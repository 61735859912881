import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip, styled } from "@mui/material";
import DocWhereInsightIsMentionedCard from "../../reusable_components/DocWhereInsightIsMentionedCard.js";
import AnchorMenu from "../../reusable_components/AnchorMenu/AnchorMenu.js";
import IndividualPainPointsCardScroller from "../../reusable_components/InsightsCardScrollers/IndividualPainPointsCardScroller.js";
import CurrentSolutionsTable from "../../reusable_components/CurrentSolutionsTable/CurrentSolutionsTable.js";
import SubsectionDropdownHeader from "../../reusable_components/SubsectionDropdownHeader.js";
import SingleIndividualPainPointMenu from "../../PagesToDisplayASingleIndividualInsightForEachType/SingleIndividualPainPointMenu.js";
import TriangulatedFeatureRequestCard from "../ReusableComponents/TriangulatedFeatureRequestCard.js";


// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
}));

const TriangulatedPainPointExplorerView = ({ data, triangulatedFeatureRequests }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedIndividualPainPointId, setSelectedIndividualPainPointId] =
    useState(null);
  const [showIndividualMenu, setShowIndividualMenu] = useState(false);
  const [showAllQuotes, setShowAllQuotes] = useState(true);
  const [activeHeading, setActiveHeading] = useState("Summary");
  const headings = [
    { id: "SummaryAndImpactedPartiesSection", text: "Summary" },
    { id: "IndividualPainPoints", text: "Individual Pain Points" },
    {
      id: "ImportanceAndMetricsImpactedAndCostsCards",
      text: "Importance/Metrics/Costs",
    },
    { id: "CurrentSolutions", text: "Current Solutions" },
    { id: "RelatedFeatureRequests", text: "Related Feature Requests" },
    { id: "ImpactfulQuotes", text: "Impactful Quotes" },
    { id: "Sources", text: "Sources" },
  ];
  const sectionRefs = useRef(headings.map(() => React.createRef()));
  const anchorMenuRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveHeading(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  const handleHeadingClick = (heading) => {
    setActiveHeading(heading);
    const index = headings.findIndex((h) => h.id === heading);
    if (sectionRefs.current[index].current) {
      const yOffset = -anchorMenuRef.current.offsetHeight;
      const y =
        sectionRefs.current[index].current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const closeMenu = () => {
    setShowIndividualMenu(false);
  };

  const handlePainPointClick = (painPointId) => {
    navigate(
      `/insights/triangulated-pain-point/${id}/individual-pain-point/${painPointId}`
    );
  };

  // Safely access data with null checks
  const triangulatedPainPoint = data?.triangulated_pain_point;
  const aggregateInsightsByType = triangulatedPainPoint?.constituent_objects_by_type || {};
  const aggregatePainPointInsights = 
    aggregateInsightsByType?.AggregatePainPointInsight?.objects_of_this_type || [];
  
  let aggregatePainPointInsight = null;
  if (aggregatePainPointInsights.length > 0) {
    aggregatePainPointInsight = aggregatePainPointInsights[0];
  }

  if (!aggregatePainPointInsight) {
    return <div>No data available</div>;
  }

  // Extract current solutions with null checks
  const aggregatePainPointInsightsList = 
    data?.triangulated_pain_point?.constituent_objects_by_type
    ?.AggregatePainPointInsight?.objects_of_this_type || [];

  // Function to collect all current solutions from individual pain points
  const collectCurrentSolutionsFromIndividualPainPoints = (
    aggregatePainPointInsightsList
  ) => {
    let allCurrentSolutions = [];
    aggregatePainPointInsightsList.forEach((aggregatePainPointInsight) => {
      aggregatePainPointInsight.individual_insights.forEach(
        (individualInsight) => {
          if (
            individualInsight.current_solutions &&
            individualInsight.current_solutions.length > 0
          ) {
            allCurrentSolutions.push(...individualInsight.current_solutions);
          }
        }
      );
    });
    return allCurrentSolutions;
  };

  const currentSolutionsListOfDicts = collectCurrentSolutionsFromIndividualPainPoints(
    aggregatePainPointInsightsList
  );

  return (
    <div className="single-aggregate-pain-point-page">
      <div
        id="TitleAndImpactScoreAndNumberOfInsightsFromKeyAccountsAndAnchorMenu"
        ref={anchorMenuRef}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          display: "flex",
          paddingTop: "24px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "24px",
          backgroundColor: "#f6f9ff",
        }}
      >
        <AnchorMenu
          headings={headings}
          activeHeading={activeHeading}
          onHeadingClick={handleHeadingClick}
        />
      </div>
      <div
        id="AllContentBelowAnchorMenu"
        style={{
          display: "flex",
          paddingBottom: "200px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "40px",
        }}
      >
        <div
          id="SummaryAndImpactedPartiesSection"
          ref={sectionRefs.current[0]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "30px",
            alignSelf: "stretch",
            marginTop: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
              justifyContent: "space-between",
            }}
          >
            <div
              id="Summary"
              style={{
                display: "flex",
                width: "744px",
                paddingRight: "20px",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                borderRadius: "12px",
              }}
            >
              <StyledTooltip
                title={
                  data.triangulated_pain_point.description_long_version || ""
                }
                arrow
                placement="bottom-start"
              >
                <div
                  style={{
                    flex: "1 0 0",
                    color: "var(--blue-darkest, #001331)",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "140%",
                    cursor: "help",
                  }}
                >
                  {data.triangulated_pain_point.description_short_version || ""}
                </div>
              </StyledTooltip>
            </div>
            <div
              id="ImpactedParties"
              style={{
                display: "flex",
                padding: "24px 20px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
                flex: "0 0 0",
                alignSelf: "stretch",
                borderRadius: "12px",
                border: "1px solid var(--slate-lighter, #E5EAF3)",
                background: "var(--white, #FFF)",
              }}
            >
              <div
                style={{
                  color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "uppercase",
                }}
              >
                IMPACTED PARTIES
              </div>
              <ul
                style={{
                  color: "var(--blue-darkest, #001331)",
                  fontFamily: "Rubik",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "140%",
                  width: "331px",
                  paddingLeft: "20px",
                  margin: 0,
                }}
              >
                {aggregatePainPointInsight.impacted_parties
                  ?.slice(0, 5)
                  .map((party, index) => (
                    <li key={index}>{party}</li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
        <div
          id="IndividualPainPoints"
          ref={sectionRefs.current[1]}
          style={{ width: "100%" }}
        >
          <IndividualPainPointsCardScroller
            individualPainPointsList={
              aggregatePainPointInsight.individual_insights || []
            }
            onPainPointClick={handlePainPointClick}
          />
        </div>
        <div
          id="ImportanceAndMetricsImpactedAndCostsCards"
          ref={sectionRefs.current[2]}
          style={{
            display: "flex",
            height: "570px",
            alignItems: "flex-start",
            gap: "24px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            id="ImportanceCard"
            style={{
              display: "flex",
              width: "360px",
              padding: "24px",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "var(--white, #FFF)",
              maxHeight: "570px",
              overflowY: "auto",
            }}
          >
            <div
              id="ImportanceCardContent"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                flex: "1 0 0",
              }}
            >
              <div
                id="TitleAndNumberOfFeatureRequestsItRelatesTo"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  alignSelf: "stretch",
                }}
              >
                <div
                  id="ImportanceTitle"
                  style={{
                    alignSelf: "stretch",
                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                    fontFamily: '"Roboto Mono"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "135%",
                    letterSpacing: "0.32px",
                    textTransform: "uppercase",
                  }}
                >
                  IMPORTANCE
                </div>
                <div
                  id="NumberOfFeatureRequestsItRelatesToSection"
                  style={{
                    display: "flex",
                    padding: "4px 12px",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    background: "var(--blue-lighter2, #E5EFFF)",
                  }}
                >
                  <div
                    style={{
                      color: "var(--slate-dark, #727F95)",
                      fontFamily: '"Roboto Mono"',
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "135%",
                      letterSpacing: "0.28px",
                      textTransform: "uppercase",
                    }}
                  >
                    relates to:
                  </div>
                  <div
                    style={{
                      color: "var(--blue-dark, #104499)",
                      fontFamily: "Roboto Mono",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "135%",
                      letterSpacing: "0.28px",
                      textTransform: "uppercase",
                    }}
                  >
                    {data?.triangulated_pain_point?.constituent_objects_by_type
                      ?.AggregateFeatureRequestInsight?.objects_of_this_type?.length || 0}{" "}
                    feature requests
                  </div>
                </div>
                <div
                  id="ImportanceTextContent"
                  style={{
                    alignSelf: "stretch",
                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "145%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        alignSelf: "stretch",
                        color:
                          "var(--blue-darkest, var(--blue-darkest, #001331))",
                        fontFamily: "Rubik",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "145%",
                      }}
                    >
                      Specifics:
                    </div>
                    <StyledTooltip
                      title={
                        aggregatePainPointInsight.specifics_long_version || ""
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <div style={{ cursor: "help" }}>
                        {aggregatePainPointInsight.specifics_short_version ||
                          ""}
                      </div>
                    </StyledTooltip>
                    <div
                      style={{
                        alignSelf: "stretch",
                        color:
                          "var(--blue-darkest, var(--blue-darkest, #001331))",
                        fontFamily: "Rubik",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "145%",
                      }}
                    >
                      Why it's particularly impactful
                    </div>
                    <StyledTooltip
                      title={
                        aggregatePainPointInsight.why_them_long_version || ""
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <div style={{ cursor: "help" }}>
                        {aggregatePainPointInsight.why_them_short_version || ""}
                      </div>
                    </StyledTooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="MetricsImpactedCard"
            style={{
              display: "flex",
              width: "360px",
              padding: "24px",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
              borderRadius: "12px",
              border: "1px solid var(--slate-lighter, #E5EAF3)",
              background: "var(--white, #FFF)",
              maxHeight: "570px",
              overflowY: "auto",
            }}
          >
            <div
              id="MetricsImpactedCardContent"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                flex: "1 0 0",
              }}
            >
              <div
                id="TitleAndNumberOfAlignedKPIs"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  alignSelf: "stretch",
                }}
              >
                <div
                  id="MetricsImpactedTitle"
                  style={{
                    alignSelf: "stretch",
                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                    fontFamily: '"Roboto Mono"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "135%",
                    letterSpacing: "0.32px",
                    textTransform: "uppercase",
                  }}
                >
                  METRICS IMPACTED
                </div>
                <div
                  id="NumberOfAlignedKPIsSection"
                  style={{
                    display: "flex",
                    padding: "4px 12px",
                    alignItems: "center",
                    gap: "4px",
                    borderRadius: "8px",
                    background: "var(--color-yellow-light, #FFEFC6)",
                  }}
                >
                  {data?.triangulated_pain_point?.constituent_objects_by_type
                    ?.AggregateSpecificMethodCompaniesHaveUsedToImproveASpecificKPIInsight
                    ?.objects_of_this_type ? (
                    data.triangulated_pain_point.constituent_objects_by_type
                      .AggregateSpecificMethodCompaniesHaveUsedToImproveASpecificKPIInsight
                      .objects_of_this_type.map((ele, index) => (
                        <div
                          key={index}
                          style={{
                            color: "var(--color-yellow, #B88C1D)",
                            fontFamily: '"Roboto Mono"',
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                            letterSpacing: "0.28px",
                            textTransform: "uppercase",
                          }}
                        >
                          {ele.specific_kpi_description}
                        </div>
                      ))
                  ) : (
                    <div
                      style={{
                        color: "var(--color-yellow, #B88C1D)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.28px",
                        textTransform: "uppercase",
                      }}
                    >
                      0 KPIs
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            id="CostsCard"
            style={{
              display: "flex",
              width: "360px",
              padding: "24px",
              alignItems: "flex-start",
              gap: "10px",
              alignSelf: "stretch",
              borderRadius: "12px",
              background: "var(--white, #FFF)",
              maxHeight: "570px",
              overflowY: "auto",
            }}
          >
            <div
              id="CostsCardContent"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                flex: "1 0 0",
              }}
            >
              <div
                id="CostsTitle"
                style={{
                  alignSelf: "stretch",
                  color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "uppercase",
                }}
              >
                COSTS
              </div>
              <div
                id="PrimaryCostsTitleAndBulletList"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "4px",
                  flex: "1 0 0",
                  alignSelf: "stretch",
                }}
              >
                <div
                  id="PrimaryCostsTitle"
                  style={{
                    alignSelf: "stretch",
                    color: "var(--slate-dark, #727F95)",
                    fontFamily: '"Roboto Mono"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "135%",
                    letterSpacing: "0.32px",
                    textTransform: "uppercase",
                  }}
                >
                  PRIMARY COSTS
                </div>
                <div
                  id="PrimaryCostsBulletList"
                  style={{
                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "145%",
                    alignSelf: "stretch",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {aggregatePainPointInsight.primary_costs?.map(
                      (cost, index) => (
                        <li
                          key={index}
                          style={{
                            paddingLeft: "1em",
                            textIndent: "-1em",
                          }}
                        >
                          <span
                            style={{
                              color:
                                "var(--blue-darkest, var(--blue-darkest, #001331))",
                              marginRight: "0.5em",
                            }}
                          >
                            •
                          </span>
                          {cost.charAt(0).toUpperCase() + cost.slice(1)}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div
                id="ImpliedCostsTitleAndBulletList"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "4px",
                  flex: "1 0 0",
                  alignSelf: "stretch",
                }}
              >
                <div
                  id="ImpliedCostsTitle"
                  style={{
                    alignSelf: "stretch",
                    color: "var(--slate-dark, #727F95)",
                    fontFamily: '"Roboto Mono"',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "135%",
                    letterSpacing: "0.32px",
                    textTransform: "uppercase",
                  }}
                >
                  IMPLIED COSTS
                </div>
                <div
                  id="ImpliedCostsBulletList"
                  style={{
                    color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "145%",
                    alignSelf: "stretch",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {aggregatePainPointInsight.implied_costs?.map(
                      (cost, index) => (
                        <li
                          key={index}
                          style={{
                            paddingLeft: "1em",
                            textIndent: "-1em",
                          }}
                        >
                          <span
                            style={{
                              color:
                                "var(--blue-darkest, var(--blue-darkest, #001331))",
                              marginRight: "0.5em",
                            }}
                          >
                            •
                          </span>
                          {cost.charAt(0).toUpperCase() + cost.slice(1)}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="CurrentSolutions"
          ref={sectionRefs.current[3]}
          style={{
            width: "100%",
          }}
        >
          <CurrentSolutionsTable
            currentSolutionsListOfDicts={currentSolutionsListOfDicts}
          />
        </div>
        <div
          id="RelatedFeatureRequests"
          ref={sectionRefs.current[4]}
          style={{
            width: "100%",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px", gap: "8px" }}>
            <span
              style={{
                color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                fontFamily: '"Roboto Mono"',
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "135%",
              }}
            >
              RELATED FEATURE REQUESTS
            </span>
            <span
              style={{
                color: "var(--Mid-grey, #727F95)",
                fontFamily: '"Roboto Mono"',
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "130%",
              }}
            >
              ({triangulatedFeatureRequests?.length || 0})
            </span>
          </div>
          
          <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", width: "100%", gap: "16px", }}>
            {triangulatedFeatureRequests?.map(featureRequest => (
              <div key={featureRequest.id}>
                <TriangulatedFeatureRequestCard
                  featureRequest={featureRequest} 
                />
              </div>
            ))}
          </div>
        </div>
        {aggregatePainPointInsight.impactful_quotes &&
          aggregatePainPointInsight.impactful_quotes.length > 0 && (
            <div
              id="ImpactfulQuotes"
              ref={sectionRefs.current[5]}
              style={{
                display: "flex",
                padding: "24px 20px",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "16px",
                alignSelf: "stretch",
                borderRadius: "12px",
                border: "1px solid var(--slate-lighter, #E5EAF3)",
                background: "var(--white, #FFF)",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  color: "var(--blue-darkest, #001331)",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "uppercase",
                }}
              >
                IMPACTFUL QUOTES
              </div>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  width: "100%",
                }}
              >
                {aggregatePainPointInsight.impactful_quotes
                  .slice(0, showAllQuotes ? undefined : 3)
                  .map((quote, index) => (
                    <li
                      key={index}
                      style={{
                        color: "var(--blue-darkest, #001331)",
                        fontFamily: "Rubik",
                        fontSize: "16px",
                        fontStyle: "italic",
                        fontWeight: 400,
                        lineHeight: "140%",
                        padding: "12px 16px",
                        borderRadius: "8px",
                        background: "var(--slate-lightest, #F6F9FF)",
                      }}
                    >
                      "{quote}"
                    </li>
                  ))}
              </ul>
              {aggregatePainPointInsight.impactful_quotes.length > 3 && (
                <button
                  onClick={() => setShowAllQuotes(!showAllQuotes)}
                  style={{
                    alignSelf: "center",
                    marginTop: "12px",
                    padding: "8px 16px",
                    backgroundColor: "var(--blue-medium, #628FDA)",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    fontFamily: "Roboto Mono",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {showAllQuotes
                    ? "Show Less"
                    : `Show ${
                        aggregatePainPointInsight.impactful_quotes.length - 3
                      } More`}
                </button>
              )}
            </div>
          )}
        <div
          id="SourcesSection"
          ref={sectionRefs.current[6]}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
          }}
        >
          <SubsectionDropdownHeader
            showDropdownArrow={false}
            sectionHeader="Sources"
            count={aggregatePainPointInsight?.source_artifact_ids?.length || 0}
          />
          <div
            id="DocWhereInsightIsMentionedCardScroller"
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
              overflowX: "auto",
              paddingBottom: "16px",
            }}
          >
            {aggregatePainPointInsight?.source_artifact_ids?.map((sourceDocumentId) => (
              <DocWhereInsightIsMentionedCard
                key={sourceDocumentId}
                insight_id={aggregatePainPointInsight.id}
                processed_artifact_to_extract_insights_from_mongo_id={sourceDocumentId}
                choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight="mongo_aggregate_insight"
              />
            )) || null}
          </div>
        </div>
      </div>
      {showIndividualMenu && selectedIndividualPainPointId && (
        <>
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={closeMenu}
          ></div>
          <SingleIndividualPainPointMenu
            insightId={selectedIndividualPainPointId}
            onClose={closeMenu}
          />
        </>
      )}
    </div>
  );
};

export default TriangulatedPainPointExplorerView;
