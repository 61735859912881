import React, { useState, useEffect } from 'react';
import RadarChart from '../ReusableComponents/RadarChart';
import BarChart from '../ReusableComponents/BarChart';
import DataSourceCard from '../ReusableComponents/DataSourceCard';
import ExpandCollapseArrow from "../../../assets/icons/InitiativesPageIcons/ExpandCollapseArrow";
import Masonry from 'react-masonry-css';

const TriangulatedFeatureRequestTriangulationView = ({
  data,
  documentsByType,
  numSources,
  chartData,
  totalSources,
  scaleMax
}) => {

  // Responsive column count logic
  const [columnCount, setColumnCount] = useState(3);

  // State variables to manage expansion/collapse
  const [customerDataExpanded, setCustomerDataExpanded] = useState(true);
  const [marketResearchExpanded, setMarketResearchExpanded] = useState(true);
  const [businessStrategyExpanded, setBusinessStrategyExpanded] = useState(true);

  useEffect(() => {
    const updateColumnCount = () => {
      if (window.innerWidth < 700) {
        setColumnCount(1);
      } else if (window.innerWidth < 1100) {
        setColumnCount(2);
      } else {
        setColumnCount(3);
      }
    };

    window.addEventListener('resize', updateColumnCount);
    updateColumnCount();

    return () => {
      window.removeEventListener('resize', updateColumnCount);
    };
  }, []);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'stretch', marginTop: '24px', gap: '24px' }}>
        <div style={{ display: 'flex' }}>
          <RadarChart 
            chartData={chartData} 
            totalSources={totalSources} 
            scaleMax={scaleMax} 
            style={{ width: '100%', height: '100%' }} 
          />
        </div>
        <div style={{ flex: 1, display: 'flex' }}>
          <BarChart 
            chartData={chartData}
            totalSources={totalSources}
            scaleMax={scaleMax}
            style={{ width: '100%', height: '100%' }} 
          />
        </div>
      </div>

      {/* Customer Data Sources Section */}
      <div 
        style={{ display: 'flex', alignItems: 'flex-start', gap: '16px', alignSelf: 'stretch', marginTop: '48px', cursor: 'pointer' }}
        onClick={() => setCustomerDataExpanded(prev => !prev)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: '1 0 0' }}>
          <ExpandCollapseArrow 
            width={24} 
            height={25} 
            fill="#104499" 
            rotation={customerDataExpanded ? 90 : 0} 
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <circle cx="6" cy="6.5" r="5.5" fill="#ABE9F5" stroke="#9CC2FF"/>
          </svg>
          <span
            style={{
              color: "#001331",
              fontFamily: "Roboto Mono",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            Customer Data Sources
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <span
              style={{
                color: "#001331",
                fontFamily: "Roboto Mono",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "135%",
              }}
            >
              {documentsByType.customerDiscoveryInterview ? documentsByType.customerDiscoveryInterview.length : 0}
            </span>
            <span
              style={{
                color: "#727F95",
                fontFamily: "Rubik",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "145%",
              }}
            >
              {documentsByType.customerDiscoveryInterview && documentsByType.customerDiscoveryInterview.length > 0
                ? `${documentsByType.customerDiscoveryInterview.length === 1 ? 'user interview' : 'user interviews'}`
                : 'user interviews'}
            </span>
          </div>
          <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
            <span
              style={{
                color: "#001331",
                fontFamily: "Roboto Mono",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "135%",
              }}
            >
              {documentsByType.review ? documentsByType.review.length : 0}
            </span>
            <span
              style={{
                color: "#727F95",
                fontFamily: "Rubik",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "145%",
              }}
            >
              {documentsByType.review && documentsByType.review.length > 0
                ? `${documentsByType.review.length === 1 ? 'app review' : 'app reviews'}`
                : 'app reviews'}
            </span>
          </div>
        </div>
      </div>
      {customerDataExpanded && (
        <Masonry
          breakpointCols={{
            default: 3,
            1100: 2,
            700: 1
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {['customerDiscoveryInterview', 'review'].flatMap((sourceCategory) =>
            (documentsByType[sourceCategory] || []).map((doc, index) => (
              <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
            ))
          )}
        </Masonry>
      )}

      {/* Market Research Sources Section */}
      <div 
        style={{ display: 'flex', alignItems: 'flex-start', gap: '16px', alignSelf: 'stretch', marginTop: '48px', cursor: 'pointer' }}
        onClick={() => setMarketResearchExpanded(prev => !prev)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: '1 0 0' }}>
          <ExpandCollapseArrow 
            width={24} 
            height={25} 
            fill="#104499" 
            rotation={marketResearchExpanded ? 90 : 0} 
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <circle cx="6" cy="6.5" r="6" fill="#9CC2FF"/>
          </svg>
          <span
            style={{
              color: "#001331",
              fontFamily: "Roboto Mono",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            Market Research Sources
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span
            style={{
              color: "#001331",
              fontFamily: "Roboto Mono",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            {documentsByType.marketResearchReport ? documentsByType.marketResearchReport.length : 0}
          </span>
          <span
            style={{
              color: "#727F95",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
            }}
          >
            {documentsByType.marketResearchReport && documentsByType.marketResearchReport.length > 0
              ? `${documentsByType.marketResearchReport.length === 1 ? 'market research report' : 'market research reports'}`
              : 'market research reports'}
          </span>
        </div>
      </div>
      {marketResearchExpanded && (
        <Masonry
          breakpointCols={{
            default: 3,
            1100: 2,
            700: 1
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {['marketResearchReport'].flatMap((sourceCategory) =>
            (documentsByType[sourceCategory] || []).map((doc, index) => (
              <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
            ))
          )}
        </Masonry>
      )}

      {/* Business Strategy Sources Section */}
      <div 
        style={{ display: 'flex', alignItems: 'flex-start', gap: '16px', alignSelf: 'stretch', marginTop: '48px', cursor: 'pointer' }}
        onClick={() => setBusinessStrategyExpanded(prev => !prev)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', flex: '1 0 0' }}>
          <ExpandCollapseArrow 
            width={24} 
            height={25} 
            fill="#104499" 
            rotation={businessStrategyExpanded ? 90 : 0} 
          />
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <circle cx="6" cy="6.5" r="6" fill="#9392F2"/>
          </svg>
          <span
            style={{
              color: "#001331",
              fontFamily: "Roboto Mono",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            Business Strategy Sources
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span
            style={{
              color: "#001331",
              fontFamily: "Roboto Mono",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            {documentsByType.businessObjectivesOrStrategyDocument ? documentsByType.businessObjectivesOrStrategyDocument.length : 0}
          </span>
          <span
            style={{
              color: "#727F95",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "145%",
            }}
          >
            {documentsByType.businessObjectivesOrStrategyDocument && documentsByType.businessObjectivesOrStrategyDocument.length > 0
              ? `${documentsByType.businessObjectivesOrStrategyDocument.length === 1 ? 'strategy document' : 'strategy documents'}`
              : 'strategy documents'}
          </span>
        </div>
      </div>
      {businessStrategyExpanded && (
        <Masonry
          breakpointCols={{
            default: 3,
            1100: 2,
            700: 1
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {['businessObjectivesOrStrategyDocument'].flatMap((sourceCategory) =>
            (documentsByType[sourceCategory] || []).map((doc, index) => (
              <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
            ))
          )}
        </Masonry>
      )}
    </div>
  );
};

export default TriangulatedFeatureRequestTriangulationView;
