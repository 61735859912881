import API from "../../services/api";
import log from "../../utils/logger";

const generateStateValue = (userId) => {
  return btoa(JSON.stringify({ user_id: userId, timestamp: new Date().getTime() }));
};

const storeStateValue = async (stateValue, externalToolName) => {
  const storeStateUrl = `${process.env.REACT_APP_BACKEND_URL}/api/integrations_general/store_state_value_for_oauth2_flow_for_an_external_integration`;
  try {
    await API.post(storeStateUrl, { state_value: stateValue, external_tool_name: externalToolName });
    log("State stored successfully");
  } catch (error) {
    console.error("Failed to store state:", error);
    throw error;
  }
};

export const initiateJiraOAuth = async (user) => {
  log("Initiating Jira OAuth flow");
  const token = user?.access_token;
  if (!token) {
    console.error("No token found, user must be logged in to add integration.");
    return;
  }

  const stateValue = generateStateValue(user.id);
  await storeStateValue(stateValue, "jira");

  const authorizationURL = `${process.env.REACT_APP_JIRA_AUTHORIZATION_URL_PREFIX}${stateValue}&response_type=code&prompt=consent`;
  window.location.href = authorizationURL;
};


// Add more OAuth initiation functions for other integrations here
// export const initiateGitHubOAuth = async (user) => { ... };
// export const initiateSlackOAuth = async (user) => { ... };

export const handleRemoveIntegration = async (serviceName) => {
  if (window.confirm("Are you sure you want to remove this integration?")) {
    try {
      const response = await API.post("/integrations_general/remove_integration", {
        service_name: serviceName,
      });
      if (response.status === 200) {
        log("Integration removed successfully");
        window.location.reload();
      }
    } catch (error) {
      console.error("Failed to remove integration", error);
    }
  }
};

// ... other existing functions ...

export const initiateSlackOAuth = async (user) => {
  log("Initiating Slack OAuth flow");
  const token = user?.access_token;
  if (!token) {
    console.error("No token found, user must be logged in to add integration.");
    return;
  }

  try {
    const response = await API.get("/slack/oauth/install");
    if (response.status === 200 && response.data.authUrl) {
      window.location.href = response.data.authUrl;
    } else {
      console.error("Failed to get Slack OAuth URL");
    }
  } catch (error) {
    console.error("Error initiating Slack OAuth:", error);
  }
};

// ... existing code ...