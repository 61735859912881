import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { usePayment } from "../../contexts/PaymentContext";

const PaymentSuccess = () => {
   const navigate = useNavigate();
   const location = useLocation();
   const { verifyPayment } = usePayment();
   const [message, setMessage] = useState("Payment successful! Verifying subscription...");

   useEffect(() => {
      const verifySubscription = async () => {
         const searchParams = new URLSearchParams(location.search);
         const sessionId = searchParams.get("session_id");

         if (!sessionId) {
            setMessage("Error: Session ID not found");
            return;
         }

         try {
            await verifyPayment(sessionId);
            setMessage("Subscription verified! Redirecting...");
            setTimeout(() => navigate("/initiatives"), 2000);
         } catch (error) {
            console.error("Error verifying payment:", error);
            setMessage("Error verifying subscription. Please contact support.");
         }
      };
      verifySubscription();
   }, [navigate, location, verifyPayment]);

   return <div className="text-center mt-10">{message}</div>;
};

export default PaymentSuccess;
