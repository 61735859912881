import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInitiativesPage } from '../../../contexts/InitiativesPageContext';
import RelatedInsightsPainPointCard from '../../reusable_components/RelatedInsights/RelatedInsightsPainPointCard';
import RelatedInsightsFeatureRequestCard from '../../reusable_components/RelatedInsights/RelatedInsightsFeatureRequestCard';
import InitiativesPagePainPointCard from '../../reusable_components/InitiativesPage/InitiativesPagePainPointCard';
import InitiativesPageFeatureRequestCard from '../../reusable_components/InitiativesPage/InitiativesPageFeatureRequestCard';
import InitiativesPageObjectiveCard from '../../reusable_components/InitiativesPage/InitiativesPageObjectiveCard';
import RelatedInsightsObjectiveCard from '../../reusable_components/RelatedInsights/RelatedInsightsObjectiveCard';

const RelatedInsights = ({ initiativeId, onClose }) => {
  const navigate = useNavigate();
  const { 
    matchRelatedInsightsForIndividualInitiative, 
    getIndividualInitiativeByIdForUser,
    setInitiativesPageBlurredOrNot
  } = useInitiativesPage();

  useEffect(() => {
    setInitiativesPageBlurredOrNot(true);
    return () => setInitiativesPageBlurredOrNot(false);
  }, [setInitiativesPageBlurredOrNot]);

  const relatedInsights = useMemo(() => 
    matchRelatedInsightsForIndividualInitiative(initiativeId),
    [matchRelatedInsightsForIndividualInitiative, initiativeId]
  );

  const initiative = useMemo(() => 
    getIndividualInitiativeByIdForUser(initiativeId),
    [getIndividualInitiativeByIdForUser, initiativeId]
  );

  const painPoints = relatedInsights.aggregate_pain_point || [];
  const featureRequests = relatedInsights.aggregate_feature_request || [];
  const objectives = relatedInsights.objective_kg_node || [];

  const [expandedSections, setExpandedSections] = useState({
    painPoints: true,
    featureRequests: true,
    objectives: true
  });

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  const ChevronIcon = ({ expanded }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}>
      <path d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z" fill="#104499"/>
    </svg>
  );

  const handlePainPointClick = (painPointId) => {
    navigate(`/insights/aggregate-pain-point/${painPointId}`);
    onClose();
  };

  const handleFeatureRequestClick = (featureRequestId) => {
    navigate(`/insights/aggregate-feature-request/${featureRequestId}`);
    onClose();
  };

  const handleObjectiveClick = (objectiveId) => {
    navigate(`/insights/objective/${objectiveId}`);
    onClose();
  };

  const sortedPainPoints = useMemo(() => 
    [...painPoints].sort((a, b) => b.count - a.count),
    [painPoints]
  );

  const sortedFeatureRequests = useMemo(() => 
    [...featureRequests].sort((a, b) => b.count - a.count),
    [featureRequests]
  );

  return (
    <div className="fixed top-0 right-0 bottom-0 w-1/2 bg-white overflow-y-auto shadow-lg z-50">
      <button 
        className="absolute top-4 right-4 text-2xl"
        onClick={onClose}
      >
        &times;
      </button>
      <main className="flex flex-col pb-32 pl-[43px] pr-4 pt-[35px]">
        <header className="flex flex-wrap gap-8 items-start w-full font-medium leading-none">
          <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px]">
            <div className="text-[#104499] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase">
              RELEVANCE
            </div>
            <h1 className="text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%] mt-2">
              {initiative?.title}
            </h1>
          </div>
        </header>
        <section className="flex flex-col mt-8 w-full">
          <div className="flex flex-col w-full">
            <div className="flex gap-3 items-center self-start leading-none uppercase cursor-pointer" onClick={() => toggleSection('painPoints')}>
              <ChevronIcon expanded={expandedSections.painPoints} />
              <InitiativesPagePainPointCard count={painPoints.length} />
            </div>
            {expandedSections.painPoints && (
              <div className="flex flex-wrap gap-4 items-start mt-5 w-full text-sm tracking-wide">
                {sortedPainPoints.map((point, index) => (
                  <RelatedInsightsPainPointCard 
                    key={index} 
                    {...point} 
                    onClick={() => handlePainPointClick(point.id)}
                  />
                ))}
              </div>
            )}
          </div>
        </section>
        <section className="flex flex-col mt-9 w-full">
          <div className="flex flex-wrap gap-3 items-center w-full leading-none uppercase cursor-pointer" onClick={() => toggleSection('featureRequests')}>
            <ChevronIcon expanded={expandedSections.featureRequests} />
            <InitiativesPageFeatureRequestCard count={featureRequests.length} />
          </div>
          {expandedSections.featureRequests && (
            <div className="flex flex-wrap gap-4 items-start mt-5 w-full text-sm tracking-wide">
              {sortedFeatureRequests.map((request, index) => (
                <RelatedInsightsFeatureRequestCard 
                  key={index} 
                  {...request} 
                  onClick={() => handleFeatureRequestClick(request.id)}
                />
              ))}
            </div>
          )}
        </section>
        <section className="flex flex-col mt-9 w-full">
          <div className="flex flex-wrap gap-3 items-center w-full leading-none uppercase cursor-pointer" onClick={() => toggleSection('objectives')}>
            <ChevronIcon expanded={expandedSections.objectives} />
            <InitiativesPageObjectiveCard count={objectives.length} />
          </div>
          {expandedSections.objectives && (
            <div className="flex flex-wrap gap-4 items-start mt-5 w-full text-sm tracking-wide">
              {objectives.map((objective, index) => (
                <RelatedInsightsObjectiveCard 
                  key={index} 
                  {...objective} 
                  onClick={() => handleObjectiveClick(objective.id)}
                />
              ))}
            </div>
          )}
        </section>
      </main>
    </div>
  );
};

export default RelatedInsights;