import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubsectionDropdownHeader from "../SubsectionDropdownHeader";
import QuoteCard from "../QuoteCard";

const IndividualPainPointsCardScroller = ({ individualPainPointsList, onPainPointClick }) => {
   const [isContentVisible, setIsContentVisible] = useState(true);
   const navigate = useNavigate();

   const toggleContentVisibility = () => {
      setIsContentVisible(!isContentVisible);
   };

   const handlePainPointClick = (painPointId) => {
      if (onPainPointClick) {
        onPainPointClick(painPointId);
      } else {
        navigate(`/insights/individual-pain-point/${painPointId}`);
      }
    };

   return (
      <div
         className="individual-pain-points-card-scroller"
         style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch"
         }}
      >
         <div
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
         <SubsectionDropdownHeader
            count={individualPainPointsList.length}
            sectionHeader="Individual Pain Points"
            isExpanded={isContentVisible}
            onToggle={toggleContentVisibility}
         />
         {isContentVisible && (
            <div
               style={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "24px",
                  alignSelf: "stretch",
                  overflowX: "auto",
                  paddingBottom: "16px"
               }}
            >
               {individualPainPointsList.map((painPoint) => (
                  <QuoteCard
                     key={painPoint.id}
                     title={painPoint.title}
                     quote_text={painPoint.impactful_quotes[0]}
                     source_file_name={painPoint.source_artifact_name}
                     isClickable={true}
                     onClick={() => handlePainPointClick(painPoint.id)}
                     style={{
                        minWidth: "360px",
                        maxWidth: "360px",
                     }}
                     arrowAndLeftLineColor="#F26969"
                  />
               ))}
            </div>
         )}
         <div
            style={{
               width: "100%",
               height: "1px",
               background: "var(--slate-light, #C5D1E4)"
            }}
         ></div>
      </div>
   );
};

export default IndividualPainPointsCardScroller;
