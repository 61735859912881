import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function AuthenticatedRoute() {
   const { user } = useAuth(); // Use the useAuth hook to get the current user
   return user ? <Navigate to="/initiatives" replace /> : <Outlet />;
}

export { AuthenticatedRoute };
