import React, { useEffect, useState, useContext } from "react";
import API from "../../../../services/api";
import {
  Grid,
  Typography,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SteamIntegrationGameBox from "./SteamTabContentGameBox";
import PrimaryButton from "../../../reusable_components/Buttons/PrimaryButton";
import { StatusContext } from "../../../../contexts/StatusContext";

function SteamIntegrationSelectedGames() {
  const [selectedGames, setSelectedGames] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);

  useEffect(() => {
    fetchSelectedGames();
  }, []);

  const fetchSelectedGames = async () => {
    try {
      const response = await API.get("steam/selected-games");
      setSelectedGames(response.data.games);
    } catch (error) {
      console.error("Error fetching selected games:", error);
    }
  };

  const handleSelectChange = async (gameId, isSelected) => {
    if (!isSelected) {
      setGameToDelete(gameId);
      setDeleteConfirmOpen(true);
    }
  };

  const handleDeleteConfirm = async () => {
    setDeleteConfirmOpen(false);
    if (gameToDelete) {
      try {
        await API.post("steam/remove-game", { game_id: gameToDelete });
        fetchSelectedGames();
        setSnackbarOpen(true);
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      } catch (error) {
        console.error("Error removing game:", error);
      }
    }
    setGameToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setGameToDelete(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      {selectedGames.length > 0 ? (
        <Grid container spacing={2}>
          {selectedGames.map((game) => (
            <Grid item key={game.id}>
              <SteamIntegrationGameBox
                game={game}
                isSelected={true}
                onSelectChange={handleSelectChange}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6">
          No selected games. Go to the search tab to add games!
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Game and associated reviews removed"
        sx={{
          bottom: "80px",
          "& .MuiSnackbarContent-root": {
            width: "auto",
            minWidth: "200px",
            maxWidth: "800px",
            fontSize: "1.1rem",
            padding: "12px 24px",
            textAlign: "center",
            display: "block",
          },
        }}
      />
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">
            Confirm Removal
          </h1>
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: "#727F95",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "135%",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to remove this game? This will also delete all associated reviews and insights extracted from them.
          </p>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDeleteConfirm} color="primary">
            Remove
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SteamIntegrationSelectedGames;
