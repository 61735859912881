import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DocIcon } from '../../../assets/icons/DocIcon.svg';
import { ReactComponent as SlackIcon } from '../../../assets/icons/ExternalIntegrationsIcons/slack.svg';
import { ReactComponent as SteamIcon } from '../../../assets/icons/ExternalIntegrationsIcons/steam_logo.svg';

// This object maps source types to their corresponding SVG components
const sourceIcons = {
  computer_upload: DocIcon,
  steam_integration: SteamIcon,
  slack_integration: SlackIcon,
  // Add more mappings here as needed
};

const sourceCategoryMapping = {
  customerDiscoveryInterview: "User Interview",
  review: "App Review",
//   businessObjectivesOrStrategyDocument: "Strategy Document",
  marketResearchReport: "Market Research Report",
//   internalMeeting: "Internal Meeting"
};

const DataSourceCard = ({ data, sourceCategory }) => {
    console.log("data in datasourcecard", data)
    const [mentions, setMentions] = useState([]);
    const [currentMentionIndex, setCurrentMentionIndex] = useState(0);
    const [processedArtifactToExtractInsightsFrom, setprocessedArtifactToExtractInsightsFrom] = useState(null);
    const navigate = useNavigate();
    const IconComponent = sourceIcons[data.source] || DocIcon;

    useEffect(() => {
        if (data && data.mentions) {
            setMentions(data.mentions);
            setprocessedArtifactToExtractInsightsFrom(data);
        }
    }, [data]);

    const handlePrevious = () => {
        setCurrentMentionIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : mentions.length - 1));
    };

    const handleNext = () => {
        setCurrentMentionIndex((prevIndex) => (prevIndex < mentions.length - 1 ? prevIndex + 1 : 0));
    };

    const handleMentionClick = (mention) => {
        const linkUrl = `/doc-viewer/${data.id}/${mention.id}`;
        navigate(linkUrl);
    };

    return (
        <div
            style={{
                display: 'flex',
                width: '100%', // Ensure the card fills the column width
                padding: '24px 20px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                borderRadius: '12px',
                border: '1px solid var(--slate-lighter, #E5EAF3)',
                background: 'var(--white, #FFF)',
                boxSizing: 'border-box',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <IconComponent width="24" height="24" />
                <span
                    style={{
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: "Roboto Mono",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase",
                    }}
                >
                    {sourceCategoryMapping[sourceCategory] || sourceCategory}
                </span>
            </div>
            {mentions.length > 0 && (
                <>
                    <div style={{
                        backgroundColor: '#FFF',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleMentionClick(mentions[currentMentionIndex])}
                    >
                        <p style={{
                            color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "145%", /* 23.2px */
                            margin: 0
                        }}>
                            {mentions[currentMentionIndex].span_text}
                        </p>
                    </div>
                    <div
               id="LeftRightArrowContainer"
               style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  alignSelf: "stretch"
               }}
            >
               <div
                  id="LeftArrow"
                  style={{
                     width: "24px",
                     height: "24px",
                     cursor: "pointer"
                  }}
                  onClick={handlePrevious}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M10.6994 12L15.5 16.8817L14.4003 18L8.5 12L14.4003 6L15.5 7.11828L10.6994 12Z"
                        fill="#104499"
                     />
                  </svg>
               </div>
               <div
                  id="NumberAndTotalText"
                  style={{
                     color: "var(--slate-dark, #727F95)",
                     fontFamily: '"Roboto Mono"',
                     fontSize: "16px",
                     fontStyle: "normal",
                     fontWeight: 400,
                     lineHeight: "130%"
                  }}
               >
                  {currentMentionIndex + 1}/{mentions.length}
               </div>
               <div
                  id="RightArrow"
                  style={{
                     width: "24px",
                     height: "24px",
                     cursor: "pointer"
                  }}
                  onClick={handleNext}
               >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path
                        d="M13.3006 12L8.5 7.11828L9.5997 6L15.5 12L9.5997 18L8.5 16.8817L13.3006 12Z"
                        fill="#104499"
                     />
                  </svg>
               </div>
            </div>
                </>
            )}
            <div>
                <span
                    style={{
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: "Roboto Mono",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "130%",
                    }}
                >
                    Source: {data.name_of_processed_file_saved_in_s3 || 'Untitled'}
                </span>
            </div>
        </div>
    );
};

export default DataSourceCard;
