import React, { useContext, useEffect, useState } from "react";
import API from "../../services/api";
import { StatusContext } from "../../contexts/StatusContext";
import StatusBox from "../StatusBox/StatusBox";
import AggregatePainPointCard from "../reusable_components/InsightsCards/AggregatePainPointCard";
import AggregateFeatureRequestCard from "../reusable_components/InsightsCards/AggregateFeatureRequestCard";
import ObjectiveCard from "../reusable_components/InsightsCards/ObjectiveCard";
import PageLoadSpinner from "../reusable_components/PageLoadSpinner/PageLoadSpinner";
import { useNavigate } from "react-router-dom";

const InsightsPage = () => {
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);
  const [triangulatedPainPoints, setTriangulatedPainPoints] = useState([]);
  const [triangulatedFeatureRequests, setTriangulatedFeatureRequests] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);
  const [filesWithArtifactTypeCount, setFilesWithArtifactTypeCount] =
    useState(0);
  const [selectedCategory, setSelectedCategory] = useState('pain_points'); // Add this state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [insightCounts, setInsightCounts] = useState({
    pain_points: 0,
    feature_requests: 0,
    objectives: 0
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchInsightCounts();
    fetchDocumentCount();
  }, []);

  useEffect(() => {
    fetchInsightsByType();
  }, [selectedCategory, currentPage]);

  const fetchInsightCounts = async () => {
    try {
      const response = await API.get('/insights/get-triangulated-insights-count');
      setInsightCounts(response.data);
    } catch (error) {
      console.error('Error fetching insight counts:', error);
    }
  };

  const fetchDocumentCount = async () => {
    const response = await API.get("/file-management/get-files-with-artifact-type-count");
    setFilesWithArtifactTypeCount(response.data.count)
  }

  const fetchInsightsByType = async () => {
    if (selectedCategory === 'objectives') return; // Skip for objectives tab

    try {
      setIsLoading(true);
      const response = await API.get(`/insights/get-triangulated-insights-by-type/${selectedCategory}`, {
        params: {
          page: currentPage,
          per_page: 12
        }
      });
      
      if (selectedCategory === 'pain_points') {
        setTriangulatedPainPoints(response.data.insights);
      } else {
        setTriangulatedFeatureRequests(response.data.insights);
      }
      
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error(`Error fetching ${selectedCategory}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const CategoryTab = ({ type, label }) => (
    <div 
      onClick={() => {
        setSelectedCategory(type);
        setCurrentPage(1); // Reset to first page when changing categories
      }}
      className="cursor-pointer"
      style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}
    >
      <div style={{ display: 'flex', padding: `0px 4px ${selectedCategory === type ? '2px' : '6px'} 4px`, alignItems: 'center', gap: '10px', justifyContent: 'start' }}>
        <span style={{ 
          color: 'var(--blue-darkest, #001331)', 
          fontFamily: 'Roboto Mono', 
          fontSize: '20px', 
          fontStyle: 'normal', 
          fontWeight: 500, 
          lineHeight: '135%' 
        }}>
          {insightCounts[type]}
        </span>
        <span style={{
          color: selectedCategory === type ? '#001331' : '#727F95',
          fontFamily: 'Rubik',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: selectedCategory === type ? 500 : 400,
          lineHeight: '125%',
          textTransform: 'capitalize'
        }}>
          {label}
        </span>
      </div>
      {selectedCategory === type && (
        <div style={{ 
          height: '4px',
          alignSelf: 'stretch',
          borderRadius: '2px',
          background: 'var(--blue-dark, #104499)'
        }} />
      )}
    </div>
  );

  const renderContent = () => {
    switch(selectedCategory) {
      case 'pain_points':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
            {triangulatedPainPoints.length > 0 ? (
              triangulatedPainPoints.map((painPoint) => (
                <AggregatePainPointCard key={painPoint.id} painPoint={painPoint} />
              ))
            ) : (
              <p>No pain points available.</p>
            )}
          </div>
        );
      case 'feature_requests':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
            {triangulatedFeatureRequests.length > 0 ? (
              triangulatedFeatureRequests.map((feature) => (
                <AggregateFeatureRequestCard key={feature.id} featureRequest={feature} />
              ))
            ) : (
              <p>No triangulated features available.</p>
            )}
          </div>
        );
      case 'objectives':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-2">
            {objectives.length > 0 ? (
              objectives.map((objective) => (
                <ObjectiveCard key={objective.id} objective={objective} />
              ))
            ) : (
              <p>No objectives available.</p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const renderPagination = () => {
    if (selectedCategory === 'objectives') return null;
    
    const startRange = ((currentPage - 1) * 12) + 1;
    const endRange = Math.min(currentPage * 12, insightCounts[selectedCategory]);
    
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
        <svg 
          onClick={() => handlePageChange(1)}
          className={currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none"
        >
          <path d="M11.1249 17.6542L5.47119 12.0004L11.1249 6.34668L12.1787 7.40043L7.59419 12.0004L12.1787 16.6004L11.1249 17.6542ZM17.4749 17.6542L11.8212 12.0004L17.4749 6.34668L18.5287 7.40043L13.9442 12.0004L18.5287 16.6004L17.4749 17.6542Z" 
            fill={currentPage === 1 ? "#C5D1E4" : "#104499"}
          />
        </svg>
        <svg 
          onClick={() => handlePageChange(currentPage - 1)}
          className={currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer'}
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none"
        >
          <path d="M10.6994 12L15.5 16.8817L14.4003 18L8.5 12L14.4003 6L15.5 7.11828L10.6994 12Z" 
            fill={currentPage === 1 ? "#C5D1E4" : "#104499"}
          />
        </svg>
        <span style={{
          color: 'var(--blue-darkest, #001331)',
          fontFamily: 'Rubik',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '120%'
        }}>
          {startRange} - {endRange}
        </span>
        <span style={{
          color: '#727F95',
          fontFamily: 'Rubik',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '120%'
        }}>
          of
        </span>
        <span style={{
          color: '#001331',
          fontFamily: 'Rubik',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '120%'
        }}>
          {insightCounts[selectedCategory]}
        </span>
        <svg 
          onClick={() => handlePageChange(currentPage + 1)}
          className={currentPage === totalPages ? 'cursor-not-allowed' : 'cursor-pointer'}
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none"
        >
          <path d="M13.3006 12L8.5 7.11828L9.5997 6L15.5 12L9.5997 18L8.5 16.8817L13.3006 12Z" 
            fill={currentPage === totalPages ? "#C5D1E4" : "#104499"}
          />
        </svg>
        <svg 
          onClick={() => handlePageChange(totalPages)}
          className={currentPage === totalPages ? 'cursor-not-allowed' : 'cursor-pointer'}
          xmlns="http://www.w3.org/2000/svg" 
          width="24" 
          height="24" 
          viewBox="0 0 24 24" 
          fill="none"
        >
          <path d="M10.0557 12.0004L5.47119 7.40043L6.52494 6.34668L12.1787 12.0004L6.52494 17.6542L5.47119 16.6004L10.0557 12.0004ZM16.4057 12.0004L11.8212 7.40043L12.8749 6.34668L18.5287 12.0004L12.8749 17.6542L11.8212 16.6004L16.4057 12.0004Z" 
            fill={currentPage === totalPages ? "#C5D1E4" : "#104499"}
          />
        </svg>
      </div>
    );
  };

  return (
    <div>
      {isLoading && !triangulatedPainPoints.length && !triangulatedFeatureRequests.length ? (
        <PageLoadSpinner />
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <h1 id="insightsHeader" className="text-[#001331] font-['Roboto_Mono'] text-2xl font-medium leading-[135%]">
              Insights
            </h1>
            <button
              className="flex gap-3 items-center self-stretch my-auto text-base font-bold tracking-wide text-black capitalize min-w-[240px] cursor-pointer"
              onClick={() => navigate("/data-integrations", { state : { initialTab : "Local Documents" }})}
            >
              <div className="self-stretch my-auto">
                Extracted from {filesWithArtifactTypeCount} documents
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/61bde006424a8fc1e7637dc92415c8dfd4fa1bc791e86e5ea610729f0d660d2a?placeholderIfAbsent=true&apiKey=d3f082d9d7814c0fa02b6ddd63f6ac43"
                className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square"
                alt="Document icon"
              />
            </button>
          </div>
          <div>
            <div style={{ display: 'flex', paddingTop: '10px', alignItems: 'flex-end', gap: '36px', alignSelf: 'stretch' }}>
              <CategoryTab 
                type="pain_points" 
                label="Pain Points" 
              />
              <CategoryTab 
                type="feature_requests" 
                label="Feature Requests" 
              />
              <CategoryTab 
                type="objectives" 
                label="Objectives" 
              />
            </div>
            <div style={{ height: '1px', width: '100%', background: 'var(--slate-light, #C5D1E4)' }}></div>
          </div>

          <div style={{ display: 'flex', marginTop: '30px' }}>
            <p style={{
              color: 'var(--Mid-grey, #727F95)',
              fontFamily: '"Roboto Mono"',
              fontSize: '16px',
              fontStyle: 'italic',
              fontWeight: 400,
              lineHeight: '140%',
              flex: '1 0 0'
            }}>
              Click on an insight to view details
            </p>
            {renderPagination()}
          </div>

          <StatusBox />

          <div className="mt-8">
            {isLoading ? (
              <div className="flex justify-center">
                <PageLoadSpinner />
              </div>
            ) : (
              renderContent()
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InsightsPage;
