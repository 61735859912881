import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from '../../../services/api';

function SingleTriangulatedFeaturePage() {
  const { id } = useParams();
  const [triangulatedFeature, setTriangulatedFeature] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTriangulatedFeature = async () => {
      try {
        const response = await API.get(`/insights/triangulated-feature/${id}`);
        setTriangulatedFeature(response.data.triangulated_feature);
      } catch (err) {
        setError('Failed to fetch triangulated feature');
        console.error('Error fetching triangulated feature:', err);
      }
    };

    fetchTriangulatedFeature();
  }, [id]);

  const styles = {
    pageTitle: {
      fontSize: '28px',
      fontWeight: 'bold',
      marginBottom: '20px',
    },
    featureTitle: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    featureId: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '15px',
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginTop: '20px',
      marginBottom: '10px',
    },
    description: {
      fontSize: '16px',
      lineHeight: '1.5',
      marginBottom: '20px',
    },
    insightTypeTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginTop: '15px',
      marginBottom: '10px',
    },
    insightTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '5px',
    },
    insightType: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '5px',
    },
    insightDescription: {
      fontSize: '14px',
      lineHeight: '1.4',
      marginBottom: '15px',
    },
    errorMessage: {
      color: 'red',
      fontSize: '16px',
      fontWeight: 'bold',
    },
  };

  return (
    <div>
      <div style={styles.pageTitle}>Triangulated Feature</div>
      
      {error && <p style={styles.errorMessage}>{error}</p>}
      
      {triangulatedFeature ? (
        <div>
          <div style={styles.featureTitle}>{triangulatedFeature.title}</div>
          <div style={styles.featureId}>Feature ID: {id}</div>
          <div style={styles.sectionTitle}>Description:</div>
          <p style={styles.description}>{triangulatedFeature.description_long_version}</p>
          
          <div style={styles.sectionTitle}>Constituent Aggregate Insights:</div>
          {Object.entries(triangulatedFeature.constituent_objects_by_type).map(([type, insights]) => (
            <div key={type}>
              <div style={styles.insightTypeTitle}>{type}</div>
              {insights.objects_of_this_type.map((insight, index) => (
                <div key={index}>
                  <div style={styles.insightType}>Type: {insight.type}</div>
                  <div style={styles.insightTitle}>Title: {insight.title}</div>
                  <p style={styles.insightDescription}>Description: {insight.description_long_version}</p>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <p>Loading triangulated feature...</p>
      )}
    </div>
  );
}

export default SingleTriangulatedFeaturePage;
