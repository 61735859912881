import React, { useMemo, useState } from 'react';
import { useInitiativesPage } from '../../../contexts/InitiativesPageContext';
import RelatedInsights from './RelatedInsights';
import InitiativeBox from './InitiativeBox';
// import DELETEInitiativeBoxTest from './DELETEExampleOfTextBoxWithShowMore';



const InitiativesBoxRenderer = () => {
  const { 
    initiatives,
    filteredInitiatives,
    isSearching,
    searchText,
    getDescriptionExpandedOrNot,
    setDescriptionExpandedOrNot,
    initiativesPageBlur,
    matchRelatedInsightsForIndividualInitiative,
    matchRelatedMetricsForIndividualInitiative,
    searchMode
  } = useInitiativesPage();

  const [visibleRelatedInsightsId, setVisibleRelatedInsightsId] = useState(null);

  const handleShowRelatedInsights = (initiativeId) => {
    setVisibleRelatedInsightsId(initiativeId);
  };

  const handleHideRelatedInsights = () => {
    setVisibleRelatedInsightsId(null);
  };

  const renderInitiativeBoxes = useMemo(() => {
    const initiativesToRender = searchMode ? filteredInitiatives : initiatives;
    return initiativesToRender.map((initiative) => {
      const isDescriptionExpanded = getDescriptionExpandedOrNot(initiative._id);
      const relatedInsights = matchRelatedInsightsForIndividualInitiative(initiative._id);
      const metricData = matchRelatedMetricsForIndividualInitiative(initiative._id);

      const hasMetricsOrInsights = 
        (metricData && metricData.length > 0) || 
        (relatedInsights && (relatedInsights.aggregate_pain_point?.length > 0 || relatedInsights.aggregate_feature_request?.length > 0));

      const handleDescriptionExpandClick = (e) => {
        e.stopPropagation();
        setDescriptionExpandedOrNot(initiative._id, !isDescriptionExpanded);
      };

      return (
        <React.Fragment key={initiative._id}>
          <InitiativeBox
            initiativeId={initiative._id}
            isDescriptionExpanded={isDescriptionExpanded}
            onDescriptionExpandClick={handleDescriptionExpandClick}
            onShowRelatedInsights={() => handleShowRelatedInsights(initiative._id)}
          />
          {visibleRelatedInsightsId === initiative._id && (
            <RelatedInsights
              initiativeId={initiative._id}
              onClose={handleHideRelatedInsights}
            />
          )}
        </React.Fragment>
      );
    });
  }, [initiatives, filteredInitiatives, getDescriptionExpandedOrNot, setDescriptionExpandedOrNot, visibleRelatedInsightsId, matchRelatedInsightsForIndividualInitiative, matchRelatedMetricsForIndividualInitiative, searchMode]);

  if (isSearching) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center">
      <div className={`w-full max-w-[1080px] ${initiativesPageBlur ? 'blur-sm' : ''}`}>
        {renderInitiativeBoxes}
        {searchMode && filteredInitiatives.length === 0 && (
          <div>No initiatives found for the search query.</div>
        )}
      </div>
      {visibleRelatedInsightsId && (
        <RelatedInsights
          initiativeId={visibleRelatedInsightsId}
          onClose={handleHideRelatedInsights}
        />
      )}
    </div>
  );
};

export default React.memo(InitiativesBoxRenderer);