import React, { useState, useEffect, useContext } from "react";
import {
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import API from "../../../services/api";
import PageTitle from "../../reusable_components/HeadingsAndTitles/PageTitle";
import log from "../../../utils/logger";
import artifactTypes from "../../../ConstantVariables.json";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../../contexts/StatusContext";
import LoadingSpinner from "../../reusable_components/LoadingSpinner/LoadingSpinner";
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";
import ClassifyFilesTable from "./ClassifyFilesTable";
import UploadFileIcon from "../../../assets/icons/UploadFileIcon.svg";
import { initiateFileUpload } from "../../../services/localFileUploadService";
import { useIntegrations } from '../../../contexts/IntegrationsContext'; // Import useIntegrations
import { uploadFilesAndCreateLUFsAndPAEIFs } from "../../../services/uploadFilesAndCreateLUFsAndPAEIFsService";

// Custom styled Alert component
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LocalDocumentsTabContent = () => {
  const [files, setFiles] = useState([]);
  const [totalRows, setTotalRows] = useState(0)
  const [selectedArtifactTypes, setSelectedArtifactTypes] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkArtifactType, setBulkArtifactType] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    key: 0,
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const { numLocalFilesUploaded, refreshIntegrations } = useIntegrations();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    fetchFiles();
  }, [numLocalFilesUploaded, paginationModel]);

  const fetchFiles = async () => {
    try {
      setLoading(true);
      const response = await API.get("file-management/classify-files", {
        params: { 
          page: paginationModel.page, 
          pageSize: paginationModel.pageSize 
        }
      });
      const sortedFiles = sortFilesByArtifactType(response.data.rows);
      setFiles(sortedFiles);
      setInitialArtifactTypes(sortedFiles);
      setTotalRows(response.data.totalFiles)
    } catch (error) {
      console.error("Error fetching files:", error);
      showNotification("Failed to fetch files.", "error");
    } finally {
      setLoading(false);
    }
  };

  const sortFilesByArtifactType = (files) => {
    return files.sort((a, b) => {
      if (!a.artifact_type && b.artifact_type) return -1;
      if (a.artifact_type && !b.artifact_type) return 1;
      return 0;
    });
  };

  const setInitialArtifactTypes = (files) => {
    const initialArtifactTypes = {};
    files.forEach((file) => {
      initialArtifactTypes[file.id] = file.artifact_type || "";
    });
    setSelectedArtifactTypes(initialArtifactTypes);
  };

  const showNotification = (message, severity) => {
    setSnackbar((prev) => ({
      open: true,
      message,
      severity,
      key: prev.key + 1,
    }));
  };

  const handleArtifactTypeChange = async (fileId, artifactType) => {
    setSelectedArtifactTypes((prevTypes) => ({
      ...prevTypes,
      [fileId]: artifactType,
    }));

    try {
      await API.post("file-management/set-artifact-types-for-locally-uploaded-files-and-their-paeifs", {
        file_ids: [fileId],
        selected_artifact_types: [artifactType]
      });
      
      showNotification("Artifact type has been successfully saved.", "success");
      log("Kicking off insights extraction process");
    //   await API.post("/insights/extract_insights_for_user");
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error("Error updating artifact type:", error);
      showNotification("Failed to save artifact type.", "error");
    }
  };

  const handleOptionsClick = (event, fileId) => {
    setAnchorEl(event.currentTarget);
    setSelectedFileId(fileId);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
    setSelectedFileId(null);
  };

  const handleDeleteConfirm = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteConfirmOpen(false);
    try {
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      const response = await API.delete(
        `file-management/delete-files-and-metadata`,
        {
          data: { file_ids: selectedRows },
        },
      );
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      if (response.data.process_id) {
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      }
    //   setFiles((prevFiles) =>
    //     prevFiles.filter((file) => !selectedRows.includes(file.id)),
    //   );
      setSelectedRows([]);
      showNotification("File deletion process has started.", "info");
      
      // Call refreshIntegrations only if a single file is being deleted
      if (selectedRows.length === 1) {
        refreshIntegrations();
      }
    } catch (error) {
      console.error("Error deleting files:", error);
      showNotification("Failed to start file deletion process.", "error");
    }
  };

  const handleBulkArtifactTypeChange = (event) => {
    setBulkArtifactType(event.target.value);
  };

  const applyBulkArtifactType = async () => {
    log("Applying bulk artifact type:", bulkArtifactType);
    log("Selected rows for bulk update:", selectedRows);
    
    const updatedArtifactTypes = { ...selectedArtifactTypes };
    selectedRows.forEach((rowId) => {
      updatedArtifactTypes[rowId] = bulkArtifactType;
    });
    setSelectedArtifactTypes(updatedArtifactTypes);

    try {
      await API.post("file-management/set-artifact-types-for-locally-uploaded-files-and-their-paeifs", {
        file_ids: selectedRows,
        selected_artifact_types: Array(selectedRows.length).fill(bulkArtifactType)
      });
      
      showNotification("Bulk artifact type has been successfully saved.", "success");
      log("Kicking off insights extraction process for bulk update");
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error("Error updating artifact types:", error);
      showNotification("Failed to save bulk artifact type.", "error");
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const renderBatchActions = () => {
    if (selectedRows.length === 0) return null;

    // Determine the artifact type options based on selected rows
    const selectedRowsStructureTypes = new Set(
      selectedRows.map((rowId) => {
        const file = files.find((file) => file.id === rowId);
        return file?.structure_and_content_type_of_file;
      }),
    );

    let artifactTypeOptions = {};

    if (
      selectedRowsStructureTypes.size === 1 &&
      selectedRowsStructureTypes.has('numerical_csv')
    ) {
      // Only numerical_csv selected
      artifactTypeOptions = artifactTypes.numerical_artifact_types;
    } else if (
      !selectedRowsStructureTypes.has('numerical_csv') &&
      (selectedRowsStructureTypes.has('textual_csv') ||
        selectedRowsStructureTypes.has('unstructured'))
    ) {
      // Only textual_csv and/or unstructured selected
      artifactTypeOptions = artifactTypes.textual_artifact_types;
    } else if (
      selectedRowsStructureTypes.size === 2 &&
      selectedRowsStructureTypes.has('textual_csv') &&
      selectedRowsStructureTypes.has('unstructured')
    ) {
      // Only textual_csv and unstructured selected
      artifactTypeOptions = artifactTypes.textual_artifact_types;
    } else {
      // Mixed types selected, show no options
      artifactTypeOptions = {};
    }

    return (
      <div>
        <select
          value={bulkArtifactType}
          onChange={handleBulkArtifactTypeChange}
          disabled={Object.keys(artifactTypeOptions).length === 0}
        >
          <option value="">Batch Select Artifact Type</option>
          {Object.entries(artifactTypeOptions).map(([type, { description }]) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
        <button
          onClick={applyBulkArtifactType}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
          disabled={Object.keys(artifactTypeOptions).length === 0}
        >
          Apply to Selected
        </button>
        {/* <button
          onClick={handleDeleteConfirm}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          Delete
        </button> */}
      </div>
    );
  };

  const handleUploadClick = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    try {
      uploadFilesAndCreateLUFsAndPAEIFs(files);
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      refreshIntegrations();
      fetchFiles(); // Refresh the files list
    } catch (error) {
      console.error('Error uploading files:', error);
      showNotification("Failed to upload files.", "error");
    }
  };

  const handlePaginationModelChange = (newModel) => {
    setPaginationModel(newModel);
  };

  return (
    <div
      style={{
        display: "flex",
        paddingBottom: "200px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "36px",
        alignSelf: "stretch",
      }}
    >
      {loading ? (
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}>
          <LoadingSpinner />
        </div>
      ) : (
        <div
          id="text-and-upload-documents-button-and-classify-files-table"
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "36px",
          }}
        >
          <div
            id="text-and-upload-documents-button"
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "16px",
              alignSelf: "stretch",
            }}
          >
            <div id="Text-instructions"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
              }}
            >
              <p
                style={{
                  alignSelf: "stretch",
                  color: "var(--blue-darkest, #001331)",
                  fontFamily: "Rubik",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "145%",
                }}
              >
                Upload local files to automatically identify insights related to your initiatives
              </p>
              <p
                style={{
                  alignSelf: "stretch",
                  color: "var(--slate-dark, #727F95)",
                  fontFamily: "Roboto Mono",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "130%",
                }}
              >
                Formats accepted: csv, pdf, txt, docx, mp4, mp3, mov, avi, wav, mkv, wmv, m4v, aac, flac, ogg, webm, mpg, mpeg, flv, 3gp.
              </p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleUploadClick}
              multiple
              className="hidden"
            />
            <PrimaryButton
              icon={UploadFileIcon}
              onClick={() => fileInputRef.current.click()}
            >
              Upload Documents
            </PrimaryButton>
          </div>
          {renderBatchActions()}
          <ClassifyFilesTable
            files={files}
            selectedArtifactTypes={selectedArtifactTypes}
            handleArtifactTypeChange={handleArtifactTypeChange}
            handleOptionsClick={handleOptionsClick}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            rowCount={totalRows}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            pageSizeOptions={[25, 50, 100]}
          />
        </div>
      )}
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
      >
        <MenuItem onClick={handleDeleteConfirm}>Delete</MenuItem>
      </Menu> */}
      <Snackbar
        key={snackbar.key}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">
            Confirm Deletion
          </h1>
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: "#727F95",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "135%",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to delete this file(s)? This will also delete
            all insights extracted from them.
          </p>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDeleteConfirmed} color="primary">
            Delete
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LocalDocumentsTabContent;
