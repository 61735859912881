import React, { useState, useEffect, useContext } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, TextField, InputAdornment } from '@mui/material';
import API from '../../../../services/api';
import log from '../../../../utils/logger';
import { StatusContext } from "../../../../contexts/StatusContext";

const SteamIntegrationPageSync = ({ selectedGames }) => {
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState({
    day_range: 30,
    review_type: 'all',
    purchase_type: 'all'
  });

  const fetchSyncParams = async () => {
    try {
      const response = await API.get('steam/get-params');
      const fetchedParams = response.data;
      setParams(prevParams => ({
        ...prevParams,
        ...fetchedParams
      }));
    } catch (error) {
      log.error('Error fetching sync params:', error);
    }
  };

  useEffect(() => {
    if (open) {
      fetchSyncParams();
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleParamChange = (event) => {
    const { name, value } = event.target;
    if (name === 'day_range') {
      const intValue = parseInt(value, 10);
      setParams({ ...params, [name]: isNaN(intValue) ? '' : intValue });
    } else {
      setParams({ ...params, [name]: value });
    }
  };

  const handleSyncReviews = async () => {
    try {
      const syncParams = {
        ...params,
        day_range: params.day_range || 30
      };
      const response = await API.post('steam/sync-reviews', {
        params: syncParams
      });
      setTimeout(() => {
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      }, 1000);
      log('Sync response:', response.data);
      // Handle successful sync (e.g., show a success message)
    } catch (error) {
      log.error('Error syncing reviews:', error);
      // Handle error (e.g., show an error message)
    }
    handleClose();
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        disabled={selectedGames.length === 0}
      >
        SYNC REVIEWS FOR SELECTED GAMES
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sync Settings</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            name="day_range"
            label="Reviews Since"
            type="number"
            value={params.day_range}
            onChange={handleParamChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">days ago</InputAdornment>,
            }}
            data-testid="reviews-since-text-box"
          />
          <TextField
            select
            fullWidth
            margin="normal"
            name="review_type"
            label="Review Type"
            value={params.review_type}
            onChange={handleParamChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="positive">Positive</MenuItem>
            <MenuItem value="negative">Negative</MenuItem>
          </TextField>
          <TextField
            select
            fullWidth
            margin="normal"
            name="purchase_type"
            label="Purchase Type"
            value={params.purchase_type}
            onChange={handleParamChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="steam">Steam</MenuItem>
            <MenuItem value="non_steam_purchase">Non-Steam Purchase</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSyncReviews} variant="contained" color="primary">
            Sync Reviews
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SteamIntegrationPageSync;
