import React from "react";

const AnchorMenuTab = ({ text, isActive, onClick }) => {
   const textStyles = isActive
      ? {
           color: "var(--blue-darkest, #001331)",
           fontFamily: "Rubik",
           fontSize: "16px",
           fontStyle: "normal",
           fontWeight: 500,
           lineHeight: "125%",
           textTransform: "capitalize"
        }
      : {
           color: "var(--slate-dark, #727F95)",
           fontFamily: "Rubik",
           fontSize: "16px",
           fontStyle: "normal",
           fontWeight: 400,
           lineHeight: "125%",
           textTransform: "capitalize"
        };

   return (
      <div
         style={{
            display: "flex",
            padding: "10px 0px",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            cursor: "pointer"
         }}
         onClick={onClick}
      >
         <div style={textStyles}>
            {text}
         </div>
      </div>
   );
};

export default AnchorMenuTab;
