import React, { useContext, useState, useRef } from "react";
import IntegrationCard from "../IntegrationCard";
import { useIntegrations } from "../../../contexts/IntegrationsContext";
import { useAuth } from "../../../contexts/AuthContext";
import API from "../../../services/api"; // Import the API instance
import {
  initiateJiraOAuth,
  handleRemoveIntegration,
  initiateSlackOAuth,
} from "../integrationOAuthHelpers";
import {
  getSupportedFileTypes,
  uploadFiles,
} from "../../../services/localFileUploadService";
import { useNavigate } from "react-router-dom";
import { StatusContext } from "../../../contexts/StatusContext";
import JiraIntegrationLogo from "../../../assets/icons/ExternalIntegrationsIcons/jira_logo.svg";
import SteamLogo from "../../../assets/icons/ExternalIntegrationsIcons/steam_logo.svg";
import DocIcon from "../../../assets/icons/DocIcon.svg";
import UploadFileIcon from "../../../assets/icons/UploadFileIcon.svg";
import { initiateFileUpload } from "../../../services/localFileUploadService";
import SlackLogo from "../../../assets/icons/ExternalIntegrationsIcons/slack.svg";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";
import { uploadFilesAndCreateLUFsAndPAEIFs } from "../../../services/uploadFilesAndCreateLUFsAndPAEIFsService";

const AllIntegrationsTabContent = ({ onTabChange }) => {
  const {
    numLocalFilesUploaded,
    integrations,
    refreshIntegrations,
    activateSteam,
  } = useIntegrations();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setIsCurrentlyCheckingForUpdatesOnProcesses } =
    useContext(StatusContext);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleJiraIntegration = () => {
    initiateJiraOAuth(user);
  };

  const handleJiraDisconnect = () => {
    handleRemoveIntegration("jira");
  };

  const handleJiraConfigureClick = () => {
    onTabChange("Jira");
  };

  const jiraDropdownOptions = [
    { label: "Remove Integration", onClick: handleJiraDisconnect },
    { label: "Configure", onClick: handleJiraConfigureClick }, // Updated this line
  ];

  const handleJiraConnectedChipClick = () => {
    handleRemoveIntegration("jira");
  };

  const handleLocalDocumentsUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    try {
      await uploadFilesAndCreateLUFsAndPAEIFs(files);
      refreshIntegrations();
      setIsCurrentlyCheckingForUpdatesOnProcesses(true);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleLocalDocumentsChipClick = () => {
    onTabChange("Local Documents");
  };

  const handleSteamIntegration = async () => {
    try {
      await activateSteam();
      console.log("Steam integration activated successfully");
      refreshIntegrations(); // Refresh the integrations state after activation
    } catch (error) {
      console.error("Failed to activate Steam integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleSteamDisconnect = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteConfirmOpen(false);
    try {
      const response = await API.post("steam/uninstall-steam-integration");
      if (response.status === 202) {
        console.log("Steam integration uninstallation process started");
        // Optionally, you can show a success message to the user
        refreshIntegrations();
      }
    } catch (error) {
      console.error("Failed to uninstall Steam integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
  };

  const handleSteamConfigureClick = () => {
    onTabChange("Steam");
  };

  const steamDropdownOptions = [
    { label: "Remove Integration", onClick: handleSteamDisconnect },
    { label: "Configure", onClick: handleSteamConfigureClick },
  ];

  const handleSlackIntegration = () => {
    initiateSlackOAuth(user);
  };

  const handleSlackDisconnect = async () => {
    try {
      const response = await API.post("slack/oauth/uninstall");
      if (response.status === 200) {
        console.log("Slack integration disconnected successfully");
        refreshIntegrations(); // Refresh the integrations state after disconnection
      }
    } catch (error) {
      console.error("Failed to disconnect Slack integration:", error);
      // Optionally, you can show an error message to the user
    }
  };

  const handleSlackConfigureClick = () => {
    onTabChange("Slack");
  };

  const slackDropdownOptions = [
    { label: "Remove Integration", onClick: handleSlackDisconnect },
    { label: "Configure", onClick: handleSlackConfigureClick },
  ];

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleLocalDocumentsUpload}
        multiple
        className="hidden"
      />
      <div
        id="integrations-cards-container"
        style={{
          display: "flex",
          paddingBottom: "200px",
          alignItems: "flex-start",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <IntegrationCard
          logoSrc={JiraIntegrationLogo}
          headerText="Jira"
          captionText="Integrate with JIRA to import and sync your project's user stories"
          onButtonClick={handleJiraIntegration}
          isIntegrated={!!integrations.jira}
          buttonIconSrc={JiraIntegrationLogo}
          buttonText="Add Jira Integration"
          showCount={false}
          connectedChipDropdownOptions={jiraDropdownOptions}
          dataTestID="jira-integration-card" // Added data-testid
        />
        <IntegrationCard
          logoSrc={SteamLogo}
          headerText="Steam"
          captionText="Connect your Steam account to analyze gaming habits and preferences"
          onButtonClick={handleSteamIntegration}
          isIntegrated={!!integrations.steam}
          buttonIconSrc={SteamLogo}
          buttonText="Add Steam Integration"
          showCount={false}
          connectedChipDropdownOptions={steamDropdownOptions}
          connectedChipText="Connected"
          dataTestID="steam-integration-card" // Added data-testid
        />
        {/* <IntegrationCard
          logoSrc={SlackLogo}
          headerText="Slack"
          captionText="Integrate with Slack to connect your workspace and align with stakeholders"
          onButtonClick={handleSlackIntegration}
          isIntegrated={!!integrations.slack}
          buttonIconSrc={SlackLogo}
          buttonText="Add Slack Integration"
          showCount={false}
          connectedChipDropdownOptions={slackDropdownOptions}
          connectedChipText="Connected"
          dataTestID="slack-integration-card" // Added data-testid
        /> */}
        <IntegrationCard
          logoSrc={DocIcon}
          headerText="Local Documents"
          captionText="Upload local files to automatically identify insights related to your initiatives"
          onButtonClick={() => fileInputRef.current.click()}
          isIntegrated={numLocalFilesUploaded > 0}
          buttonIconSrc={UploadFileIcon}
          buttonText="Upload Files"
          showCount={true}
          count={numLocalFilesUploaded}
          connectedChipText="View documents"
          onConnectedChipClick={handleLocalDocumentsChipClick}
          dataTestID="local-files-integration-card"
        />
      </div>
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">
            Confirm Steam Integration Removal
          </h1>
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: "#727F95",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "135%",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to remove the Steam integration? This will also delete all associated game reviews and insights extracted from them.
          </p>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDeleteConfirm} color="primary">
            Remove
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AllIntegrationsTabContent;
