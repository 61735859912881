/**
 * ReusableLineChartComponentWithRecharts
 *
 * This component renders a highly reusable line chart using the Recharts library.
 *
 * Props:
 * - chart_data_objects_array: Array of data objects to be plotted.
 *   Each data object should have keys corresponding to the `x_axis_data_key_string` and each key in `line_data_keys_array`.
 * - line_data_keys_array: Array of strings representing the data keys for each line to be plotted on the chart.
 * - x_axis_data_key_string: String representing the data key for the x-axis values.
 * - chart_container_width_in_pixels_number: Number representing the width of the chart container in pixels.
 * - chart_container_height_in_pixels_number: Number representing the height of the chart container in pixels.
 * - line_color_values_array: Array of strings representing the color values for each line (e.g., hex color codes).
 * - additional_line_chart_properties_object: (Optional) Object containing any additional properties to be passed to the LineChart component.
 * - show_legend?: boolean; // Added prop
 */

import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChartProps,
} from 'recharts';

interface ReusableLineChartComponentWithRechartsProps {
    chart_data_objects_array: Array<Record<string, any>>;
    line_data_keys_array: Array<string>;
    x_axis_data_key_string: string;
    chart_container_width_in_pixels_number: number;
    chart_container_height_in_pixels_number: number;
    line_color_values_array: Array<string>;
    additional_line_chart_properties_object?: LineChartProps;
    show_legend?: boolean; // Added prop
}

function ReusableLineChartComponentWithRecharts({
    chart_data_objects_array,
    line_data_keys_array,
    x_axis_data_key_string,
    chart_container_width_in_pixels_number,
    chart_container_height_in_pixels_number,
    line_color_values_array,
    additional_line_chart_properties_object,
    show_legend = false, // Added with default value
}: ReusableLineChartComponentWithRechartsProps) {
    return (
        <ResponsiveContainer width="97%" height={chart_container_height_in_pixels_number}>
            <LineChart
                data={chart_data_objects_array}
                {...additional_line_chart_properties_object}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={x_axis_data_key_string} />
                <YAxis />
                <Tooltip />
                {show_legend && <Legend />} {/* Modified to conditional rendering */}
                {line_data_keys_array.map((line_data_key_string: string, line_index_number: number) => (
                    <Line
                        type="monotone"
                        dataKey={line_data_key_string}
                        stroke={
                            line_color_values_array[line_index_number % line_color_values_array.length]
                        }
                        key={line_data_key_string}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

export default ReusableLineChartComponentWithRecharts;