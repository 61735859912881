import React, { useState } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import PageTitle from "../reusable_components/HeadingsAndTitles/PageTitle";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import { usePayment } from "../../contexts/PaymentContext";
import log from "../../utils/logger";

const LoginForm = () => {
   const { login } = useAuth();
   const { createCheckoutSession } = usePayment();
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [error, setError] = useState("");
   const [showRenewal, setShowRenewal] = useState(false);
   const [userId, setUserId] = useState(null);
   const navigate = useNavigate();

   const handleLogin = async (event) => {
      event.preventDefault();
      setError("");
      setShowRenewal(false);
      setUserId(null);
      log("Attempting to log in with username: ", username);
      try {
         const result = await login(username, password);
         if (result.success) {
            if (result.data.subscription_status === "active") {
               navigate("/initiatives"); // Redirect to home page on successful login
            } else {
               setError("Your subscription has expired. Please renew to continue.");
               setShowRenewal(true);
               setUserId(result.data.user.userId);
            }
         } else {
            setError(result.message);
         }
      } catch (error) {
         log("Login failed:", error);
         setError(error.message || "An unexpected error occurred. Please try again later.");
      }
   };

   const handleRenewSubscription = async () => {
      try {
         if (!userId) {
            setError("User ID is missing. Please try logging in again.");
            return;
         }
         const checkoutUrl = await createCheckoutSession(userId);
         if (checkoutUrl) {
            window.location.href = checkoutUrl;
         } else {
            setError("Failed to create checkout session");
         }
      } catch (error) {
         log("Failed to create checkout session:", error);
         setError("Failed to create checkout session");
      }
   };

   const handleRegisterRedirect = () => {
      navigate("/register");
   };

   return (
      <div>
         <PageTitle>Login</PageTitle>
         <form onSubmit={handleLogin} className="max-w-sm mx-auto mt-10 p-5 border rounded-lg shadow-lg">
            <div className="mb-4">
               <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
                  Username:
               </label>
               <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            <div className="mb-6">
               <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                  Password:
               </label>
               <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
               />
            </div>
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-between">
               <PrimaryButton type="submit">Login</PrimaryButton>
               <PrimaryButton type="button" onClick={handleRegisterRedirect}>
                  Sign Up
               </PrimaryButton>
            </div>
         </form>
         {showRenewal && (
            <div className="text-center mt-4">
               <PrimaryButton type="button" onClick={handleRenewSubscription} className="mb-4">
                  Renew Subscription
               </PrimaryButton>
            </div>
         )}
         <div className="text-center mt-4">
            <Link to="/forgot-password" className="text-blue-500 hover:text-blue-700">
               Forgot Password?
            </Link>
         </div>
      </div>
   );
};

export default LoginForm;
