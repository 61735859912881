import axios from 'axios';
import AuthService from '../components/auth/AuthService';
import { useNavigate } from 'react-router-dom';
import log from '../utils/logger'; // Import the custom log function

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Adjust this URL based on your environment
});

API.interceptors.request.use(
  async config => {
    const access_token = await AuthService.getJWTToken();
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    // Add the X-React-Page header with the current pathname
    config.headers['X-React-Page'] = window.location.pathname;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Response interceptor for handling 401 errors
API.interceptors.response.use(
  response => {
    // If the response is successful, just pass it through
    return response;
  },
  error => {
    // Check if the error is a 401 and handle it
    if (error.response && error.response.status === 401) {
      // Handle 401 error, e.g., redirect to login or refresh token
      log('Unauthorized! Redirecting to login.');
      AuthService.logout().then(() => {
        window.location.href = '/login'; // Redirect to login after logout
     });
      // alert('Unauthorized! Redirecting to login.');
      // Redirect or perform any action needed
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default API;