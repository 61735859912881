import React, { useState, useEffect } from 'react';
import PageTitle from '../../../reusable_components/HeadingsAndTitles/PageTitle';
import SteamIntegrationSearch from './SteamTabContentSearchTab';
import SteamIntegrationSelectedGames from './SteamTabContentSelectedGamesTab';
import SteamIntegrationPageSync from './SteamTabContentPageSync';

import { Tabs, Tab, Box } from '@mui/material';
import API from '../../../../services/api';

function SteamIntegrationPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedGames, setSelectedGames] = useState([]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Fetch selected games when the component mounts or when activeTab changes
  useEffect(() => {
    fetchSelectedGames();
  }, [activeTab]);

  const fetchSelectedGames = async () => {
    try {
      const response = await API.get("steam/selected-games");
      setSelectedGames(response.data.games);
    } catch (error) {
      console.error("Error fetching selected games:", error);
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Search" />
          <Tab label="Selected Games" />
        </Tabs>
        <SteamIntegrationPageSync selectedGames={selectedGames} />
      </Box>
      {activeTab === 0 && <SteamIntegrationSearch setSelectedGames={setSelectedGames} selectedGames={selectedGames} />}
      {activeTab === 1 && <SteamIntegrationSelectedGames selectedGames={selectedGames} setSelectedGames={setSelectedGames} />}
    </div>
  );
}

export default SteamIntegrationPage;
