import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";
import API from "../../services/api";
import AuthService from "../auth/AuthService";

const DevManagementMenu = () => {
   const [isOpen, setIsOpen] = useState(false);
   const menuRef = useRef(null);
   const [userId, setUserId] = useState("");
   const [isExtracting, setIsExtracting] = useState(false);

   useEffect(() => {
      const handleMouseMove = (event) => {
         const onePixelInCm = 37.795; // Approximate conversion from cm to pixels
         const triggerDistance = onePixelInCm; // 1 cm in pixels

         if (event.clientX <= triggerDistance) {
            setIsOpen(true);
         }
      };

      const handleMenuMouseLeave = (event) => {
         // Check if the mouse has actually left the menu
         if (!menuRef.current?.contains(event.relatedTarget)) {
            setIsOpen(false);
         }
      };

      const handleDocumentMouseLeave = () => {
         setIsOpen(false);
      };

      const handleWindowBlur = () => {
         setIsOpen(false);
      };

      document.addEventListener("mousemove", handleMouseMove);
      menuRef.current?.addEventListener("mouseleave", handleMenuMouseLeave);
      document.body.addEventListener("mouseleave", handleDocumentMouseLeave);
      window.addEventListener("blur", handleWindowBlur);

      return () => {
         document.removeEventListener("mousemove", handleMouseMove);
         menuRef.current?.removeEventListener("mouseleave", handleMenuMouseLeave);
         document.body.removeEventListener("mouseleave", handleDocumentMouseLeave);
         window.removeEventListener("blur", handleWindowBlur);
      };
   }, []);

   const fetchUserDetails = async () => {
      try {
         const userDetails = await AuthService.getUserAndCompanyDetails();
         setUserId(userDetails.userId);
      } catch (error) {
         console.error("Error fetching user details:", error);
      }
   };

   React.useEffect(() => {
      fetchUserDetails();
   }, []);

   const handleRateInitiatives = async () => {
      try {
         await API.post("/initiatives-page/rate_impact_and_complexity_for_user_initiatives");
         alert("Initiatives rated successfully!");
      } catch (error) {
         console.error("Error rating initiatives:", error);
         alert("Failed to rate initiatives. Please try again.");
      }
   };

   const [initiativeId, setInitiativeId] = useState("");

   const handleDeleteRelatedInsights = async () => {
      try {
         await API.delete("/dev-management/delete-initiative-related-insights", {
            data: { initiative_id: initiativeId }
         });
         alert("Related insights deleted successfully!");
      } catch (error) {
         console.error("Error deleting related insights:", error);
         alert("Failed to delete related insights. Please try again.");
      }
   };

   const handleResetJiraTimestamp = async () => {
      try {
         const response = await API.post("/dev-management/reset-jira-integration-timestamp");
         console.log(response.data.message);
         alert("Jira integration timestamp reset successfully!");
      } catch (error) {
         console.error("Error resetting Jira timestamp:", error);
         alert("Failed to reset Jira integration timestamp. Please try again.");
      }
   };

   const handleCalculateRelevanceScores = async () => {
      try {
         await API.post("/initiatives-page/calculate-relevance-score-for-all-initiatives-and-save-to-database");
         alert("Relevance scores calculated and saved successfully!");
      } catch (error) {
         console.error("Error calculating relevance scores:", error);
         alert("Failed to calculate relevance scores. Please try again.");
      }
   };

   const handleExtractInsights = async () => {
      setIsExtracting(true);
      try {
         const response = await API.post('/insights/extract_insights_for_user');
         console.log('Insights extraction initiated:', response.data);
      } catch (error) {
         console.error('Error initiating insights extraction:', error);
      }
      setIsExtracting(false);
   };

   const handleTriangulateFeatures = async () => {
      try {
         const response = await API.post('/dev-management/initiate-triangulated-feature-triangulation');
         console.log('Feature triangulation initiated:', response.data);
      } catch (error) {
         console.error('Error initiating feature triangulation:', error);
         alert('Failed to initiate feature triangulation. Please try again.');
      }
   };

   const handle_triangulate_pain_points = async () => {
      try {
         const response = await API.post('/dev-management/triangulate-pain-points');
         console.log('Pain point triangulation initiated:', response.data);
         alert('Pain point triangulation completed successfully!');
      } catch (error) {
         console.error('Error initiating pain point triangulation:', error);
         alert('Failed to triangulate pain points. Please try again.');
      }
   };

   return (
      <div
         ref={menuRef}
         style={{
            position: "fixed",
            left: isOpen ? "0" : "-300px",
            top: "0",
            bottom: "0",
            width: "300px",
            backgroundColor: "#f0f0f0",
            transition: "none", // Remove transition for instant open/close
            zIndex: "1000",
            padding: "20px"
         }}
      >
         <p>Dev Management Menu - this is used by the developers and won't be visible to the users.</p>
         <p>User ID: {userId}</p>
         <PrimaryButton onClick={handleRateInitiatives}>
            Rate Impact and Complexity for User's Initiatives
         </PrimaryButton>
         <div style={{ marginTop: "20px" }}>
            <h4>Delete Initiative's Related Insights</h4>
            <input
               type="text"
               value={initiativeId}
               onChange={(e) => setInitiativeId(e.target.value)}
               placeholder="Enter Initiative DB ID"
               style={{ width: "100%", marginBottom: "10px" }}
            />
            <PrimaryButton onClick={handleDeleteRelatedInsights}>Delete Related Insights</PrimaryButton>
         </div>
         <div style={{ marginTop: "20px" }}>
            <PrimaryButton onClick={handleResetJiraTimestamp}>Reset Jira Integration Timestamp</PrimaryButton>
         </div>
         <div style={{ marginTop: "20px" }}>
            <PrimaryButton onClick={handleCalculateRelevanceScores}>Calculate Relevance Scores</PrimaryButton>
         </div>
         <div style={{ marginTop: "20px" }}>
            <PrimaryButton onClick={handleExtractInsights} disabled={isExtracting}>
               {isExtracting ? 'Extracting Insights...' : 'Extract Insights'}
            </PrimaryButton>
         </div>
         <div style={{ marginTop: "20px" }}>
            <PrimaryButton onClick={handleTriangulateFeatures}>
               Triangulate Features
            </PrimaryButton>
         </div>
         <div style={{ marginTop: "20px" }}>
            <PrimaryButton onClick={handle_triangulate_pain_points}>
               Triangulate Pain Points
            </PrimaryButton>
         </div>
      </div>
   );
};

export default DevManagementMenu;