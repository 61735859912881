import React, { useState } from "react";
import { Link } from "react-router-dom";

// Each element in currentSolutionsListOfDicts contains keys: method, challenge, and associated_mention
const CurrentSolutionsTable = ({ currentSolutionsListOfDicts }) => {
   const [showAllSolutions, setShowAllSolutions] = useState(false);
   const initialSolutionsToShow = 3;

   const visibleSolutions = showAllSolutions
      ? currentSolutionsListOfDicts
      : currentSolutionsListOfDicts.slice(0, initialSolutionsToShow);

   return (
      <div
         id="CurrentSolutionsSection"
         style={{
            display: "flex",
            padding: "24px 20px",
            alignItems: "flex-start",
            gap: "10px",
            alignSelf: "stretch",
            borderRadius: "12px",
            border: "1px solid var(--slate-lighter, #E5EAF3)",
            background: "var(--white, #FFF)"
         }}
      >
         <div
            id="CurrentSolutionsTableTitleAndContent"
            style={{
               display: "flex",
               flexDirection: "column",
               alignItems: "flex-start",
               gap: "16px",
               flex: "1 0 0"
            }}
         >
            <div
               id="CurrentSolutionsTableTitle"
               style={{
                  alignSelf: "stretch",
                  color: "var(--blue-darkest, var(--blue-darkest, #001331))",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "uppercase"
               }}
            >
               CURRENT SOLUTIONS
            </div>
            <div
               id="CurrentSolutionsTable"
               style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                  alignSelf: "stretch"
               }}
            >
               <div
                  id="CurrentSolutionsTableColumnHeaders"
                  style={{
                     display: "flex",
                     paddingLeft: "24px",
                     alignItems: "flex-start",
                     gap: "20px",
                     alignSelf: "stretch"
                  }}
               >
                  <div
                     style={{
                        flex: "1 0 0",
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase"
                     }}
                  >
                     METHOD
                  </div>
                  <div
                     style={{
                        flex: "1 0 0",
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase"
                     }}
                  >
                     CHALLENGES
                  </div>
                  <div
                     style={{
                        flex: "1 0 0",
                        color: "var(--slate-dark, #727F95)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase"
                     }}
                  >
                     SOURCES
                  </div>
               </div>
               <div
                  id="CurrentSolutionsTableRows"
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "flex-start",
                     gap: "16px",
                     alignSelf: "stretch"
                  }}
               >
                  {visibleSolutions.map((solution, index) => (
                     <React.Fragment key={index}>
                        <div
                           id={`CurrentSolutionsTableRow-${index}`}
                           style={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "20px",
                              alignSelf: "stretch",
                              paddingLeft: "24px"
                           }}
                        >
                           <div
                              id={`CurrentSolutionsTableRowKeyAccountSymbolAndMethod-${index}`}
                              style={{
                                 flex: "1 0 0",
                                 display: "flex",
                                 alignItems: "center",
                                 gap: "4px"
                              }}
                           >
                              {solution.isAKeyAccount && (
                                 <div id={`KeyAccountSymbol-${index}`}>
                                     <svg
                                         xmlns="http://www.w3.org/2000/svg"
                                         width="20"
                                         height="20"
                                         viewBox="0 0 20 20"
                                         fill="none"
                                     >
                                         <path
                                             d="M9.99982 13.2979L6.70169 9.99982L9.99982 6.70169L13.2979 9.99982L9.99982 13.2979ZM11.0623 17.1792C10.9202 17.3213 10.7557 17.43 10.5688 17.5054C10.3818 17.5807 10.1922 17.6184 9.99982 17.6184C9.80746 17.6184 9.6178 17.5807 9.43086 17.5054C9.24392 17.43 9.0794 17.3213 8.93732 17.1792L2.79628 11.0384C2.65961 10.9016 2.55357 10.7397 2.47815 10.5527C2.40287 10.3658 2.36523 10.1788 2.36523 9.9919C2.36523 9.80482 2.40287 9.6178 2.47815 9.43086C2.55357 9.24392 2.65961 9.08211 2.79628 8.94544L8.92461 2.81711C9.07517 2.66655 9.24176 2.55357 9.4244 2.47815C9.60718 2.40287 9.79898 2.36523 9.99982 2.36523C10.2007 2.36523 10.3925 2.40287 10.5752 2.47815C10.7579 2.55357 10.9245 2.66655 11.075 2.81711L17.2034 8.94544C17.34 9.08211 17.4461 9.24392 17.5215 9.43086C17.5968 9.6178 17.6344 9.80482 17.6344 9.9919C17.6344 10.1788 17.5968 10.3658 17.5215 10.5527C17.4461 10.7397 17.34 10.9016 17.2034 11.0384L11.0623 17.1792ZM10.1361 15.8557L15.8636 10.1279C15.9009 10.0906 15.9196 10.0452 15.9196 9.9919C15.9196 9.93843 15.9009 9.89301 15.8636 9.85565L10.1361 4.12794C10.0987 4.09058 10.0533 4.07322 9.99982 4.07586C9.94635 4.07864 9.90093 4.09871 9.86357 4.13607L4.12794 9.87169C4.09058 9.90905 4.07322 9.95176 4.07586 9.99982C4.07864 10.0479 4.09871 10.0906 4.13607 10.1279L9.86357 15.8557C9.90093 15.893 9.94635 15.9117 9.99982 15.9117C10.0533 15.9117 10.0987 15.893 10.1361 15.8557Z"
                                             fill="#104499"
                                         />
                                     </svg>
                                 </div>
                              )}
                              <div
                                 style={{
                                    color: "var(--blue-darkest, #001331)",
                                    fontFamily: "Rubik",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "145%"
                                 }}
                              >
                                 {solution.method}
                              </div>
                           </div>
                           <div
                             style={{
                               flex: "1 0 0",
                               color: "var(--blue-darkest, #001331)",
                               fontFamily: "Rubik",
                               fontSize: "16px",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "140%"
                             }}
                           >
                             {solution.challenge}
                           </div>
                           <div id={`CurrentSolutionsTableRowSource-${index}`}
                             style={{
                               display: "flex",
                               flexDirection: "column",
                               alignItems: "flex-start",
                               gap: "4px",
                               flex: "1 0 0"
                             }}
                           >
                              <div className="CurrentSolutionsTableRowSourceFileName" style={{
                                alignSelf: "stretch",
                                color: "var(--blue-darkest, #001331)",
                                fontFamily: "Rubik",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "140%"
                              }}>{solution.source_artifact_name}</div>
                              {solution.associated_mention && solution.associated_mention.id && (
                                <Link 
                                  to={`/doc-viewer/${solution.source_artifact_id}/${solution.associated_mention.id}`}
                                  className="SourceEntryLineAndQuoteText"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    alignSelf: "stretch",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                  }}
                                >
                                  <div className="SourceEntryLine" style={{
                                    width: "2px",
                                    alignSelf: "stretch",
                                    borderRadius: "2px",
                                    background: "var(--blue-medium, #628FDA)"
                                  }}></div>
                                  <div className="SourceEntryQuoteText" style={{
                                    flex: "1 0 0",
                                    color: "var(--slate-dark, #727F95)",
                                    fontFamily: "Roboto Mono",
                                    fontSize: "16px",
                                    fontStyle: "italic", 
                                    fontWeight: 400,
                                    lineHeight: "140%"
                                  }}>{solution.associated_mention.span_text}</div>
                                </Link>
                              )}
                           </div>
                        </div>
                        {index < visibleSolutions.length - 1 && (
                           <div
                              style={{
                                 width: "100%",
                                 height: "1px",
                                 background: "var(--slate-light, #C5D1E4)"
                              }}
                           />
                        )}
                     </React.Fragment>
                  ))}
               </div>
            </div>
            {currentSolutionsListOfDicts.length > initialSolutionsToShow && (
               <button
                  onClick={() => setShowAllSolutions(!showAllSolutions)}
                  style={{
                     alignSelf: "center",
                     marginTop: "12px",
                     padding: "8px 16px",
                     backgroundColor: "var(--blue-medium, #628FDA)",
                     color: "white",
                     border: "none",
                     borderRadius: "4px",
                     cursor: "pointer",
                     fontFamily: "Roboto Mono",
                     fontSize: "14px",
                     fontWeight: 500
                  }}
               >
                  {showAllSolutions
                     ? "Show Less"
                     : `Show ${currentSolutionsListOfDicts.length - initialSolutionsToShow} More`}
               </button>
            )}
         </div>
      </div>
   );
};

export default CurrentSolutionsTable;
