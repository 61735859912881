import API from './api';

export const uploadFilesAndCreateLUFsAndPAEIFs = async (files) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append(`file${i}`, files[i]);
  }

  const response = await API.post('/upload-files-and-create-LUFs-and-PAEIFs/upload-files-and-create-LUFs-and-PAEIFs', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};
