import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { usePayment } from "../../contexts/PaymentContext";
import PrimaryButton from "../reusable_components/Buttons/PrimaryButton";

const PaymentForm = ({ userId, isRenewal = false }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [error, setError] = useState("");
   const navigate = useNavigate();
   const { createCheckoutSession } = usePayment();

   useEffect(() => {
      if (!userId) {
         navigate("/register");
      }
   }, [navigate, userId]);

   const handlePayment = async () => {
      setIsLoading(true);
      setError("");
      try {
         const checkoutUrl = await createCheckoutSession(userId);
         window.location.href = checkoutUrl;
      } catch (error) {
         setError("An error occurred while processing your request");
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl">
         <h2 className="text-2xl font-bold mb-6 text-center">
            {isRenewal ? "Renew Your Subscription" : "Complete Your Subscription"}
         </h2>
         {error && <p className="text-red-500 text-center mb-4">{error}</p>}
         <div className="flex justify-center">
            <PrimaryButton onClick={handlePayment} disabled={isLoading} className="w-full max-w-xs">
               {isLoading ? "Processing..." : isRenewal ? "Renew Subscription" : "Proceed to Payment"}
            </PrimaryButton>
         </div>
      </div>
   );
};

export default PaymentForm;
