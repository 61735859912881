import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import API from "../../../services/api";
import log from "../../../utils/logger";
import HeadwindAndTailwindCard from "../../reusable_components/InsightsCards/HeadwindAndTailwindCard";
import KPICard from "../../reusable_components/InsightsCards/KPICard";
import PageTitle from "../../reusable_components/HeadingsAndTitles/PageTitle";
import DocWhereInsightIsMentionedCard from "../../reusable_components/DocWhereInsightIsMentionedCard";
import PageLoadSpinner from "../../reusable_components/PageLoadSpinner/PageLoadSpinner";
import { StatusContext } from '../../../contexts/StatusContext';
import PrimaryButton from "../../reusable_components/Buttons/PrimaryButton";
import RelatedInitiativeCard from "../../reusable_components/Cards/RelatedInitiativeCard";

function SingleObjectivePage() {
   const { objectiveId } = useParams();
   const [objectiveData, setObjectiveData] = useState(null);
   const [relatedInitiatives, setRelatedInitiatives] = useState([]);
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   useEffect(() => {
      const fetchObjectiveData = async () => {
         try {
            const response = await API.get(`/insights/objective/${objectiveId}`);
            log("Objective data:", response.data);
            setObjectiveData(response.data);
            setRelatedInitiatives(response.data.related_initiatives || []);
            // Update the following line to log source_docs and its number of entries
            console.log(
               "Source docs:",
               response.data.source_docs,
               "Number of entries:",
               Object.keys(response.data.source_docs).length
            );
         } catch (error) {
            log("Error fetching objective data:", error);
         }
      };

      fetchObjectiveData();
   }, [objectiveId]);

   const handleFindRelatedInitiatives = async () => {
      try {
         const response = await API.post('insights/find-and-store-related-initiatives-for-insight', {
            insight_id: objectiveId,
            insight_type: 'objective_kg_node'
         });
         if (response.status === 202) {
            console.log("Related initiatives search started");
            setIsCurrentlyCheckingForUpdatesOnProcesses(true);
         } else {
            throw new Error("Failed to start related initiatives search");
         }
      } catch (error) {
         console.error("Error starting related initiatives search:", error);
      }
   };

   if (!objectiveData) {
      return <PageLoadSpinner />;
   }

   const headwinds = objectiveData.headwindsAndTailwinds.filter((item) => item.type === "headwind");
   const tailwinds = objectiveData.headwindsAndTailwinds.filter((item) => item.type === "tailwind");
   return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
         <PageTitle className="text-3xl font-bold text-gray-900 mb-6">{objectiveData.objective_kg_node.title}</PageTitle>
         <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-16">
            <div className="px-4 py-5 sm:px-6">
               {objectiveData.objective_kg_node.description && (
                  <p className="text-lg text-gray-700 mb-4">{objectiveData.objective_kg_node.description}</p>
               )}
               {objectiveData.objective_kg_node.qualitative_summary && (
                  <p className="text-md text-gray-600 mb-4">
                     <span className="font-semibold">Summary:</span> {objectiveData.objective_kg_node.qualitative_summary}
                  </p>
               )}
               {objectiveData.objective_kg_node.timeframe && (
                  <p className="text-md text-gray-600">
                     <span className="font-semibold">Timeframe:</span> {objectiveData.objective_kg_node.timeframe} months
                  </p>
               )}
            </div>
         </div>

         {objectiveData.kpis.length > 0 && (
            <div className="mb-12">
               <h3 className="text-2xl font-bold text-gray-900 mb-6">Related KPIs</h3>
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {objectiveData.kpis.map((kpi) => (
                     <KPICard key={kpi.id} kpi={kpi} />
                  ))}
               </div>
            </div>
         )}

         {objectiveData.source_docs && Object.keys(objectiveData.source_docs).length > 0 && (
            <div className="mb-12">
               <h3 className="text-2xl font-bold text-gray-900 mb-6">Mentioned in these files:</h3>
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {Object.entries(objectiveData.source_docs).map(([processed_artifact_to_extract_insights_from_mongo_id, docData]) => (
                     <DocWhereInsightIsMentionedCard
                        key={processed_artifact_to_extract_insights_from_mongo_id}
                        insight_id={objectiveId}
                        processed_artifact_to_extract_insights_from_mongo_id={processed_artifact_to_extract_insights_from_mongo_id}
                        choose_type_kg_node_mongo_aggregate_insight_or_mongo_individual_insight="kg_node"
                     />
                  ))}
               </div>
            </div>
         )}

         <div className="mb-12">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Related Initiatives</h3>
            <PrimaryButton onClick={handleFindRelatedInitiatives}>
               Find Related Initiatives
            </PrimaryButton>

            {objectiveData.last_time_find_related_initiatives_was_ran && relatedInitiatives.length > 0 && (
               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                  {relatedInitiatives.map((initiative) => (
                     <RelatedInitiativeCard
                        key={initiative.id}
                        title={initiative.title}
                        description={initiative.description}
                        explanation_of_relatedness_decision={initiative.explanation_of_relatedness_decision}
                     />
                  ))}
               </div>
            )}
         </div>

         <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {headwinds.length > 0 && (
               <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-6">Headwinds</h3>
                  <div className="space-y-4">
                     {headwinds.map((item) => (
                        <HeadwindAndTailwindCard key={item.id} headwindOrTailwind={item} />
                     ))}
                  </div>
               </div>
            )}

            {tailwinds.length > 0 && (
               <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-6">Tailwinds</h3>
                  <div className="space-y-4">
                     {tailwinds.map((item) => (
                        <HeadwindAndTailwindCard key={item.id} headwindOrTailwind={item} />
                     ))}
                  </div>
               </div>
            )}
         </div>
      </div>
   );
}

export default SingleObjectivePage;