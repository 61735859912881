import React, { useState } from 'react';


const TriangulationToggle = ({view}) => {

    if (!view) {
        return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M11.6667 29.1668C9.12028 29.1668 6.95597 28.276 5.17375 26.4943C3.39125 24.7127 2.5 22.5492 2.5 20.0039C2.5 17.4586 3.39125 15.2939 5.17375 13.5097C6.95597 11.7256 9.12028 10.8335 11.6667 10.8335H28.3333C30.8797 10.8335 33.044 11.7243 34.8262 13.506C36.6087 15.2877 37.5 17.4511 37.5 19.9964C37.5 22.5417 36.6087 24.7064 34.8262 26.4906C33.044 28.2747 30.8797 29.1668 28.3333 29.1668H11.6667Z" fill="white"/>
        <path d="M11.6667 29.1668C9.12028 29.1668 6.95597 28.276 5.17375 26.4943C3.39125 24.7127 2.5 22.5492 2.5 20.0039C2.5 17.4586 3.39125 15.2939 5.17375 13.5097C6.95597 11.7256 9.12028 10.8335 11.6667 10.8335H28.3333C30.8797 10.8335 33.044 11.7243 34.8262 13.506C36.6087 15.2877 37.5 17.4511 37.5 19.9964C37.5 22.5417 36.6087 24.7064 34.8262 26.4906C33.044 28.2747 30.8797 29.1668 28.3333 29.1668H11.6667ZM11.6667 26.6668H28.3333C30.1667 26.6668 31.7361 26.0141 33.0417 24.7085C34.3472 23.4029 35 21.8335 35 20.0002C35 18.1668 34.3472 16.5974 33.0417 15.2918C31.7361 13.9863 30.1667 13.3335 28.3333 13.3335H11.6667C9.83333 13.3335 8.26389 13.9863 6.95833 15.2918C5.65278 16.5974 5 18.1668 5 20.0002C5 21.8335 5.65278 23.4029 6.95833 24.7085C8.26389 26.0141 9.83333 26.6668 11.6667 26.6668Z" fill="#C5D1E4"/>
        <circle cx="28.3335" cy="20" r="5" fill="#001331"/>
        </svg> )
    } else {
        return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path d="M11.6667 29.1668C9.12028 29.1668 6.95597 28.276 5.17375 26.4943C3.39125 24.7127 2.5 22.5492 2.5 20.0039C2.5 17.4586 3.39125 15.2939 5.17375 13.5097C6.95597 11.7256 9.12028 10.8335 11.6667 10.8335H28.3333C30.8797 10.8335 33.044 11.7243 34.8262 13.506C36.6087 15.2877 37.5 17.4511 37.5 19.9964C37.5 22.5417 36.6087 24.7064 34.8262 26.4906C33.044 28.2747 30.8797 29.1668 28.3333 29.1668H11.6667Z" fill="white"/>
        <path d="M11.6667 29.1668C9.12028 29.1668 6.95597 28.276 5.17375 26.4943C3.39125 24.7127 2.5 22.5492 2.5 20.0039C2.5 17.4586 3.39125 15.2939 5.17375 13.5097C6.95597 11.7256 9.12028 10.8335 11.6667 10.8335H28.3333C30.8797 10.8335 33.044 11.7243 34.8262 13.506C36.6087 15.2877 37.5 17.4511 37.5 19.9964C37.5 22.5417 36.6087 24.7064 34.8262 26.4906C33.044 28.2747 30.8797 29.1668 28.3333 29.1668H11.6667ZM11.6667 26.6668H28.3333C30.1667 26.6668 31.7361 26.0141 33.0417 24.7085C34.3472 23.4029 35 21.8335 35 20.0002C35 18.1668 34.3472 16.5974 33.0417 15.2918C31.7361 13.9863 30.1667 13.3335 28.3333 13.3335H11.6667C9.83333 13.3335 8.26389 13.9863 6.95833 15.2918C5.65278 16.5974 5 18.1668 5 20.0002C5 21.8335 5.65278 23.4029 6.95833 24.7085C8.26389 26.0141 9.83333 26.6668 11.6667 26.6668Z" fill="#C5D1E4"/>
        <circle cx="11.6666" cy="20" r="5" fill="#001331"/>
        </svg> )
    }
}


export default TriangulationToggle;