import API from "../services/api";

export const fetchIntegrationStatus = async () => {
  try {
    const response = await API.get(
      "/integrations_general/get-integrations-context-data",
    );
    const hasAnyIntegration = Object.values(response.data.integrated_services).some(
      (status) => status,
    );
    return {
      hasIntegrations: hasAnyIntegration,
      integrationStatus: response.data.integrated_services,
      integrationsContextData: response.data,
    };
  } catch (error) {
    console.error("Error fetching integration status:", error);
    throw error;
  }
};

export const isJiraIntegrated = async () => {
  try {
    const { integrationStatus } = await fetchIntegrationStatus();
    return !!integrationStatus.jira;
  } catch (error) {
    console.error("Error checking Jira integration status:", error);
    return false;
  }
};

export const activateSteamIntegration = async () => {
  try {
    const response = await API.post("/steam/activate-steam-integration");
    return response.data;
  } catch (error) {
    console.error("Error activating Steam integration:", error);
    throw error;
  }
};
