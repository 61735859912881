import React, { createContext, useState, useContext, useEffect } from 'react';
import { fetchIntegrationStatus, activateSteamIntegration } from '../utils/IntegrationUtils';

const IntegrationsContext = createContext();

export const IntegrationsProvider = ({ children }) => {
  const [integrations, setIntegrations] = useState({});
  const [numLocalFilesUploaded, setNumLocalFilesUploaded] = useState(null);

  const loadIntegrationStatus = async () => {
    try {
      const { integrationStatus, integrationsContextData } = await fetchIntegrationStatus();
      setIntegrations(integrationStatus);
      setNumLocalFilesUploaded(integrationsContextData.num_local_files_uploaded);
    } catch (error) {
      console.error("Error loading integration status:", error);
    }
  };

  const activateSteam = async () => {
    try {
      await activateSteamIntegration();
      await loadIntegrationStatus();
    } catch (error) {
      console.error("Error activating Steam integration:", error);
    }
  };

  useEffect(() => {
    loadIntegrationStatus();
  }, []);

  return (
    <IntegrationsContext.Provider
      value={{
        integrations,
        setIntegrations,
        numLocalFilesUploaded,
        refreshIntegrations: loadIntegrationStatus,
        activateSteam,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

export const useIntegrations = () => useContext(IntegrationsContext);
