import React, { useEffect, useState } from "react";

const DocPortionOfArtifactViewerPage = ({
  file_content,
  mentions_with_colors,
  colorCodeByInsightTypeOrInsight,
  idOfInsightInFocus,
  setIdOfInsightInFocus,
  getInsightInstanceColor,
  mentionRef,
  initialMentionId,
  selectedMentionId,
  setSelectedMentionId,
}) => {
  const [clickedMentionId, setClickedMentionId] = useState(null);

  useEffect(() => {
    if (mentionRef.current && initialMentionId) {
      mentionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [initialMentionId]);

  const getMentionsAtCharIndex = (charIndex, mentions) => {
    return mentions
      .filter(
        (mention) =>
          charIndex >= mention.span_indices[0] &&
          charIndex < mention.span_indices[1],
      )
      .map((mention) => mention.mentionId);
  };

  const getColorAtCharIndex = (charIndex, mentions) => {
    const mentionsAtIndex = getMentionsAtCharIndex(charIndex, mentions);

    if (mentionsAtIndex.length === 0) {
      return "transparent";
    }

    if (mentionsAtIndex.includes(selectedMentionId)) {
      const selectedMention = mentions.find(
        (mention) => mention.mentionId === selectedMentionId,
      );
      return darkenColor(
        colorCodeByInsightTypeOrInsight === "type"
          ? selectedMention.insightTypeColor
          : getInsightInstanceColor(selectedMention.insightId),
        0.2,
      );
    }

    const containedMentions = mentionsAtIndex.filter((mentionId) =>
      mentionsAtIndex.some(
        (otherId) =>
          mentionId !== otherId &&
          mentions.find((mention) => mention.mentionId === mentionId)
            .span_indices[0] >=
            mentions.find((mention) => mention.mentionId === otherId)
              .span_indices[0] &&
          mentions.find((mention) => mention.mentionId === mentionId)
            .span_indices[1] <=
            mentions.find((mention) => mention.mentionId === otherId)
              .span_indices[1],
      ),
    );

    if (containedMentions.length > 0) {
      const containedMention = mentions.find(
        (mention) => mention.mentionId === containedMentions[0],
      );
      return colorCodeByInsightTypeOrInsight === "type"
        ? containedMention.insightTypeColor
        : getInsightInstanceColor(containedMention.insightId);
    }

    const earliestMention = mentions.find(
      (mention) =>
        mention.mentionId ===
        mentionsAtIndex.sort(
          (a, b) =>
            mentions.find((mention) => mention.mentionId === a)
              .span_indices[0] -
            mentions.find((mention) => mention.mentionId === b).span_indices[0],
        )[0],
    );

    return colorCodeByInsightTypeOrInsight === "type"
      ? earliestMention.insightTypeColor
      : getInsightInstanceColor(earliestMention.insightId);
  };

  const renderHighlightedText = () => {
    console.log('file_content:', file_content);
    const colorsAtEachIndex = file_content
      .split("")
      .map((_, i) => getColorAtCharIndex(i, mentions_with_colors));

    return file_content.split("").map((char, i) => (
      <span
        key={i}
        ref={
          getMentionsAtCharIndex(i, mentions_with_colors).includes(
            initialMentionId,
          )
            ? mentionRef
            : null
        }
        style={{ backgroundColor: colorsAtEachIndex[i], cursor: "pointer" }}
        onClick={() => {
          const mentionsAtIndex = getMentionsAtCharIndex(
            i,
            mentions_with_colors,
          );
          if (mentionsAtIndex.length > 0) {
            const mentionToSelect = mentions_with_colors.find(
              (mention) => mention.mentionId === mentionsAtIndex[0],
            );
            handleMentionClick(mentionToSelect);
          }
        }}
      >
        {char}
      </span>
    ));
  };

  // Helper function to darken a color
  const darkenColor = (color, amount) => {
    return (
      "#" +
      color
        .replace(/^#/, "")
        .replace(/../g, (color) =>
          (
            "0" +
            Math.min(
              255,
              Math.max(0, parseInt(color, 16) - amount * 255),
            ).toString(16)
          ).substr(-2),
        )
    );
  };

  const handleMentionClick = (mention) => {
    setSelectedMentionId(mention.mentionId);
    setIdOfInsightInFocus(mention.insightId);
    setClickedMentionId(mention.mentionId);
  };

  return (
    <div className="pdf-viewer narrow-viewer">
      <div className="pdf-container">
        <div className="doc-page">{renderHighlightedText()}</div>
      </div>
      <style jsx>{`
        .pdf-viewer {
          display: flex;
          flex-direction: column;
          height: 100%;
          background-color: #e0e0e0;
          padding: 20px;
        }
        .pdf-container {
          flex-grow: 1;
          overflow: auto;
          background-color: #808080;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 20px;
        }
        .doc-page {
          background-color: white;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          padding: 40px;
          margin: 0 auto;
          width: 8.5in;
          min-height: 11in;
          white-space: pre-wrap;
          font-family: "Times New Roman", Times, serif;
          font-size: 12pt;
          line-height: 1.5;
        }
      `}</style>
    </div>
  );
};

export default DocPortionOfArtifactViewerPage;
