import React, { useState, useEffect } from "react";
import TriangulatedPainPointExplorerView from "./TriangulatedPainPointExplorerView";
import TriangulatedPainPointTriangulationView from "./TriangulatedPainPointTriangulationView";
import TriangulationToggle from "../../../assets/icons/TriangulationToggle";
import { ReactComponent as DropdownArrowIcon } from "../../../assets/icons/DropdownArrowIcon.svg";
import { useNavigate, useParams, useLocation, Outlet } from "react-router-dom";
import { ReactComponent as BlueDonutIcon } from "../../../assets/icons/BlueDonutIcon.svg";
import API from "../../../services/api";
import LoadingSpinner from "../../../components/reusable_components/LoadingSpinner/LoadingSpinner";
import '../ReusableComponents/styles.css';

const RenderTriangulatedPainPointPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [view, setView] = useState(true);
  const [triangulatedPPData, setTriangulatedPPData] = useState(null);
  const [numSources, setNumSources] = useState(0);
  const [documentsByType, setDocumentsByType] = useState({});
  const [chartData, setChartData] = useState({});
  const [totalSources, setTotalSources] = useState(0);
  const [scaleMax, setScaleMax] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [severityColors, setSeverityColors] = useState(null);
  const [triangulatedFeatureRequests, setTriangulatedFeatureRequests] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await API.get(`insights/triangulated-pain-point/${id}`);
        console.log("tpp RESPONSE.DATA", response.data);
        setTriangulatedPPData(response.data);
        processTriangulatedData(response.data);

        if (response.data?.triangulated_pain_point?.constituent_objects_by_type?.AggregateFeatureRequestInsight) {
          const aggregateFeatureRequests = response.data.triangulated_pain_point.constituent_objects_by_type
            .AggregateFeatureRequestInsight.objects_of_this_type;
          
          const featureRequestIds = aggregateFeatureRequests.map(fr => fr.id);
          
          const featureResponse = await API.post('/insights/get-triangulated-feature-requests-by-aggregate-ids', {
            aggregate_feature_request_ids: featureRequestIds
          });
          
          setTriangulatedFeatureRequests(featureResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (triangulatedPPData && triangulatedPPData.triangulated_pain_point) {
      const severity = triangulatedPPData.triangulated_pain_point.constituent_objects_by_type
        .AggregatePainPointInsight.objects_of_this_type[0].severity_rating;
      
      setSeverityColors(getSeverityColors(severity));
    }
  }, [triangulatedPPData]);

  const processTriangulatedData = (data) => {
    // Initialize variables
    const uniqueSourceIds = new Set();

    // Initialize an object to store documents by type
    const documentsByType = {};

    // Mapping from artifact_type to desired key
    const documentTypeMapping = {
      "Customer Discovery Interview": "customerDiscoveryInterview",
      "Review": "review",
      "Business Objectives or Strategy Document":
        "businessObjectivesOrStrategyDocument",
      "Market Research Report": "marketResearchReport",
      "Internal Meeting": "internalMeeting",
    };

    // Set to keep track of processed document IDs to avoid duplicates
    const processedDocIds = new Set();

    // Function to process source documents
    function processSourceDocuments(sourceDocuments) {
      Object.entries(sourceDocuments).forEach(([docId, doc]) => {
        if (processedDocIds.has(docId)) return; // Skip if already processed
        processedDocIds.add(docId);

        uniqueSourceIds.add(docId); // Add to unique source IDs

        const docType = doc.artifact_type;
        const key = documentTypeMapping[docType];

        if (key) {
          if (!documentsByType[key]) {
            documentsByType[key] = [];
          }
          documentsByType[key].push(doc);
        }
      });
    }

    const aggregateFeatureRequestInsights =
      data.triangulated_pain_point.constituent_objects_by_type
        ?.AggregateFeatureRequestInsight?.objects_of_this_type || [];

    aggregateFeatureRequestInsights.forEach((aggregateInsight) => {
      // Process source IDs
      aggregateInsight.source_artifact_ids.forEach((id) =>
        uniqueSourceIds.add(id)
      );

      // Process source documents
      processSourceDocuments(aggregateInsight.source_artifacts);
    });

    // Process AggregatePainPointInsight (if needed)
    const aggregatePainPointInsights =
      data.triangulated_pain_point.constituent_objects_by_type
        ?.AggregatePainPointInsight?.objects_of_this_type || [];

    aggregatePainPointInsights.forEach((aggregateInsight) => {
      // Process source IDs
      aggregateInsight.source_artifact_ids.forEach((id) =>
        uniqueSourceIds.add(id)
      );

      // Process source documents
      processSourceDocuments(aggregateInsight.source_artifacts);
    });

    const numSources = uniqueSourceIds.size;
    setNumSources(numSources);

    // New code for calculating chart data
    const mappings = {
      "User Interviews": "customerDiscoveryInterview",
      "App Review": "review",
      "Market Trend News": "marketResearchReport",
      "Strategy Documents": "businessObjectivesOrStrategyDocument",
    };

    const chartData = {
      userInterviews: 0,
      appReview: 0,
      marketTrendNews: 0,
      strategyDocuments: 0,
    };

    Object.entries(mappings).forEach(([key, value]) => {
      const sourceData = documentsByType[value];
      if (key === "User Interviews") {
        chartData.userInterviews = sourceData?.length || 0;
      } else if (key === "App Review") {
        chartData.appReview = sourceData?.length || 0;
      } else if (key === "Market Trend News") {
        chartData.marketTrendNews = sourceData?.length || 0;
      } else if (key === "Strategy Documents") {
        chartData.strategyDocuments = sourceData?.length || 0;
      }
    });

    const totalSources =
      chartData.userInterviews +
      chartData.appReview +
      chartData.marketTrendNews +
      chartData.strategyDocuments;
    const maxValue = Math.max(
      chartData.userInterviews,
      chartData.appReview,
      chartData.marketTrendNews,
      chartData.strategyDocuments
    );

    const getScaleMax = (value) => {
      if (value <= 5) return 5;
      if (value <= 10) return 10;
      return Math.ceil(value / 5) * 5;
    };

    const scaleMax = getScaleMax(maxValue);

    setDocumentsByType(documentsByType);
    setChartData(chartData);
    setTotalSources(totalSources);
    setScaleMax(scaleMax);
  };

  const getSeverityColors = (severity) => {
    if (!severity) return null;
    
    switch (severity.toLowerCase()) {
      case 'high':
        return { severityBgColor: 'var(--blue-darkest, #001331)', severityTextColor: '#FFFFFF' };
      case 'medium':
        return { severityBgColor: 'var(--blue-dark, #104499)', severityTextColor: '#FFFFFF' };
      case 'low':
        return { severityBgColor: 'var(--blue-medium, #628FDA)', severityTextColor: '#FFFFFF' };
      default:
        return { severityBgColor: 'var(--blue-medium, #628FDA)', severityTextColor: '#FFFFFF' };
    }
  };

  const onClickView = () => {
    setView(!view);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <div>
        <div
          id="back-to-insights-page-button-and-find-on-page-search-box-and-export-insights-button"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "6px",
            cursor: "pointer",
            marginBottom: "22px",
          }}
          onClick={() => navigate("/insights")}
        >
          <DropdownArrowIcon
            style={{ width: "24px", height: "24px", transform: "rotate(90deg)" }}
          />
          <span
            style={{
              color: "var(--blue-dark, #104499)",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "130%",
              flex: "1 0 0",
            }}
          >
            Insights
          </span>
        </div>
        <div
          style={{
            display: "flex",
            padding: "6px 24px",
            marginTop: "6px",
            alignItems: "center",
            gap: "10px",
            height: "50px",
            borderRadius: "16px",
            border: "1px solid var(--blue-lighter2, #E5EFFF)",
            background: "var(--blue-lighter2, #E5EFFF)",
          }}
        >
          <BlueDonutIcon />
          <h3
            style={{
              color: "var(--blue-darkest, #001331)",
              fontFamily: '"Roboto Mono"',
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            {totalSources}
          </h3>
          <span
            style={{
              color: "var(--blue-darkest, #001331)",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              marginTop: "4px",
              flex: "1 0 0",
            }}
          >
            data sources mention this pain point. View triangulated details
            here:
          </span>
          <span
            style={{
              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: view ? "500" : "400",
              lineHeight: "145%",
              marginRight: "10px",
            }}
          >
            Explore
          </span>
          <div id='triangulationToggle' onClick={onClickView}>
            <TriangulationToggle view={view} />
          </div>
          <span
            style={{
              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: view ? "400" : "500",
              lineHeight: "145%",
              marginLeft: "10px",
            }}
          >
            Triangulate
          </span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "75%",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            marginTop: "24px",
          }}
        >
          <span
            style={{
              color: "var(--slate-dark, #727F95)",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
              letterSpacing: "0.32px",
              textTransform: "uppercase",
            }}
          >
            PAIN POINT
          </span>
          <span
            style={{
              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
              fontFamily: '"Roboto Mono"',
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
            }}
          >
            {triangulatedPPData.triangulated_pain_point.title}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            flex: "1 0 0",
          }}
        >
          <span
            style={{
              color: "var(--blue-darkest, var(--blue-darkest, #001331))",
              fontFamily: "Roboto Mono",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "135%",
              letterSpacing: "0.32px",
              textTransform: "uppercase",
            }}
          >
            SEVERITY SCORE:
          </span>
          <div style={{ 
            display: "flex", 
            padding: "4px 10px", 
            justifyContent: "center", 
            alignItems: "center", 
            gap: "10px", 
            backgroundColor: severityColors?.severityBgColor, 
            color: severityColors?.severityTextColor,
            borderRadius: "14px",
          }}>
            {triangulatedPPData.triangulated_pain_point.constituent_objects_by_type
        .AggregatePainPointInsight.objects_of_this_type[0].severity_rating.toUpperCase()}
          </div>
        </div>
      </div>
      <div>
        {view ? (
          <TriangulatedPainPointExplorerView
            data={triangulatedPPData}
            triangulatedFeatureRequests={triangulatedFeatureRequests}
          />
        ) : (
          <TriangulatedPainPointTriangulationView
          triangulatedPPData={triangulatedPPData}
            documentsByType={documentsByType}
            numSources={numSources}
            chartData={chartData}
            totalSources={totalSources}
            scaleMax={scaleMax}
          />
        )}
      </div>
      <Outlet />
    </div>
  );
};

export default RenderTriangulatedPainPointPage;
