import React from "react";
import { DataGrid, GridActionsCellItem, gridClasses } from "@mui/x-data-grid";
import { Tooltip, IconButton, styled, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import artifactTypes from "../../../ConstantVariables.json";

// Custom styled tooltip
const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
}));

const ClassifyFilesTable = ({
  files,
  selectedArtifactTypes,
  handleArtifactTypeChange,
  handleOptionsClick,
  selectedRows,
  setSelectedRows,
  rowCount,
  paginationMode,
  paginationModel,
  onPaginationModelChange
}) => {
  const navigate = useNavigate();

  // Add function to determine artifact type options based on file structure
  const getArtifactTypeOptionsForRow = (structureAndContentType) => {
    switch (structureAndContentType) {
      case 'numerical_csv':
        return artifactTypes.numerical_artifact_types;
      case 'unstructured':
      case 'textual_csv':
        return artifactTypes.textual_artifact_types;
      default:
        console.warn(`Unknown structure type: ${structureAndContentType}`);
        return {};
    }
  };

  // Add console log to see files data
  console.log('ClassifyFilesTable files:', files);

  // Update tooltip content to handle nested structure
  const artifactTypeTooltipContent = (
    <div>
      <div>
        <Typography variant="subtitle1" color="primary" gutterBottom fontWeight="bold">
          Textual Artifact Types:
        </Typography>
        {Object.entries(artifactTypes.textual_artifact_types).map(([type, { description }]) => (
          <div key={type} style={{ marginBottom: "8px" }}>
            <strong style={{ color: "#1976d2" }}>{type}:</strong>
            <p style={{ margin: "4px 0 0 0" }}>{description}</p>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "16px" }}>
        <Typography variant="subtitle1" color="primary" gutterBottom fontWeight="bold">
          Numerical Artifact Types:
        </Typography>
        {Object.entries(artifactTypes.numerical_artifact_types).map(([type, { description }]) => (
          <div key={type} style={{ marginBottom: "8px" }}>
            <strong style={{ color: "#1976d2" }}>{type}:</strong>
            <p style={{ margin: "4px 0 0 0" }}>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );

  const preventSelectionOnClick = (event) => {
    event.stopPropagation();
  };

  const columns = [
    // {
    //   field: "actions",
    //   headerName: "",
    //   width: 30,
    //   renderCell: (params) => (
    //     <GridActionsCellItem
    //       icon={<MoreVertIcon />}
    //       onClick={(event) => handleOptionsClick(event, params.row.id)}
    //       label="Options"
    //     />
    //   ),
    // },
    {
      field: "original_file_name",
      headerName: "File Name",
      width: 350,
      renderCell: (params) => (
        params.row.file_extension?.toLowerCase() !== 'csv' ? (
          <div
            className="cursor-pointer text-blue-600 hover:underline"
            onClick={() => navigate(`/doc-viewer/${params.row.id}`)}
          >
            {params.value}
          </div>
        ) : (
          <div>{params.value}</div>
        )
      ),
    },
    {
      field: "artifact_type",
      headerName: "Artifact Type",
      width: 350,
      renderHeader: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.colDef.headerName}
          <StyledTooltip
            title={artifactTypeTooltipContent}
            arrow
            placement="right-start"
          >
            <IconButton size="small" style={{ marginLeft: "4px" }}>
              <InfoIcon fontSize="small" color="action" />
            </IconButton>
          </StyledTooltip>
        </div>
      ),
      renderCell: (params) => (
        <div
          onClick={preventSelectionOnClick}
          className="w-full h-full flex items-center"
        >
          {params.row.done_creating_all_relevant_objects_and_files ? (
            <select
              className={`w-full ${selectedArtifactTypes[params.row.id] === "" ? "bg-red-200" : ""}`}
              value={selectedArtifactTypes[params.row.id] || ""}
              onChange={(e) =>
                handleArtifactTypeChange(params.row.id, e.target.value)
              }
            >
              <option value="">Select Artifact Type</option>
              {Object.entries(getArtifactTypeOptionsForRow(params.row.structure_and_content_type_of_file))
                .map(([type, { description }]) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
              ))}
            </select>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Processing...
            </Typography>
          )}
        </div>
      ),
      cellClassName: (params) =>
        params.row.done_creating_all_relevant_objects_and_files
          ? selectedArtifactTypes[params.row.id] === ""
            ? "bg-red-200"
            : ""
          : "bg-gray-200 cursor-not-allowed",
    },
    {
      field: "done_creating_all_relevant_objects_and_files",
      headerName: "Status",
      width: 120,
      renderCell: (params) => (
        <Typography
          variant="body2"
          color={params.value ? "textPrimary" : "textSecondary"}
        >
          {params.value ? "Ready" : "Processing"}
        </Typography>
      ),
    },
  ];

  const totalColumnWidth = columns.reduce(
    (total, column) => total + (column.width || 0),
    0,
  );
  const minGridWidth = totalColumnWidth + 100;

  return (
    <div
      className="pt-5 pb-5"
      style={{ height: 400, width: "100%", minWidth: `${minGridWidth}px` }}
    >
      <DataGrid
        data-testid="classify-files-table"
        rows={files}
        columns={columns}
        checkboxSelection
        onRowSelectionModelChange={(newSelection) => {
          const filteredSelection = newSelection.filter(
            (id) => files.find((file) => file.id === id)?.done_creating_all_relevant_objects_and_files,
          );
          setSelectedRows(filteredSelection);
        }}
        selectionModel={selectedRows}
        disableRowSelectionOnClick
        isRowSelectable={(params) => params.row.done_creating_all_relevant_objects_and_files}
        paginationMode={paginationMode}
        rowCount={rowCount}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        pageSizeOptions={[25, 50, 100]}
        sx={{
          [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]:
            {
              outline: "none",
            },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "transparent",
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& .MuiDataGrid-row:not(.Mui-selected):hover": {
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.04)"
                : "rgba(255, 255, 255, 0.08)",
          },
          "& .MuiDataGrid-row.processing": {
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.04)"
                : "rgba(255, 255, 255, 0.08)",
            pointerEvents: "none",
            opacity: 0.7,
          },
        }}
        getRowClassName={(params) => (params.row.done_creating_all_relevant_objects_and_files ? "" : "processing")}
      />
    </div>
  );
};

export default ClassifyFilesTable;
