import React, { createContext, useCallback, useState, useMemo, useContext } from 'react';
import API from '../services/api';

const InitiativesPageContext = createContext();

const sortInitiativesByRelevance = (initiatives) => {
  return initiatives.sort((a, b) => {
    const relevanceA = a.relevance_scores?.relevance_rating_high_moderate_low || 'low';
    const relevanceB = b.relevance_scores?.relevance_rating_high_moderate_low || 'low';

    const relevanceOrder = { high: 3, moderate: 2, low: 1 };
    return relevanceOrder[relevanceB.toLowerCase()] - relevanceOrder[relevanceA.toLowerCase()];
  });
};

export const InitiativesProvider = ({ children }) => {
  const [initiatives, setInitiatives] = useState([]);
  const [relatedInsights, setRelatedInsights] = useState({});
  const [initiativesPageBlur, setInitiativesPageBlur] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [initiativesExpanded, setInitiativesExpanded] = useState({});
  const [descriptionExpanded, setDescriptionExpanded] = useState({});
  const [metricsExpanded, setMetricsExpanded] = useState({});
  const [relatedMetrics, setRelatedMetrics] = useState([]);
  const [filteredInitiatives, setFilteredInitiatives] = useState([]);
  const [totalInitiatives, setTotalInitiatives] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchMode, setSearchMode] = useState(false);

  const getAllInitiativesForUser = useCallback(async (startIndex = 0, endIndex = 50) => {
    const response = await API.get(`initiatives/get_initiatives_associated_with_user?start=${startIndex}&end=${endIndex}`);
    return response.data;
  }, []);

  const setAllInitiativesForUser = useCallback((initiatives) => {
    setInitiatives(initiatives);
  }, []);

  const getAndSetAllInitiativesForUser = useCallback(async (startIndex = 0, endIndex = 50) => {
    try {
      const response = await API.get(`initiatives/get_initiatives_associated_with_user?start=${startIndex}&end=${endIndex}`);
      const sortedInitiatives = sortInitiativesByRelevance(response.data.initiatives);
      setInitiatives(sortedInitiatives);
      setTotalInitiatives(response.data.total);
      return sortedInitiatives; // Return the initiatives
    } catch (error) {
      console.error('Error fetching initiatives:', error);
      return []; // Return an empty array in case of error
    }
  }, []);

  const getIndividualInitiativeByIdForUser = useCallback((initiativeId) => {
    if (searchMode) {
      return filteredInitiatives.find(initiative => initiative._id === initiativeId);
    } else {
      return initiatives.find(initiative => initiative._id === initiativeId);
    }

  }, [initiatives, filteredInitiatives]);

  const setIndividualInitiativeByIdForUser = useCallback((initiative) => {
    setInitiatives(prevInitiatives => {
      const index = prevInitiatives.findIndex(item => item._id === initiative._id);
      if (index !== -1) {
        const updatedInitiatives = [...prevInitiatives];
        updatedInitiatives[index] = initiative;
        return updatedInitiatives;
      } else {
        return [...prevInitiatives, initiative];
      }
    });
  }, []);

  const getRelatedInsightsForIndividualInitiativeRoute = useCallback(async (initiativeId) => {
    const response = await API.get(`initiatives-page/${initiativeId}/get-related-insights-data-for-initiative`);
    return response.data;
  }, []);

  const getAllRelatedInsightsDataForUser = useCallback(async () => {
    try {
      const response = await API.get('initiatives-page/get-all-related-insights-data-for-user');
      return response.data;
    } catch (error) {
      console.error('Error fetching related insights data:', error);
      return {};
    }
  }, []);

  const setAllRelatedInsightsDataForUser = useCallback((relatedInsightsData) => {
    setRelatedInsights(relatedInsightsData);
  }, []);

  const getAndSetAllRelatedInsightsDataForUser = useCallback(async () => {
    const relatedInsightsData = await getAllRelatedInsightsDataForUser();
    setAllRelatedInsightsDataForUser(relatedInsightsData);
  }, [getAllRelatedInsightsDataForUser, setAllRelatedInsightsDataForUser]);

  const setRelatedInsightsForIndividualInitiative = useCallback((relatedInsights, initiativeId) => {
    setRelatedInsights(prevState => ({
      ...prevState,
      [initiativeId]: relatedInsights
    }));
  }, []);

  const getAndSetRelatedInsightsForIndividualInitiative = useCallback(async (initiativeId) => {
    const data = await getRelatedInsightsForIndividualInitiativeRoute(initiativeId);
    setRelatedInsightsForIndividualInitiative(data, initiativeId);
  }, [getRelatedInsightsForIndividualInitiativeRoute, setRelatedInsightsForIndividualInitiative]);

  const defaultRelatedInsightsForAllInitiatives = useCallback(async () => {
    await getAndSetAllRelatedInsightsDataForUser();
  }, [getAndSetAllRelatedInsightsDataForUser]);

  const matchRelatedInsightsForIndividualInitiative = useCallback((initiativeId) => {
    const relatedInsightsForInitiative = {
      aggregate_pain_point: relatedInsights.aggregate_pain_point
        .filter((insight) => insight.initiative_id === initiativeId)
        .map((insight) => insight.insight),
      aggregate_feature_request: relatedInsights.aggregate_feature_request
        .filter((insight) => insight.initiative_id === initiativeId)
        .map((insight) => insight.insight),
      individual_quantitative_statement: relatedInsights.individual_quantitative_statement
        .filter((insight) => insight.initiative_id === initiativeId)
        .map((insight) => insight.insight),
      objective_kg_node: relatedInsights.objective_kg_node
        .filter((insight) => insight.initiative_id === initiativeId)
        .map((insight) => insight.insight),
    };
    return relatedInsightsForInitiative;
  }, [relatedInsights]);

  const setInitiativesPageBlurredOrNot = useCallback((isBlurred) => {
    setInitiativesPageBlur(isBlurred);
  }, []);

  const getInitiativesPageBlurredOrNot = useCallback(() => {
    return initiativesPageBlur;
  }, [initiativesPageBlur]);

  const setPageLoadingOrNot = useCallback((isLoading) => {
    setPageLoading(isLoading);
  }, []);

  const getPageLoadingOrNot = useCallback(() => {
    return pageLoading;
  }, [pageLoading]);

  const defaultInitiativeExpandedOrNot = useCallback((initiatives) => {
    const expandedState = {};
    initiatives.forEach(initiative => {
      expandedState[initiative._id] = false;
    });
    setInitiativesExpanded(expandedState);
  }, []);

  const getInitiativeExpandedOrNot = useCallback((initiativeId) => {
    return initiativesExpanded[initiativeId] || false;
  }, [initiativesExpanded]);

  const setInitiativeExpandedOrNot = useCallback((initiativeId, expandedOrNot) => {
    setInitiativesExpanded(prevState => ({
      ...prevState,
      [initiativeId]: expandedOrNot
    }));
  }, []);

  const defaultDescriptionExpandedOrNot = useCallback((initiatives) => {
    const expandedState = {};
    initiatives.forEach(initiative => {
      expandedState[initiative._id] = false;
    });
    setDescriptionExpanded(expandedState);
  }, []);

  const getDescriptionExpandedOrNot = useCallback((initiativeId) => {
    return descriptionExpanded[initiativeId] || false;
  }, [descriptionExpanded]);

  const setDescriptionExpandedOrNot = useCallback((initiativeId, expandedOrNot) => {
    setDescriptionExpanded(prevState => ({
      ...prevState,
      [initiativeId]: expandedOrNot
    }));
  }, []);

  const getMetricsExpandedOrNot = useCallback((initiativeId) => {
    return metricsExpanded[initiativeId] || false;
  }, [metricsExpanded]);

  const setMetricsExpandedOrNot = useCallback((initiativeId, expandedOrNot) => {
    setMetricsExpanded(prevState => ({
      ...prevState,
      [initiativeId]: expandedOrNot
    }));
  }, []);

  const getRelatedMetricsForIndividualInitiativeRoute = useCallback(async (initiativeId) => {
    const response = await API.get(`initiatives-page/${initiativeId}/metrics`);
    return response.data;
  }, []);

  const setRelatedMetricsForIndividualInitiativeRoute = useCallback((relatedMetrics, initiativeId) => {
    setRelatedMetrics(prevState => ({
      ...prevState,
      [initiativeId]: relatedMetrics
    }));
  }, []);

  const getAndSetRelatedMetricsForIndividualInitiativeRoute = useCallback(async (initiativeId) => {
    const data = await getRelatedMetricsForIndividualInitiativeRoute(initiativeId);
    setRelatedMetricsForIndividualInitiativeRoute(data, initiativeId);
  }, [getRelatedMetricsForIndividualInitiativeRoute, setRelatedMetricsForIndividualInitiativeRoute]);

  const getAllRelatedMetricsDataForUser = useCallback(async () => {
    const response = await API.get(`initiatives-page/get-all-related-metrics-data-for-user`);
    return response.data;
  }, []);

  const setAllRelatedMetricsDataForUser = useCallback(async (relatedMetricsData) => {
    setRelatedMetrics(relatedMetricsData);
    console.log(relatedMetricsData)
  }, []);

  const getAndSetAllRelatedMetricsDataForUser = useCallback(async () => {
    const data = await getAllRelatedMetricsDataForUser();
    setAllRelatedMetricsDataForUser(data);
  }, [getAllRelatedMetricsDataForUser, setAllRelatedMetricsDataForUser]);

  const defaultRelatedMetricsForAllInitiatives = useCallback(async () => {
      await getAndSetAllRelatedMetricsDataForUser();
  }, [getAndSetAllRelatedMetricsDataForUser]);

  const matchRelatedMetricsForIndividualInitiative = useCallback((initiativeId) => {
    return relatedMetrics.filter(metric => metric.related_initiative_id === initiativeId);
  }, [relatedMetrics]);

  const searchInitiatives = useCallback(async (startIndex, endIndex) => {
    setPageLoading(true);
    const search = searchText;
    try {
      const response = await API.get(`initiatives/search-initiatives?search_text=${search}&start=${startIndex}&end=${endIndex}`);
      const sortedInitiatives = sortInitiativesByRelevance(response.data.initiatives);
      setFilteredInitiatives(sortedInitiatives);
      setTotalInitiatives(response.data.total);
      console.log('Search results:', sortedInitiatives);
    } catch (error) {
      console.error('Error searching initiatives:', error);
      setFilteredInitiatives([]);
      setTotalInitiatives(0);
    }
    setPageLoading(false);
  }, [searchText]);

  const searchPageLoader = useCallback(async (page = 1) => {
    setPageLoading(true);
    const startIndex = (page - 1) * 50;
    const endIndex = startIndex + 50;
    await searchInitiatives(startIndex, endIndex);

    setFilteredInitiatives(currentInitiatives => {
      Promise.all([
        defaultRelatedInsightsForAllInitiatives(),
        defaultInitiativeExpandedOrNot(currentInitiatives),
        defaultDescriptionExpandedOrNot(currentInitiatives),
        defaultRelatedMetricsForAllInitiatives(currentInitiatives)
      ]).then(() => {
        setPageLoading(false);
      });

      return currentInitiatives;
    });
  }, [searchInitiatives, defaultInitiativeExpandedOrNot, defaultDescriptionExpandedOrNot, defaultRelatedInsightsForAllInitiatives, defaultRelatedMetricsForAllInitiatives]);

  const defaultPageLoader = useCallback(async (page = 1) => {
    setPageLoading(true);
    const startIndex = (page - 1) * 50;
    const endIndex = startIndex + 50;
    await getAndSetAllInitiativesForUser(startIndex, endIndex);
    await defaultRelatedInsightsForAllInitiatives();

    setInitiatives(currentInitiatives => {
      Promise.all([
        defaultInitiativeExpandedOrNot(currentInitiatives),
        defaultDescriptionExpandedOrNot(currentInitiatives),
        defaultRelatedMetricsForAllInitiatives(currentInitiatives)
      ]).then(() => {
        setPageLoading(false);
      });

      return currentInitiatives;
    });
  }, [getAndSetAllInitiativesForUser, defaultInitiativeExpandedOrNot, defaultDescriptionExpandedOrNot, defaultRelatedInsightsForAllInitiatives, defaultRelatedMetricsForAllInitiatives]);

  const getCurrentPage = useCallback(() => {
    return currentPage;
  }, [currentPage]);

  const setCurrentPageNumber = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const value = useMemo(() => ({
    initiatives,
    relatedInsights,
    totalInitiatives,
    getAllInitiativesForUser,
    setAllInitiativesForUser,
    getAndSetAllInitiativesForUser,
    getIndividualInitiativeByIdForUser,
    setIndividualInitiativeByIdForUser,
    getRelatedInsightsForIndividualInitiativeRoute,
    setRelatedInsightsForIndividualInitiative,
    getAndSetRelatedInsightsForIndividualInitiative,
    defaultRelatedInsightsForAllInitiatives,
    getAllRelatedInsightsDataForUser,
    setAllRelatedInsightsDataForUser,
    getAndSetAllRelatedInsightsDataForUser,
    initiativesPageBlur,
    setInitiativesPageBlurredOrNot,
    getInitiativesPageBlurredOrNot,
    pageLoading,
    setPageLoadingOrNot,
    getPageLoadingOrNot,
    defaultInitiativeExpandedOrNot,
    getInitiativeExpandedOrNot,
    setInitiativeExpandedOrNot,
    defaultDescriptionExpandedOrNot,
    getDescriptionExpandedOrNot,
    setDescriptionExpandedOrNot,
    getAllRelatedMetricsDataForUser,
    setAllRelatedMetricsDataForUser,
    getAndSetAllRelatedMetricsDataForUser,
    relatedMetrics,
    getRelatedMetricsForIndividualInitiativeRoute,
    setRelatedMetricsForIndividualInitiativeRoute,
    getAndSetRelatedMetricsForIndividualInitiativeRoute,
    defaultRelatedMetricsForAllInitiatives,
    defaultPageLoader,
    matchRelatedInsightsForIndividualInitiative,
    matchRelatedMetricsForIndividualInitiative,
    filteredInitiatives,
    searchText,
    setSearchText,
    searchInitiatives,
    currentPage,
    getCurrentPage,
    setCurrentPageNumber,
    searchPageLoader,
    searchMode,
    setSearchMode
  }), [
    initiatives,
    relatedInsights,
    totalInitiatives,
    initiativesPageBlur,
    pageLoading,
    matchRelatedInsightsForIndividualInitiative,
    getAllInitiativesForUser,
    setAllInitiativesForUser,
    getAndSetAllInitiativesForUser,
    getIndividualInitiativeByIdForUser,
    setIndividualInitiativeByIdForUser,
    getRelatedInsightsForIndividualInitiativeRoute,
    setRelatedInsightsForIndividualInitiative,
    getAndSetRelatedInsightsForIndividualInitiative,
    defaultRelatedInsightsForAllInitiatives,
    getAllRelatedInsightsDataForUser,
    setAllRelatedInsightsDataForUser,
    getAndSetAllRelatedInsightsDataForUser,
    setInitiativesPageBlurredOrNot,
    getInitiativesPageBlurredOrNot,
    setPageLoadingOrNot,
    getPageLoadingOrNot,
    defaultInitiativeExpandedOrNot,
    getInitiativeExpandedOrNot,
    setInitiativeExpandedOrNot,
    defaultDescriptionExpandedOrNot,
    getDescriptionExpandedOrNot,
    setDescriptionExpandedOrNot,
    relatedMetrics,
    getRelatedMetricsForIndividualInitiativeRoute,
    setRelatedMetricsForIndividualInitiativeRoute,
    getAndSetRelatedMetricsForIndividualInitiativeRoute,
    defaultRelatedMetricsForAllInitiatives,
    getAllRelatedMetricsDataForUser,
    setAllRelatedMetricsDataForUser,
    getAndSetAllRelatedMetricsDataForUser,
    defaultPageLoader,
    matchRelatedMetricsForIndividualInitiative,
    filteredInitiatives,
    searchText,
    setSearchText,
    searchInitiatives,
    currentPage,
    getCurrentPage,
    setCurrentPageNumber,
    searchPageLoader,
    searchMode,
    setSearchMode
  ]);

  return (
    <InitiativesPageContext.Provider value={value}>
      {children}
    </InitiativesPageContext.Provider>
  );
};

export const useInitiativesPage = () => useContext(InitiativesPageContext);