import React, { useState, useEffect } from "react";
import RadarChart from "../ReusableComponents/RadarChart";
import BarChart from "../ReusableComponents/BarChart";
import DataSourceCard from "../ReusableComponents/DataSourceCard";
import ExpandCollapseArrow from "../../../assets/icons/InitiativesPageIcons/ExpandCollapseArrow";
import Masonry from 'react-masonry-css';
import DropdownMenu from "../../reusable_components/DropdownMenu";
import ReusableLineChartComponentWithRecharts from "../../reusable_components/reusable_line_chart_component_with_recharts";
import moment from 'moment';

function TriangulatedPainPointTriangulationView({
    triangulatedPPData,
    documentsByType,
    numSources,
    chartData,
    totalSources,
    scaleMax,
}) {
    console.log('triangulatedPPData:', triangulatedPPData);

    // Responsive column count logic
    const [column_count_in_masonry_layout, set_column_count_in_masonry_layout] = useState(3);

    // State variables to manage expansion/collapse
    const [customer_data_is_expanded_in_UI, set_customer_data_is_expanded_in_UI] = useState(true);
    const [market_research_is_expanded_in_UI, set_market_research_is_expanded_in_UI] = useState(true);
    const [business_strategy_is_expanded_in_UI, set_business_strategy_is_expanded_in_UI] = useState(true);

    // State variables for selected custom events
    const [selected_custom_events_in_dropdowns, set_selected_custom_events_in_dropdowns] = useState([null, null, null]);

    useEffect(() => {
        const update_column_count_based_on_window_width = () => {
            if (window.innerWidth < 700) {
                set_column_count_in_masonry_layout(1);
            } else if (window.innerWidth < 1100) {
                set_column_count_in_masonry_layout(2);
            } else {
                set_column_count_in_masonry_layout(3);
            }
        };

        window.addEventListener("resize", update_column_count_based_on_window_width);
        update_column_count_based_on_window_width();

        return () => {
            window.removeEventListener("resize", update_column_count_based_on_window_width);
        };
    }, []);

    // Extract custom event data safely
    const custom_event_list_from_triangulated_pain_point =
        triangulatedPPData?.triangulated_pain_point?.constituent_objects_by_type?.TimeSeriesNumericalDataOfCustomEvent?.objects_of_this_type || [];

    // Safely map event names 
    const custom_event_names_array = custom_event_list_from_triangulated_pain_point.map(
        (event_object) => event_object.custom_event_name
    );

    // Handler for selecting custom events from dropdowns
    const handle_custom_event_selection_in_dropdown = (dropdown_index_number, selected_event_name_string) => {
        set_selected_custom_events_in_dropdowns((previous_selected_events_array) => {
            const new_selected_events_array = [...previous_selected_events_array];
            new_selected_events_array[dropdown_index_number] = selected_event_name_string === "Select event" ? null : selected_event_name_string;
            return new_selected_events_array;
        });
    };

    // Prepare data for the chart based on selected events
    const selected_event_data_objects = selected_custom_events_in_dropdowns
        .map((event_name_string) =>
            custom_event_list_from_triangulated_pain_point.find(
                (event_object) => event_object.custom_event_name === event_name_string
            )
        )
        .filter((event_object) => event_object);

    // Collect all unique timestamps
    const all_unique_timestamps_set = new Set();
    selected_event_data_objects.forEach((event_object) => {
        event_object.timestamp_values.forEach((timestamp) => {
            all_unique_timestamps_set.add(timestamp);
        });
    });
    const all_unique_timestamps_array = Array.from(all_unique_timestamps_set).sort();

    // Build chart data by combining selected events
    const chart_data_objects_array = all_unique_timestamps_array.map((timestamp_value) => {
        const data_point_object = { date: moment(timestamp_value).format('M/D') };
        selected_event_data_objects.forEach((event_object, index_number) => {
            const value_index_number = event_object.timestamp_values.indexOf(timestamp_value);
            data_point_object[`value${index_number}`] = value_index_number !== -1 ? event_object.numerical_values[value_index_number] : null;
        });
        return data_point_object;
    });

    // Line data keys and colors for the chart
    const line_data_keys_array = selected_event_data_objects.map((_, index_number) => `value${index_number}`);
    const line_color_values_array = ['#4CAF50', '#2196F3', '#9C27B0'].slice(0, line_data_keys_array.length);

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "stretch",
                    marginTop: "24px",
                    gap: "24px",
                }}
            >
                <div style={{ display: "flex" }}>
                    <RadarChart
                        chartData={chartData}
                        totalSources={totalSources}
                        scaleMax={scaleMax}
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
                <div style={{ flex: 1, display: "flex" }}>
                    <BarChart
                        chartData={chartData}
                        totalSources={totalSources}
                        scaleMax={scaleMax}
                        style={{ width: "100%", height: "100%" }}
                    />
                </div>
            </div>

            {/* Customer Data Sources Section */}
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                    marginTop: "48px",
                    cursor: "pointer"
                }}
                onClick={() => set_customer_data_is_expanded_in_UI((previous_state_boolean) => !previous_state_boolean)}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        flex: "1 0 0",
                    }}
                >
                    <ExpandCollapseArrow
                        width={24}
                        height={25}
                        fill="#104499"
                        rotation={customer_data_is_expanded_in_UI ? 90 : 0}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                    >
                        <circle cx="6" cy="6.5" r="5.5" fill="#ABE9F5" stroke="#9CC2FF" />
                    </svg>
                    <span
                        style={{
                            color: "#001331",
                            fontFamily: "Roboto Mono",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                        }}
                    >
                        Customer Data Sources
                    </span>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <span
                            style={{
                                color: "#001331",
                                fontFamily: "Roboto Mono",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "135%",
                            }}
                        >
                            {documentsByType.customerDiscoveryInterview
                                ? documentsByType.customerDiscoveryInterview.length
                                : 0}
                        </span>
                        <span
                            style={{
                                color: "#727F95",
                                fontFamily: "Rubik",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "145%",
                            }}
                        >
                            {documentsByType.customerDiscoveryInterview &&
                                documentsByType.customerDiscoveryInterview.length > 0
                                ? `${documentsByType.customerDiscoveryInterview.length === 1
                                    ? "user interview"
                                    : "user interviews"
                                }`
                                : "user interviews"}
                        </span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <span
                            style={{
                                color: "#001331",
                                fontFamily: "Roboto Mono",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "135%",
                            }}
                        >
                            {documentsByType.review ? documentsByType.review.length : 0}
                        </span>
                        <span
                            style={{
                                color: "#727F95",
                                fontFamily: "Rubik",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "145%",
                            }}
                        >
                            {documentsByType.review && documentsByType.review.length > 0
                                ? `${documentsByType.review.length === 1
                                    ? "app review"
                                    : "app reviews"
                                }`
                                : "app reviews"}
                        </span>
                    </div>
                </div>
            </div>
            {customer_data_is_expanded_in_UI && (
                <Masonry
                    breakpointCols={{
                        default: 3,
                        1100: 2,
                        700: 1
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {['customerDiscoveryInterview', 'review'].flatMap((sourceCategory) =>
                        (documentsByType[sourceCategory] || []).map((doc, index) => (
                            <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
                        ))
                    )}
                </Masonry>
            )}

            {/* Market Research Sources Section */}
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                    marginTop: "48px",
                    cursor: "pointer",
                }}
                onClick={() => set_market_research_is_expanded_in_UI((previous_state_boolean) => !previous_state_boolean)}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        flex: "1 0 0",
                    }}
                >
                    <ExpandCollapseArrow
                        width={24}
                        height={25}
                        fill="#104499"
                        rotation={market_research_is_expanded_in_UI ? 90 : 0}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                    >
                        <circle cx="6" cy="6.5" r="6" fill="#9CC2FF" />
                    </svg>
                    <span
                        style={{
                            color: "#001331",
                            fontFamily: "Roboto Mono",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                        }}
                    >
                        Market Research Sources
                    </span>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <span
                        style={{
                            color: "#001331",
                            fontFamily: "Roboto Mono",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                        }}
                    >
                        {documentsByType.marketResearchReport
                            ? documentsByType.marketResearchReport.length
                            : 0}
                    </span>
                    <span
                        style={{
                            color: "#727F95",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "145%",
                        }}
                    >
                        {documentsByType.marketResearchReport &&
                            documentsByType.marketResearchReport.length > 0
                            ? `${documentsByType.marketResearchReport.length === 1
                                ? "market research report"
                                : "market research reports"
                            }`
                            : "market research reports"}
                    </span>
                </div>
            </div>
            {market_research_is_expanded_in_UI && (
                <Masonry
                    breakpointCols={{
                        default: 3,
                        1100: 2,
                        700: 1
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {['marketResearchReport'].flatMap((sourceCategory) =>
                        (documentsByType[sourceCategory] || []).map((doc, index) => (
                            <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
                        ))
                    )}
                </Masonry>
            )}

            {/* Business Strategy Sources Section */}
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                    marginTop: "48px",
                    cursor: "pointer",
                }}
                onClick={() => set_business_strategy_is_expanded_in_UI((previous_state_boolean) => !previous_state_boolean)}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        flex: "1 0 0",
                    }}
                >
                    <ExpandCollapseArrow
                        width={24}
                        height={25}
                        fill="#104499"
                        rotation={business_strategy_is_expanded_in_UI ? 90 : 0}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                    >
                        <circle cx="6" cy="6.5" r="6" fill="#9392F2" />
                    </svg>
                    <span
                        style={{
                            color: "#001331",
                            fontFamily: "Roboto Mono",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                        }}
                    >
                        Business Strategy Sources
                    </span>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <span
                        style={{
                            color: "#001331",
                            fontFamily: "Roboto Mono",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "135%",
                        }}
                    >
                        {documentsByType.businessObjectivesOrStrategyDocument
                            ? documentsByType.businessObjectivesOrStrategyDocument.length
                            : 0}
                    </span>
                    <span
                        style={{
                            color: "#727F95",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "145%",
                        }}
                    >
                        {documentsByType.businessObjectivesOrStrategyDocument &&
                            documentsByType.businessObjectivesOrStrategyDocument.length > 0
                            ? `${documentsByType.businessObjectivesOrStrategyDocument
                                .length === 1
                                ? "strategy document"
                                : "strategy documents"
                            }`
                            : "strategy documents"}
                    </span>
                </div>
            </div>
            {business_strategy_is_expanded_in_UI && (
                <Masonry
                    breakpointCols={{
                        default: 3,
                        1100: 2,
                        700: 1
                    }}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {['businessObjectivesOrStrategyDocument'].flatMap((sourceCategory) =>
                        (documentsByType[sourceCategory] || []).map((doc, index) => (
                            <DataSourceCard key={`${sourceCategory}-${index}`} data={doc} sourceCategory={sourceCategory} />
                        ))
                    )}
                </Masonry>
            )}

            {/* Relevant Analytics Section */}
            <div
                id="relevant-analytics-section"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "36px",
                    alignSelf: "stretch",
                    marginBottom: "100px"
                }}
            >
                <div
                    id="title-and-analytics-insights-cards"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "24px"
                    }}
                >
                    <div
                        id="relevant-analytics-title-and-timeframe"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "8px"
                        }}
                    >
                        <span
                            style={{
                                color: "var(--blue-darkest, #001331)",
                                fontFamily: "Roboto Mono",
                                fontSize: "20px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "135%"
                            }}
                        >
                            Relevant Analytics
                        </span>
                        <span
                            style={{
                                color: "var(--slate-dark, #727F95)",
                                fontFamily: "Roboto Mono",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "130%"
                            }}
                        >
                            Last 30 days
                        </span>
                    </div>
                    <div
                        id="relevant-analytics-insights-cards"
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "24px"
                        }}
                    >
                    </div>
                </div>
                <div
                    id="num-relevant-insights-found-and-event-selection-dropdowns-and-graph"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "24px",
                        alignSelf: "stretch"
                    }}
                >
                    <div
                        id="num-relevant-insights-found-and-event-selection-dropdowns"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "16px",
                            alignSelf: "stretch"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "4px"
                            }}
                        >
                            <span
                                style={{
                                    color: "var(--slate-dark, #727F95)",
                                    fontFamily: "Roboto Mono",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "130%"
                                }}
                            >
                                {custom_event_list_from_triangulated_pain_point.length} relevant events found in uploaded reports.
                            </span>
                            <span
                                style={{
                                    color: "var(--blue-darkest, #001331)",
                                    fontFamily: "Roboto Mono",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "130%"
                                }}
                            >
                                Select up to 3 events to compare:
                            </span>
                        </div>
                        <div
                            id="event-selection-dropdowns"
                            style={{
                                display: "flex",
                                alignItems: "flex-start",
                                gap: "24px",
                                alignSelf: "stretch"
                            }}
                        >
                            {[0, 1, 2].map((dropdown_index_number) => (
                                <DropdownMenu
                                    key={`dropdown-${dropdown_index_number}`}
                                    legend={true}
                                    circle_color={['#4CAF50', '#2196F3', '#9C27B0'][dropdown_index_number]}
                                    placeholder_text="Select event"
                                    options={custom_event_names_array}
                                    on_select={(selected_event_name_string) =>
                                        handle_custom_event_selection_in_dropdown(dropdown_index_number, selected_event_name_string)
                                    }
                                    styles={{
                                        outer_div: {
                                            display: "flex",
                                            height: "48px",
                                            alignItems: "flex-start",
                                            flex: "1 0 0"
                                        }
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    <div
                        id="line-chart"
                        style={{
                            display: "flex",
                            padding: "24px 0px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "10px",
                            alignSelf: "stretch",
                            borderRadius: "8px",
                            border: "1px solid var(--slate-lighter, #E5EAF3)",
                            background: "var(--white, #FFF)"
                        }}
                    >
                        <ReusableLineChartComponentWithRecharts
                            chart_data_objects_array={chart_data_objects_array}
                            line_data_keys_array={line_data_keys_array}
                            x_axis_data_key_string="date"
                            chart_container_width_in_pixels_number={800}
                            chart_container_height_in_pixels_number={400}
                            line_color_values_array={line_color_values_array}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TriangulatedPainPointTriangulationView;
