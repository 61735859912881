import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  Box,
} from '@mui/material';

function SteamIntegrationGameBox({ game, isSelected, onSelectChange }) {
  const handleCheckboxChange = (event) => {
    onSelectChange(game.id, event.target.checked);
  };

  return (
    <Card 
      sx={{ width: 260, margin: 1, display: 'flex', flexDirection: 'column' }}
      className="steam-integration-game-box"
    >
      <CardMedia
        component="img"
        sx={{
          width: '100%',
          objectFit: 'cover',
          flexGrow: 1,
        }}
        image={game.tiny_image}
        title={game.name}
      />
      <CardContent sx={{ display: 'flex', alignItems: 'center', flexShrink: 0, height: 80 }}>
        <Checkbox
          checked={isSelected}
          onChange={handleCheckboxChange}
          color="primary"
          sx={{ mr: 1, ml: -1 }}
        />
        <Typography variant="body1" align="center" sx={{ flexGrow: 1 }}>
          {game.name}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default SteamIntegrationGameBox;
