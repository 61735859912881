import React, { useState, useRef, useLayoutEffect, useContext } from "react";
import { useInitiativesPage } from "../../../contexts/InitiativesPageContext";
import TeamInformationForm from "./TeamInformationForm";
import IndividualInitiativeMoreOptionsDropdown from "./IndividualInitiativeMoreOptionsDropdown";
import FindInsightsIcon from "../../../assets/icons/FindInsightsIcon";
import PainPointCard from "../../reusable_components/InitiativesPage/InitiativesPagePainPointCard";
import FeatureRequestCard from "../../reusable_components/InitiativesPage/InitiativesPageFeatureRequestCard";
import ObjectiveCard from "../../reusable_components/InitiativesPage/InitiativesPageObjectiveCard";
import ExpandIcon from "../../../assets/icons/ExpandIcon";
import API from "../../../services/api";
import { StatusContext } from "../../../contexts/StatusContext";
import MetricCard from "../../../components/reusable_components/InitiativesPage/MetricCard";
import ExpandCollapseArrow from "../../../assets/icons/InitiativesPageIcons/ExpandCollapseArrow";
import { useNavigate } from "react-router-dom";

const useTruncatedElement = ({ ref }) => {
   const [isTruncated, setIsTruncated] = useState(false);
   const [isShowingMore, setIsShowingMore] = useState(false);

   useLayoutEffect(() => {
      const { offsetHeight, scrollHeight } = ref.current || {};
      if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
         setIsTruncated(true);
      } else {
         setIsTruncated(false);
      }
   }, [ref]);

   const toggleIsShowingMore = () => setIsShowingMore((prev) => !prev);

   return { isTruncated, isShowingMore, toggleIsShowingMore };
};

const NoInsightsToDisplaySection = ({ onFindRelatedInsights }) => (
   <div
      style={{
         display: "flex",
         flexDirection: "column",
         alignItems: "flex-start",
         gap: "8px",
         alignSelf: "stretch"
      }}
   >
      <div
         style={{
            width: "1080px",
            height: "1px",
            background: "var(--slate-light, #C5D1E4)"
         }}
      />
      <div
         style={{
            display: "flex",
            padding: "0px 20px 0px 30px",
            alignItems: "center",
            gap: "8px",
            alignSelf: "stretch"
         }}
      >
         <span
            style={{
               flex: "1 0 0",
               color: "var(--slate-dark, #727F95)",
               fontFamily: '"Roboto Mono"',
               fontSize: "16px",
               fontStyle: "normal",
               fontWeight: 400,
               lineHeight: "135%",
               letterSpacing: "0.32px"
            }}
         >
            No insights to display
         </span>
         <button
            onClick={onFindRelatedInsights}
            style={{
               display: "flex",
               height: "40px",
               padding: "0px 12px",
               justifyContent: "center",
               alignItems: "center",
               gap: "8px",
               borderRadius: "999px"
            }}
         >
            <FindInsightsIcon width={20} height={19.121} />
            <span
               style={{
                  color: "var(--blue-dark, var(--blue-dark, #104499))",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize"
               }}
            >
               Find Related Insights
            </span>
         </button>
      </div>
   </div>
);

const getRelevanceColor = (relevanceRating) => {
   switch (relevanceRating.toLowerCase()) {
      case "high":
         return "bg-[#001331]"; // --blue-darkest
      case "moderate":
         return "bg-[#104499]"; // --blue-dark
      case "low":
         return "bg-[#628FDA]"; // --blue-medium
      default:
         return "bg-slate-900"; // default color
   }
};

const RelevanceAndInsightsCardsAndDetailsButtonSection = ({
   painPointCount,
   featureRequestCount,
   objectiveCount,
   relevanceRating,
   last_time_related_insights_was_ran,
   onShowRelatedInsights
}) => (
   <div
      style={{
         display: "flex",
         flexDirection: "column",
         alignItems: "flex-start",
         gap: "8px",
         alignSelf: "stretch"
      }}
   >
      <div
         id="line over RelevanceAndInsightsCardsAndDetailsButtonSection"
         style={{
            width: "1080px",
            height: "1px",
            background: "var(--slate-light, #C5D1E4)"
         }}
      />
      <div
         style={{
            display: "flex",
            padding: "0px 30px",
            alignItems: "center",
            gap: "20px",
            alignSelf: "stretch",
            width: "100%"
         }}
      >
         <div
            id="relevance and insights cards div"
            style={{
               display: "flex",
               alignItems: "center",
               gap: "20px",
               flex: "1 0 0",
               overflow: "hidden" // Hide overflow
            }}
         >
            {relevanceRating && (
               <div
                  id="relevance div"
                  style={{
                     display: "flex",
                     alignItems: "center",
                     gap: "8px"
                  }}
               >
                  <span
                     style={{
                        color: "var(--blue-darkest, #001331)",
                        fontFamily: '"Roboto Mono"',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "135%",
                        letterSpacing: "0.32px",
                        textTransform: "uppercase"
                     }}
                  >
                     RELEVANCE:
                  </span>
                  <div
                     id="relevance rating pill"
                     className={`flex justify-center items-center gap-2.5 rounded-[14px] px-2.5 py-1 ${getRelevanceColor(relevanceRating)}`}
                  >
                     <span
                        style={{
                           color: "var(--white, #FFF)",
                           fontFamily: '"Roboto Mono"',
                           fontSize: "14px",
                           fontStyle: "normal",
                           fontWeight: 700,
                           lineHeight: "135%",
                           letterSpacing: "0.28px",
                           textTransform: "uppercase"
                        }}
                     >
                        {relevanceRating}
                     </span>
                  </div>
               </div>
            )}
            <div
               id="line separating relevance section and insights cards section"
               style={{
                  width: "1px",
                  height: "28px",
                  background: "var(--slate-light, #C5D1E4)",
                  marginLeft: "16px",
                  marginRight: "16px"
               }}
            />
            <div
               id="insights cards section"
               style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  overflowX: "auto", // Enable horizontal scrolling
                  flexGrow: 1 // Allow this section to grow
               }}
            >
               <PainPointCard count={painPointCount} />
               <FeatureRequestCard count={featureRequestCount} />
               <ObjectiveCard count={objectiveCount} />
            </div>
            <button
               data-test="show-related-insights-details-button"
               style={{
                  display: "flex",
                  height: "40px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  borderRadius: "999px",
                  color: "var(--blue-dark, var(--blue-dark, #104499))",
                  fontFamily: '"Roboto Mono"',
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "135%",
                  letterSpacing: "0.32px",
                  textTransform: "capitalize",
                  flexShrink: 0 // Prevent the button from shrinking
               }}
               onClick={onShowRelatedInsights}
            >
               Details
               <ExpandIcon
                  style={{
                     width: "24px",
                     height: "24px"
                  }}
               />
            </button>
         </div>
      </div>
   </div>
);

const MetricsSection = ({ initiativeId, metrics, isExpanded, onToggleExpand }) => {
  const navigate = useNavigate();

  const handleMetricClick = (initiativeId, metricType) => {
    navigate(`/initiatives/${initiativeId}/metrics/${metricType}`);
  };

  return (
    <div className="metrics-section" style={{
      display: 'flex',
      width: '852px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '12px'
    }}>
      <div id="sectionTitleAndDropdownArrowIcon" 
           style={{
             display: 'flex',
             padding: '0px 30px',
             alignItems: 'flex-start',
             gap: '8px',
             cursor: 'pointer'
           }}
           onClick={onToggleExpand}
      >
        <ExpandCollapseArrow width={24} height={24} fill="#104499" rotation={isExpanded ? 90 : 0} />
        <span style={{
          color: 'var(--blue-darkest, var(--blue-darkest, #001331))',
          fontFamily: '"Roboto Mono"',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '135%',
          letterSpacing: '0.32px',
          textTransform: 'uppercase'
        }}>
          METRICS
        </span>
      </div>
      {isExpanded && (
        <div id="metrics cards" style={{
          display: 'flex',
          padding: '0px 30px',
          alignItems: 'flex-start',
          gap: '20px',
          alignSelf: 'stretch'
        }}>
          {metrics.map((metric) => (
            <MetricCard
              key={metric._id}
              value={metric.value}
              metricType={metric.type}
              initiativeId={initiativeId}
              onClick={handleMetricClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const DefaultInitiativeBox = ({
   initiativeId,
   isDescriptionExpanded,
   onDescriptionExpandClick,
   onShowRelatedInsights
}) => {
   const [showTeamForm, setShowTeamForm] = useState(false);
   const { getIndividualInitiativeByIdForUser, matchRelatedMetricsForIndividualInitiative } = useInitiativesPage();
   const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

   const initiative = getIndividualInitiativeByIdForUser(initiativeId);
   const descriptionRef = useRef(null);
   const { isTruncated, isShowingMore, toggleIsShowingMore } = useTruncatedElement({ ref: descriptionRef });
   const [metricsIsExpanded, setMetricsIsExpanded] = useState(true);

   if (!initiative) {
      return null; // Return null if initiative is not found
   }

   const { title, description, last_time_related_insights_was_ran } = initiative;
   const { impact = "--", complexity = "--" } = initiative.ratings || {};
   const metricData = matchRelatedMetricsForIndividualInitiative(initiativeId);

   const handleEstimateMetrics = (event) => {
      event.stopPropagation();
      setShowTeamForm(true);
   };

   const handleFindRelatedInsightsForInitiative = async () => {
      try {
         const response = await API.get(`initiatives-page/${initiative._id}/find-related-insights-for-initiative`);
         if (response.status === 200) {
            console.log("Related insights search started");
         } else {
            throw new Error("Failed to start related insights search");
         }

         setIsCurrentlyCheckingForUpdatesOnProcesses(true);
      } catch (error) {
         console.error("Error starting related insights or objectives search:", error);
      }
   };

   const toggleMetricsExpansion = () => {
     setMetricsIsExpanded(!metricsIsExpanded);
   };

   return (
      <>
         <article className="flex flex-col justify-center pt-5 pb-2.5 mt-5 w-full bg-white rounded-xl border border-solid border-slate-300 max-w-[1080px] shadow-[0px_2px_8px_rgba(114,127,149,0.25)] max-md:max-w-full">
            <div className="flex flex-wrap gap-5 items-start pr-5 pl-8 w-full max-md:pl-5">
               <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                  <h2 className="text-xl font-medium leading-none text-slate-900 max-md:max-w-full">{title}</h2>
                  {description && (
                     <div className="relative mt-1.5 mb-5">
                        <p
                           ref={descriptionRef}
                           className={`max-md:max-w-full ${
                              !isShowingMore ? "line-clamp-2" : ""
                           }`}
                           style={{
                              color: 'var(--slate-dark, #727F95)',
                              fontFamily: 'Rubik',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              lineHeight: '145%',
                           }}
                        >
                           {description}
                        </p>
                        {isTruncated && (
                           <button
                              onClick={toggleIsShowingMore}
                              className="absolute bottom-0 right-0 font-medium text-blue-400"
                              style={{ marginLeft: "4px", backgroundColor: "#ffffff", paddingLeft: "4px" }}
                           >
                              {description && description.split(" ").length >= 17 ? (
                                 isShowingMore ? (
                                    "show less"
                                 ) : (
                                    <>
                                       <span className="text-slate-500">.. </span>
                                       <span>show more</span>
                                    </>
                                 )
                              ) : null}
                           </button>
                        )}
                     </div>
                  )}
               </div>
               <div className="flex flex-col leading-none uppercase whitespace-nowrap w-[100px]">
                  <div className="text-sm tracking-wide text-slate-900">IMPACT</div>
                  <div className="mt-1 text-base font-medium tracking-wide text-slate-500">{impact}</div>
               </div>
               <div className="flex flex-col leading-none uppercase whitespace-nowrap w-[100px]">
                  <div className="text-sm tracking-wide text-slate-900">COMPLEXITY</div>
                  <div className="mt-1 text-base font-medium tracking-wide text-slate-500">{complexity}</div>
               </div>
               <div className="relative">
                  <IndividualInitiativeMoreOptionsDropdown initiative={initiative} />
               </div>
            </div>
            {(!initiative.metrics || initiative.metrics.length === 0) && !initiative.last_time_related_insights_was_ran && (
               <NoInsightsToDisplaySection onFindRelatedInsights={handleFindRelatedInsightsForInitiative} />
            )}
            {initiative.last_time_related_insights_was_ran && (
               <RelevanceAndInsightsCardsAndDetailsButtonSection
                  painPointCount={initiative.related_insights.aggregate_pain_point.length}
                  featureRequestCount={initiative.related_insights.aggregate_feature_request.length}
                  objectiveCount={initiative.related_insights.objective_kg_node.length}
                  relevanceRating={initiative.relevance_scores.relevance_rating_high_moderate_low}
                  onShowRelatedInsights={onShowRelatedInsights}
                  last_time_related_insights_was_ran={initiative.last_time_related_insights_was_ran}
               />
            )}
            {initiative.metrics && initiative.metrics.length > 0 && (
              <MetricsSection 
                initiativeId={initiativeId} 
                metrics={initiative.metrics} 
                isExpanded={metricsIsExpanded}
                onToggleExpand={toggleMetricsExpansion}
              />
            )}
         </article>
         {showTeamForm && (
            <TeamInformationForm
               initiativeId={initiativeId}
               onClose={() => setShowTeamForm(false)}
               title={initiative.title}
            />
         )}
      </>
   );
};

export default React.memo(DefaultInitiativeBox);