/**
 * DropdownMenu Component
 * 
 * A reusable dropdown menu component in React.
 * 
 * Props:
 * - legend (boolean): Determines whether or not a circle is included in the dropdown. When true, the circle is only shown if an option other than the placeholder is selected. Default is false.
 * - circle_color (string): The color of the circle if shown. Default is "#9392F2".
 * - placeholder_text (string): The placeholder text shown when no option is selected. Default is "Select option".
 * - options (array of strings): The list of options to display in the dropdown.
 * - on_select (function): The function to call when an option is selected.
 * - styles (object): Custom styles to override existing styles.
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import DropdownArrowIcon from '../../assets/icons/DropdownArrowIcon';
import { usePopper } from 'react-popper';

const DropdownMenu = ({
  legend = false,
  circle_color = "#9392F2",
  placeholder_text = "Select option",
  options = [],
  on_select,
  styles = {},
}) => {
  const [dropdown_menu_is_currently_in_the_open_state, set_dropdown_menu_is_currently_in_the_open_state] = useState(false);
  const [option_that_is_currently_selected_by_the_user_in_the_dropdown_menu, set_option_that_is_currently_selected_by_the_user_in_the_dropdown_menu] = useState(null);
  const reference_to_dropdown_outer_div_element_in_the_DOM = useRef(null);
  const reference_element_for_popper_positioning = useRef(null);
  const popper_element_for_dropdown_menu = useRef(null);
  const { styles: popper_styles, attributes } = usePopper(
    reference_element_for_popper_positioning.current,
    popper_element_for_dropdown_menu.current,
    {
      placement: 'bottom-start',
      strategy: 'fixed'
    }
  );

  const toggle_the_open_or_closed_state_of_the_dropdown_menu_when_user_clicks = () => {
    set_dropdown_menu_is_currently_in_the_open_state(!dropdown_menu_is_currently_in_the_open_state);
  };

  const handle_user_click_event_when_option_is_selected_from_dropdown_menu = (option_that_was_clicked_by_user_in_the_dropdown_menu) => {
    set_option_that_is_currently_selected_by_the_user_in_the_dropdown_menu(
      option_that_was_clicked_by_user_in_the_dropdown_menu === placeholder_text ? null : option_that_was_clicked_by_user_in_the_dropdown_menu
    );
    if (on_select) {
      on_select(option_that_was_clicked_by_user_in_the_dropdown_menu);
    }
    set_dropdown_menu_is_currently_in_the_open_state(false);
  };

  const handle_event_where_user_clicks_outside_of_dropdown_menu_to_close_it = (event_that_occurred_when_user_clicked_outside) => {
    if (
      reference_to_dropdown_outer_div_element_in_the_DOM.current &&
      !reference_to_dropdown_outer_div_element_in_the_DOM.current.contains(event_that_occurred_when_user_clicked_outside.target)
    ) {
      set_dropdown_menu_is_currently_in_the_open_state(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handle_event_where_user_clicks_outside_of_dropdown_menu_to_close_it);
    return () => {
      document.removeEventListener('mousedown', handle_event_where_user_clicks_outside_of_dropdown_menu_to_close_it);
    };
  }, []);

  return (
    <div
      id="dropdown-outer-div"
      style={{
        display: 'flex',
        width: '300px',
        height: '48px',
        alignItems: 'flex-start',
        flexShrink: 0,
        ...styles.outer_div,
      }}
      ref={reference_to_dropdown_outer_div_element_in_the_DOM}
    >
      <div
        className="base-form-field"
        style={{
          display: 'flex',
          padding: '9px 20px',
          alignItems: 'center',
          gap: '8px',
          flex: '1 0 0',
          alignSelf: 'stretch',
          borderRadius: '12px',
          border: '1px solid var(--slate-lighter, #E5EAF3)',
          background: 'var(--blue-lighter, #FBFCFC)',
          ...styles.base_form_field,
        }}
        ref={reference_element_for_popper_positioning}
        onClick={toggle_the_open_or_closed_state_of_the_dropdown_menu_when_user_clicks}
      >
        {legend && option_that_is_currently_selected_by_the_user_in_the_dropdown_menu && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            style={{
              width: '10px',
              height: '10px',
              fill: 'var(--dataviz-purple, #9392F2)',
              ...styles.ellipse,
            }}
          >
            <circle cx="5" cy="5" r="5" fill={circle_color} />
          </svg>
        )}
        <span
          style={
            option_that_is_currently_selected_by_the_user_in_the_dropdown_menu
              ? {
                  flex: '1 0 0',
                  color: 'var(--blue-darkest, #001331)',
                  fontFamily: 'Rubik',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '145%', /* 23.2px */
                  ...styles.selected_option_text,
                }
              : {
                  flex: '1 0 0',
                  color: 'var(--slate-dark, #727F95)',
                  fontFamily: 'Roboto Mono',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '130%', /* 20.8px */
                  ...styles.placeholder_text,
                }
          }
        >
          {option_that_is_currently_selected_by_the_user_in_the_dropdown_menu || placeholder_text}
        </span>
        <DropdownArrowIcon
          is_open={dropdown_menu_is_currently_in_the_open_state}
          width="20.017px"
          height="20.017px"
          style={{
            width: '20.017px',
            height: '20.017px',
            ...styles.dropdown_arrow_icon,
          }}
        />
      </div>
      {dropdown_menu_is_currently_in_the_open_state && (
        <div
          ref={popper_element_for_dropdown_menu}
          className="opened-dropdown-menu-container"
          style={{
            display: 'flex',
            padding: '12px 0px',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '12px',
            border: '1px solid var(--slate-lighter, #E5EAF3)',
            background: 'var(--blue-lighter, #FBFCFC)',
            position: 'fixed',
            top: '100%',
            width: '300px',
            zIndex: 1000,
            ...styles.opened_dropdown_menu_container,
            ...popper_styles.popper,
          }}
          {...attributes.popper}
        >
          <div
            onClick={() => handle_user_click_event_when_option_is_selected_from_dropdown_menu(placeholder_text)}
            style={{
              color: 'var(--blue-darkest, #001331)',
              fontFamily: 'Roboto Mono',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '130%',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
              ...styles.option_text,
            }}
          >
            {placeholder_text}
          </div>
          <div
            style={{
              height: '1px',
              background: 'var(--slate-light, #C5D1E4)',
              width: '100%',
              ...styles.separator_line,
            }}
          ></div>
          {options.map((option, index) => (
            <React.Fragment key={option}>
              <div
                onClick={() => handle_user_click_event_when_option_is_selected_from_dropdown_menu(option)}
                style={{
                  color: 'var(--blue-darkest, #001331)',
                  fontFamily: 'Roboto Mono',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '130%', /* 20.8px */
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'center',
                  ...styles.option_text,
                }}
              >
                {option}
              </div>
              {index !== options.length - 1 && (
                <div
                  style={{
                    height: '1px',
                    background: 'var(--slate-light, #C5D1E4)',
                    width: '100%',
                    ...styles.separator_line,
                  }}
                ></div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

DropdownMenu.propTypes = {
  legend: PropTypes.bool,
  circle_color: PropTypes.string,
  placeholder_text: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  on_select: PropTypes.func,
  styles: PropTypes.object,
};

export default DropdownMenu;