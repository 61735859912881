import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler);

const RadarChart = ({ chartData, totalSources, scaleMax }) => {
    // Rotate labels by 45 degrees
    const labels = ['Market Trend News', 'User Interviews', 'App Review', 'Strategy Documents'];
    console.log(chartData, 'CHARTDATA')
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Customer Data',
                data: labels.map(label => 
                    label === 'User Interviews' ? chartData.userInterviews : 
                    label === 'App Review' ? chartData.appReview : 0
                ),
                backgroundColor: '#ABE9F5',
                borderColor: '#9CC2FF',
                borderWidth: 1,
                pointRadius: 0,
            },
            {
                label: 'Market Research',
                data: labels.map(label => label === 'Market Trend News' ? chartData.marketTrendNews : 0),
                backgroundColor: '#9CC2FF',
                borderColor: '#9CC2FF',
                borderWidth: 1,
                pointRadius: 0,
            },
            {
                label: 'Business Strategy',
                data: labels.map(label => label === 'Strategy Documents' ? chartData.strategyDocuments : 0),
                backgroundColor: '#9392F2',
                borderColor: '#9392F2',
                borderWidth: 1,
                pointRadius: 0,
            },
        ],
    };

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: false,
                    color: 'rgba(0, 0, 0, 0.1)',
                },
                suggestedMin: 0,
                suggestedMax: scaleMax,
                ticks: {
                    display: true,
                    stepSize: Math.max(1, Math.floor(scaleMax / 5)),
                    font: {
                        size: 8,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                },
                pointLabels: {
                    display: false,
                    font: {
                        size: 10,
                    },
                    color: 'rgba(0, 0, 0, 0.6)',
                },
                startAngle: 45, // Add this line to rotate the chart 45 degrees
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    return (
        <div style={{
            borderRadius: '12px',
            border: '1px solid var(--slate-lighter, #E5EAF3)',
            background: 'var(--white, #FFF)',
            padding: '24px 36px',
            width: '374px'
        }}>
            <div style={{marginBottom: '8px', display: 'flex', gap: '8px', alignItems: 'center'}}>
                <span style={{color: 'var(--blue-darkest, #001331)',
                    fontFamily: "Roboto Mono",
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '135%'}}>
                    {totalSources}
                </span>
                <span style={{
                    color: 'var(--slate-dark, #727F95)',
                    fontFamily: "Roboto Mono",
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '135%',
                    letterSpacing: '0.32px',
                    textTransform: 'uppercase'
                }}>
                    DATA SOURCES
                </span>
            </div>
            <div style={{ width: '300px', height: '300px' }}>
                <Radar data={data} options={options} />
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '16px'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                        <circle cx="7" cy="7.5" r="6.5" fill="#ABE9F5" stroke="#9CC2FF"/>
                    </svg>
                    <h3 style={{
                        color: 'var(--blue-darkest, #001331)',
                        textAlign: 'center',
                        fontFamily: "Roboto Mono",
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '135%'
                    }}>
                        {chartData.appReview + chartData.userInterviews}
                    </h3>
                    <span style={{
                        color: 'var(--slate-dark, #727F95)',
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '145%'
                    }}>
                        Customer data
                    </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                        <circle cx="7" cy="7.5" r="7" fill="#9CC2FF"/>
                    </svg>
                    <h3 style={{
                        color: 'var(--blue-darkest, #001331)',
                        textAlign: 'center',
                        fontFamily: "Roboto Mono",
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '135%'
                    }}>
                        {chartData.marketTrendNews}
                    </h3>
                    <span style={{
                        color: 'var(--slate-dark, #727F95)',
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '145%'
                    }}>
                        Market research
                    </span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                        <circle cx="7" cy="7.5" r="7" fill="#9392F2"/>
                    </svg>
                    <h3 style={{
                        color: 'var(--blue-darkest, #001331)',
                        textAlign: 'center',
                        fontFamily: "Roboto Mono",
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '135%'
                    }}>
                        {chartData.strategyDocuments}
                    </h3>
                    <span style={{
                        color: 'var(--slate-dark, #727F95)',
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '145%'
                    }}>
                        Business strategy
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RadarChart;
