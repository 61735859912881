import React from 'react';
import PropTypes from 'prop-types';

const DropdownArrowIcon = ({ 
  is_open = true, 
  width = '20.017px', 
  height = '20.017px', 
  fill = '#104499',
  ...props 
}) => {
  const rotation_style = {
    transform: is_open ? 'rotate(0deg)' : 'rotate(-90deg)',
    transition: 'transform 0.2s ease-in-out',
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      style={rotation_style}
      {...props}
    >
      <path
        d="M10.5746 11.0847L14.6461 7.08081L15.5788 7.998L10.5746 12.919L5.5704 7.998L6.50308 7.08081L10.5746 11.0847Z"
        fill={fill}
      />
    </svg>
  );
};

DropdownArrowIcon.propTypes = {
  is_open: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default DropdownArrowIcon;
