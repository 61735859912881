import React from 'react';

const ObjectiveCard = ({ count }) => {
  return (
    <div className="flex items-center gap-2 w-[130px] px-3 py-1 rounded-lg bg-[#E6F7E6]">
      <span className="text-xl font-bold tracking-wide text-green-600">{count}</span>
      <span className="text-sm font-medium tracking-wide text-slate-500 whitespace-nowrap">Objectives</span>
    </div>
  );
};

export default ObjectiveCard;