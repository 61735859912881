import React, { useState, useEffect } from "react";
import API from "../../../services/api";
import AuthService from "../../auth/AuthService";
import { useInitiativesPage } from "../../../contexts/InitiativesPageContext";

function InitiativeForm({ onClose, onSave }) {
  const [initiativeName, setInitiativeName] = useState("");
  const [initiativeDescription, setInitiativeDescription] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const { setIndividualInitiativeByIdForUser } = useInitiativesPage();

  useEffect(() => {
    setIsFormValid(
      initiativeName.trim() !== "" && initiativeDescription.trim() !== "",
    );
  }, [initiativeName, initiativeDescription]);

  const getInputBackgroundColor = (value) => {
    return value.trim() !== "" ? "var(--blue-lighter, #FBFCFC)" : "#F6F9FF";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = AuthService.getCurrentUser();
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    const newInitiative = {
      title: initiativeName,
      description: initiativeDescription,
      source: "manual",
      sourceRelatedMetadata: { created_at: new Date().toISOString() },
      associatedUserId: user.user.userId,
      additional_initiative_information: {
        team: [],
      },
    };

    try {
      const response = await API.post(
        "initiatives-page/create-new-initiative",
        newInitiative,
      );
      setIndividualInitiativeByIdForUser(response.data);
      onSave(response.data);
    } catch (error) {
      console.error("Error creating new initiative:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center w-full">
      <div className="flex flex-col gap-6 w-[600px] mb-6">
        <div
          className="flex items-center gap-2 px-5 py-[9px] rounded-xl border border-[#E5EAF3] w-full"
          style={{ background: getInputBackgroundColor(initiativeName) }}
        >
          <input
            type="text"
            id="initiativeName"
            className="flex-1 bg-transparent text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%] focus:outline-none placeholder-[#727F95]"
            placeholder="Enter a name"
            maxLength="24"
            value={initiativeName}
            onChange={(e) => setInitiativeName(e.target.value)}
            style={{ background: "transparent" }}
          />
          <div className="w-[39px] text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%]">
            {initiativeName.length}/24
          </div>
        </div>
        <div
          className="flex flex-col h-[150px] rounded-xl border border-[#E5EAF3] w-full"
          style={{ background: getInputBackgroundColor(initiativeDescription) }}
        >
          <div className="flex flex-1 flex-col px-5 py-[9px] gap-2">
            <textarea
              id="initiativeDescription"
              className="flex-1 bg-transparent resize-none text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%] focus:outline-none placeholder-[#727F95]"
              placeholder="Description"
              maxLength="250"
              value={initiativeDescription}
              onChange={(e) => setInitiativeDescription(e.target.value)}
              style={{ background: "transparent" }}
            ></textarea>
          </div>
          <div className="self-stretch text-right text-[#727F95] font-['Roboto_Mono'] text-base font-normal leading-[150%] px-5 pb-[9px]">
            {initiativeDescription.length}/250
          </div>
        </div>
      </div>
      <div className="flex justify-end w-[600px]">
        <button
          type="button"
          onClick={onClose}
          className="flex justify-center items-center h-[48px] px-10 py-[17px] mr-6 gap-2 rounded-[999px] bg-white text-[#104499] shadow-[0px_8px_16px_0px_rgba(16,68,153,0.05),0px_0px_8px_0px_rgba(16,68,153,0.05)] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={!isFormValid}
          className={`flex justify-center items-center h-[48px] px-10 py-[17px] gap-2 rounded-[999px] ${
            isFormValid ? "bg-[#104499]" : "bg-slate-300"
          } text-white shadow-[0px_8px_16px_0px_rgba(16,68,153,0.05),0px_0px_8px_0px_rgba(16,68,153,0.05)] font-['Roboto_Mono'] text-base font-medium leading-[135%] tracking-[0.32px] uppercase`}
        >
          Create
        </button>
      </div>
    </form>
  );
}

export default InitiativeForm;
