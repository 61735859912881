import React from "react";
import { useNavigate } from "react-router-dom";

function AggregateFeatureRequestCard({ featureRequest }) {
  const navigate = useNavigate();

  const handleCardClick = () => {
    // Update the route to match the new type
    navigate(`/insights/triangulated-feature-request/${featureRequest.id}`);
  };

  return (
    <div
      className="aggregate-feature-request-card flex w-full h-40 pr-4 items-center gap-3 flex-shrink-0 rounded-lg bg-white shadow-[0px_2px_8px_0px_rgba(114,127,149,0.25)] cursor-pointer transition duration-300 ease-in-out hover:-translate-y-1 hover:shadow-xl"
      onClick={handleCardClick}
    >
      <div className="w-[6px] self-stretch rounded-l-lg bg-[#628FDA]" style={{ flexShrink: 0 }}></div>
      <div className="flex flex-col h-full justify-between py-4">
        <div className="flex items-start gap-2">
          <h4 className="text-[#001331] font-rubik text-base font-medium leading-[145%] h-[70px] flex-1">
            {featureRequest.title}
          </h4>
        </div>
        <div style={{backgroundColor: '#C5D1E4', height: '1px', width: '100%'}}></div>
        <div className="flex items-center gap-2">
          <p className="text-[#727F95] font-rubik text-sm font-normal leading-[120%] flex-1"
             style={{ color: 'var(--Mid-grey, #727F95)', fontFamily: 'Rubik', fontSize: '14px', fontStyle: 'normal', fontWeight: 400, lineHeight: '120%', flex: '1 0 0' }}>
            {featureRequest.num_unique_source_artifacts_for_aggregate_insights_of_same_type_as_triangulated_insight}/{featureRequest.total_num_artifacts_for_this_user} people
          </p>
        </div>
      </div>
    </div>
  );
}

export default AggregateFeatureRequestCard;
