import React from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import InitiativesIcon from "../../assets/icons/HeaderIcons/initiatives-navbar-icon.svg";
import InsightsIcon from "../../assets/icons/HeaderIcons/insights-navbar-icon.svg";
import IntegrationsIcon from "../../assets/icons/HeaderIcons/integrations-navbar-icon.svg";

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

const NavBarButton = styled.button`
  display: flex;
  padding: 10px 12px 0px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconWrapper = styled.img`
  width: 19px;
  height: 15px;
`;

const ActiveIndicator = styled.div`
  height: 4px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--blue-dark, #104499);
`;

const NavBarButtonComponent = ({ icon, text, isActive, to }) => {
  return (
    <Link to={to}>
      <NavBarButton data-testid={`${text.toLowerCase()}-navbar-button`}>
        <ButtonContent>
          <IconWrapper src={icon} alt={`${text} Icon`} />
          {text}
        </ButtonContent>
        {isActive && <ActiveIndicator />}
      </NavBarButton>
    </Link>
  );
};

const NavBar = () => {
  const location = useLocation();

  return (
    <NavBarContainer>
      <NavBarButtonComponent
        icon={InitiativesIcon}
        text="Initiatives"
        isActive={
          location.pathname === "/initiatives" ||
          location.pathname.includes("initiatives")
        }
        to="/initiatives"
      />
      <NavBarButtonComponent
        icon={InsightsIcon}
        text="Insights"
        isActive={location.pathname.includes("insights")}
        to="/insights"
      />
      <NavBarButtonComponent
        icon={IntegrationsIcon}
        text="Integrations"
        isActive={
          location.pathname === "/data-integrations" ||
          location.pathname === "/classify-files"
        }
        to="/data-integrations"
      />
    </NavBarContainer>
  );
};

export default NavBar;
