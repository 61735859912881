import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const PreAuthRoute = () => {
   const { user } = useAuth();
   const pendingUserId = localStorage.getItem("pendingUserId");
   const location = useLocation();

   if (user) {
      return <Navigate to="/initiatives" />;
   }

   // Allow access to payment-success and payment-cancel routes without pendingUserId
   if (location.pathname === "/payment-success" || location.pathname === "/payment-cancel") {
      return <Outlet />;
   }

   if (!pendingUserId) {
      return <Navigate to="/register" />;
   }

   return <Outlet />;
};
