import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import WelcomeSection from "../WelcomeSection/WelcomeSectionPage";
import SearchBar from './SearchBar';
import InitiativesBoxRenderer from './InitiativesBoxRenderer';
import LoadingSpinner from '../../reusable_components/LoadingSpinner/LoadingSpinner';
import { useInitiativesPage } from '../../../contexts/InitiativesPageContext';
import { useIntegrations } from '../../../contexts/IntegrationsContext';
import Pagination from './Pagination';
import CreateInitiativeModal from './CreateInitiativeModal';
import PrimaryButton from '../../reusable_components/Buttons/PrimaryButton';
import API from '../../../services/api';
import { uploadFilesAndCreateLUFsAndPAEIFs } from '../../../services/uploadFilesAndCreateLUFsAndPAEIFsService';



const InitiativesPage = () => {
  const { 
    initiatives,
    filteredInitiatives,
    totalInitiatives,
    pageLoading: isLoading,
    defaultPageLoader,
    currentPage,
    setCurrentPageNumber,
    searchPageLoader,
    searchMode
  } = useInitiativesPage();

  const { integrations } = useIntegrations();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (searchMode) {
      searchPageLoader();
    } else {
      defaultPageLoader(currentPage);
    }
  }, [defaultPageLoader, searchPageLoader, currentPage, searchMode]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPageNumber(newPage);
  }, [setCurrentPageNumber]);

  const showWelcomeSection = useMemo(() => 
    initiatives.length === 0 && !integrations.jira,
    [initiatives, integrations.jira]
  );

  const handleCreateInitiative = () => {
    setIsCreateModalOpen(true);
  };

  const handleCloseCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleInitiativeCreated = (createdInitiativeId) => {
    // Refresh the initiatives list
    defaultPageLoader(currentPage);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    try {
      await uploadFilesAndCreateLUFsAndPAEIFs(files);
      // Optionally refresh initiatives list
      defaultPageLoader(currentPage);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col px-3 pt-2" style={{ backgroundColor: '#f6f9ff' }}>
      <main className="flex flex-col items-center pb-52 mt-12 w-full max-md:pb-24 max-md:mt-10">
        <div className="w-full max-w-[1080px]">
          <div className="flex justify-between items-center mb-4">
            <div className="flex gap-2 items-center self-start font-medium leading-none whitespace-nowrap">
              <h1 className="self-stretch my-auto text-2xl text-slate-900">Initiatives</h1>
              <div className="self-stretch my-auto text-xl text-slate-500">
                ({totalInitiatives})
              </div>
            </div>
            <div className="flex gap-2">
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
                multiple
                className="hidden"
              />
              <PrimaryButton
                onClick={() => fileInputRef.current.click()}
              >
                Upload Files
              </PrimaryButton>
              <PrimaryButton
                onClick={handleCreateInitiative}
              >
                Create
              </PrimaryButton>
            </div>
          </div>
          {!showWelcomeSection && <SearchBar />}
        </div>
        {showWelcomeSection ? <WelcomeSection /> : <InitiativesBoxRenderer />}
      </main>
      <Pagination
        currentPage={currentPage}
        totalInitiatives={totalInitiatives}
        onPageChange={handlePageChange}
      />
      <CreateInitiativeModal
        open={isCreateModalOpen}
        onClose={handleCloseCreateModal}
        onInitiativeCreated={handleInitiativeCreated}
      />
    </div>
  );
};

export default React.memo(InitiativesPage);
