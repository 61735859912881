import React from "react";

/**
 * AnchorTab component
 * @param {Object} props
 * @param {string} props.text - The text to display in the tab.
 * @param {boolean} [props.isSelected=false] - Optional. Whether the tab is selected.
 * @param {function} props.onClick - Function to call when the tab is clicked.
 * @param {string} [props.chipText] - Optional. Text to display in the chip. This will usually be a number
 * @param {boolean} [props.showCheckMarkIcon=false] - Optional. Whether to show the checkmark icon.
 * @param {boolean} [props.showChipText=true] - Optional. Whether to show the chip text.
 */
const AnchorTab = ({
  text,
  isSelected = false,
  onClick,
  showCheckMarkIcon = false,
  showChipText = false,
  chipText = "",
}) => {
  return (
    <div
      className="anchor-tab"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "6px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          padding: "0px 4px",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div
          id="Chip"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8px",
            background: "var(--color-green-light, #DEF8F0)",
          }}
        >
          {showChipText && (
            <span
              style={{
                color: "var(--color-green, #0AA476)",
                fontFamily: '"Roboto Mono"',
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "135%",
                letterSpacing: "0.28px",
                textTransform: "uppercase",
                padding: "0px 6px",
              }}
            >
              {chipText}
            </span>
          )}
          {showCheckMarkIcon && (
            <div
              id="CheckmarkIcon"
              style={{
                width: "20px",
                height: "20px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <circle cx="10" cy="10" r="10" fill="#DEF8F0" />
                <path
                  d="M8.29114 15L4 10.7222L5.31931 9.40712L8.29114 12.3697L14.6807 6L16 7.31504L8.29114 15Z"
                  fill="#0AA476"
                />
              </svg>
            </div>
          )}
        </div>
        {!isSelected ? (
          <span
            style={{
              color: "var(--slate-dark, #727F95)",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "125%",
            }}
          >
            {text}
          </span>
        ) : (
          <span
            style={{
              color: "var(--blue-darkest, #001331)",
              fontFamily: "Rubik",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "125%",
              textTransform: "capitalize",
            }}
          >
            {text}
          </span>
        )}
      </div>
      {isSelected && (
        <div
          style={{
            height: "4px",
            alignSelf: "stretch",
            borderRadius: "2px",
            background: "var(--blue-dark, #104499)",
          }}
        />
      )}
    </div>
  );
};

/**
 * AnchorMenuForIntegrationsTabs component
 * @param {Object} props
 * @param {Array} props.tabs - Array of tab objects with properties: text, showCheckMarkIcon, showChipText, chipText
 * @param {string} props.selectedTab - The currently selected tab's text
 * @param {function} props.onTabClick - Function to call when a tab is clicked
 */
const AnchorMenuForIntegrationsTabs = ({ tabs, selectedTab, onTabClick }) => {
  return (
    <div
      id="AllIntegrationsTabAndTabsForEachIndividualIntegration"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1px",
        alignSelf: "stretch",
      }}
    >
      <div
        id="AnchorMenuForTheTabs"
        style={{
          display: "flex",
          paddingTop: "10px",
          alignItems: "flex-start",
          gap: "36px",
          alignSelf: "stretch",
        }}
      >
        {tabs.map((tab) => (
          <AnchorTab
            key={tab.text}
            text={tab.text}
            isSelected={tab.text === selectedTab}
            onClick={() => onTabClick(tab.text)}
            showCheckMarkIcon={tab.showCheckMarkIcon}
            showChipText={tab.showChipText}
            chipText={tab.chipText}
          />
        ))}
      </div>
    </div>
  );
};

export default AnchorMenuForIntegrationsTabs;
