import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ chartData, totalSources, scaleMax }) => {
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Data Sources Distribution',
            },
            tooltip: {
                enabled: false, // Disable the tooltip
            },
        },
        hover: {
            mode: null // Disable hover effects
        },
        scales: {
            x: {
                beginAtZero: true,
                max: scaleMax,
                title: {
                    display: false,
                    text: 'Number of Sources',
                },
                grid: {
                    display: false,
                },
                ticks : {
                    precision: 0
                }
            },
            y: {
                title: {
                    display: false,
                    text: 'Data Source Type',
                },
                grid: {
                    display: false,
                },
            },
        },
        maintainAspectRatio: false, // Add this line to allow custom sizing
    };

    const data = {
        labels: ['User Interviews', 'App Review', 'Market Research Reports', 'Strategy Documents'],
        datasets: [
            {
                data: [
                    chartData.userInterviews,
                    chartData.appReview,
                    chartData.marketTrendNews,
                    chartData.strategyDocuments
                ],
                backgroundColor: [
                    '#ABE9F5',
                    '#ABE9F5',
                    '#9CC2FF',
                    '#9392F2'
                ],
                borderColor: [
                    '#9CC2FF',
                    '#9CC2FF',
                    '#9CC2FF',
                    '#9392F2'
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <div style={{
            borderRadius: '12px',
            border: '1px solid var(--slate-lighter, #E5EAF3)',
            background: 'var(--white, #FFF)',
            padding: '24px 36px',
            width: '100%'
        }}>
            <Bar options={options} data={data} />
        </div>
    );
};

export default BarChart;
