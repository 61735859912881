import React from "react";
import styled from "styled-components";

const SecondaryButtonContainer = styled.button`
  display: inline-flex;
  height: 48px;
  padding: 17px 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 999px;
  border: 1px solid var(--blue-dark, #104499);
  background: var(--white, #fff);
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  ${(props) =>
    props.disabled &&
    `
    border: 1px solid var(--slate-light, #C5D1E4);
    box-shadow: 0px 0px 8px 0px rgba(16, 68, 153, 0.05);
  `}

  &:hover {
    ${(props) =>
      !props.disabled &&
      `
      border: 1px solid var(--blue-medium, #628FDA);
      box-shadow: 0px 8px 16px 0px rgba(16, 68, 153, 0.05), 0px 0px 8px 0px rgba(16, 68, 153, 0.05);
    `}
  }
`;

const ButtonText = styled.span`
  color: var(--blue-dark, #104499);
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 0.32px;
  text-transform: uppercase;

  ${(props) =>
    props.disabled &&
    `
    color: var(--slate-light, #C5D1E4);
  `}

  ${SecondaryButtonContainer}:hover & {
    ${(props) =>
      !props.disabled &&
      `
      color: var(--blue-medium, #628FDA);
    `}
  }
`;

const ButtonIcon = styled.img`
  width: 20px;
  height: 19.121px;
`;

const SecondaryButton = ({
  children, // The text content of the button
  disabled = false, // A boolean indicating whether the button is disabled
  style = {}, // An object containing additional styles to override the default styles
  icon = null, // The URL or path to the icon image to be displayed on the left side of the button text
  onClick = () => {}, // A function to handle the button click event
  dataTestID = null, // New prop for data-testid attribute
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <SecondaryButtonContainer
      disabled={disabled}
      style={style}
      onClick={handleClick}
      data-testid={dataTestID} // Set the data-testid attribute
    >
      {icon && <ButtonIcon src={icon} alt="Button Icon" disabled={disabled} />}
      <ButtonText disabled={disabled}>{children}</ButtonText>
    </SecondaryButtonContainer>
  );
};

export default SecondaryButton;
