import React, { useState, useEffect, useContext } from "react";
import API from "../../../../services/api";
import {
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SteamIntegrationGameBox from "./SteamTabContentGameBox";
import { StatusContext } from "../../../../contexts/StatusContext";
import PrimaryButton from "../../../reusable_components/Buttons/PrimaryButton";

function SteamIntegrationSearch({ setSelectedGames }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [games, setGames] = useState([]);
  const [selectedGamesIds, setSelectedGamesIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);

  const { setIsCurrentlyCheckingForUpdatesOnProcesses } = useContext(StatusContext);

  useEffect(() => {
    if (!searchQuery) {
      fetchSelectedGames();
    }
  }, [searchQuery]);

  const fetchSelectedGames = async () => {
    try {
      setIsLoading(true);
      const response = await API.get("steam/selected-games");
      setGames(response.data.games);
      setSelectedGamesIds(response.data.games.map((game) => game.id));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching selected games:", error);
      setIsLoading(false);
    }
  };

  const handleSearch = async (event) => {
    if (event.key === "Enter") {
      try {
        setIsLoading(true);
        const response = await API.get("steam/search", {
          params: { query: searchQuery },
        });
        setGames(response.data.games);
        setIsLoading(false);
      } catch (error) {
        console.error("Error searching for games:", error);
        setIsLoading(false);
      }
    }
  };

  const handleSelectChange = async (gameId, isSelected) => {
    if (isSelected) {
      try {
        await API.post("steam/add-game", { game_id: gameId });
        setSelectedGamesIds((prev) => [...prev, gameId]);
        // Find the game data in the games array
        const gameData = games.find((game) => game.id === gameId);
        if (gameData) {
          setSelectedGames((prev) => [...prev, gameData]);
        }
        setSnackbarMessage("Game added for sync");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error updating game selection:", error);
      }
    } else {
      setGameToDelete(gameId);
      setDeleteConfirmOpen(true);
    }
  };

  const handleDeleteConfirm = async () => {
    setDeleteConfirmOpen(false);
    if (gameToDelete) {
      try {
        await API.post("steam/remove-game", { game_id: gameToDelete });
        setIsCurrentlyCheckingForUpdatesOnProcesses(true);
        setSelectedGamesIds((prev) => prev.filter((id) => id !== gameToDelete));
        setSelectedGames((prev) => prev.filter((game) => game.id !== gameToDelete));
        setSnackbarMessage("Game and associated reviews removed");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error removing game:", error);
        setSnackbarMessage("Failed to remove game");
        setSnackbarOpen(true);
      }
    }
    setGameToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setGameToDelete(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div>
      <TextField
        label="Search for a game on Steam to sync reviews"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={handleSearch}
        sx={{ marginBottom: "20px" }}
        data-testid="search-for-game-on-steam-text-box"
      />
      {isLoading ? (
        <CircularProgress />
      ) : games.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {searchQuery ? "Search results:" : "Selected games:"}
            </Typography>
          </Grid>
          {games.map((game) => (
            <Grid item key={game.id}>
              <SteamIntegrationGameBox
                game={game}
                isSelected={selectedGamesIds.includes(game.id)}
                onSelectChange={handleSelectChange}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6">
          Search to add games to sync reviews from!
        </Typography>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        sx={{
          bottom: "80px",
          "& .MuiSnackbarContent-root": {
            width: "auto",
            minWidth: "200px",
            maxWidth: "800px",
            fontSize: "1.1rem",
            padding: "12px 24px",
            textAlign: "center",
            display: "block",
          },
        }}
      />
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <h1 className="self-stretch my-auto text-2xl text-slate-900">
            Confirm Removal
          </h1>
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              color: "#727F95",
              fontFamily: '"Roboto Mono"',
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "135%",
              marginBottom: "16px",
            }}
          >
            Are you sure you want to remove this game? This will also delete all associated reviews and insights extracted from them.
          </p>
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleDeleteCancel} color="primary">
            Cancel
          </PrimaryButton>
          <PrimaryButton onClick={handleDeleteConfirm} color="primary">
            Remove
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SteamIntegrationSearch;
